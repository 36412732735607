<div class="subscription-modal">
  <div class="header">
    <div class="title">
      <span [innerText]="'Subscription Management'"></span>
    </div>
    <div>
      <span [bpIcon]="closeIcon" (click)="onCloseModal()"></span>
    </div>
  </div>

  <mat-dialog-content>
    <div class="dialog-content">
      <div class="model-details">
        <div class="detail">
          <label [innerText]="'License ID'"></label>
          <label class="value" [innerText]="license_Model?.licenseId"></label>
        </div>
        <div class="detail">
          <label [innerText]="'License Type'"></label>
          <label class="value" [innerText]="license_Model?.licenseType"></label>
        </div>
        <div class="detail">
          <label [innerText]="'Tenant ID'"></label>
          <label class="value" [innerText]="license_Model?.dbCorporationId"></label>
        </div>
        <div class="detail">
          <label [innerText]="'Effective Date'"></label>
          <label class="value" [innerText]="license_Model?.effectiveDate | date: 'MM/dd/yyyy'"></label>
        </div>
        <div class="detail">
          <label [innerText]="'Expiration Date'"></label>
          <label class="value" [innerText]="license_Model?.expirationDate ? (license_Model?.expirationDate | date: 'MM/dd/yyyy') : 'Never Expires'"></label>
        </div>
        <div class="detail">
          <label [innerText]="'License Status'"></label>
          <label class="value" [innerText]="license_Model?.licenseStatusTypeName"></label>
        </div>
      </div>

      <form [formGroup]="formSubscription" class="form-model" *ngIf="!isReadOnly">
        <!-- <div class="details-row">
          <div class="bp-form-field required custom">
            <label [innerText]="'Subscription Type'"></label>
            <lib-select
                [options]="subscriptionTypeList"
                [control]="formSubscription.controls['subscriptionTypeId']"
                [disabled]="false"
                [multiple]="false"
                [closeOnSelect]="true"
                displayKey="subscriptionName"
                returnKey="subscriptionTypeId"
                placeholder="Select"
                [required]="false"
            ></lib-select>
          </div>
        </div> -->

         <div class="details-row" *ngIf="!formSubscription.controls.licenseSubscriptionTypeId.value">
          <div class="bp-form-field required custom">
            <label [innerText]="'Subscription Type'"></label>
            <lib-select
                [options]="subscriptionTypeList"
                [control]="formSubscription.controls['subscriptionTypeId']"
                [disabled]="false"
                [multiple]="true"
                [closeOnSelect]="false"
                displayKey="subscriptionName"
                returnKey="subscriptionTypeId"
                tooltipKey="subscriptionName"
                placeholder="Select"
                [required]="false"
            ></lib-select>
          </div>
        </div>
         <div class="bp-form-field required subscription-list-row" *ngIf="formSubscription.controls.licenseSubscriptionTypeId.value">
          <label [innerText]="'Subscription Type'"></label>
          <mat-form-field appearance="outline" class="bp-form-field bp-dropdown filter-input">
            <mat-select formControlName="subscriptionTypeId" placeholder="Select" disableOptionCentering
              panelClass="bp-filter-selector">
              <mat-option *ngFor="let type of subscriptionTypeList" [value]="type">
                {{type.subscriptionName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="details-row details-row__dates">
          <div class="bp-form-field required input-date"
            [class.bp-invalid]="formSubscription.controls.effectiveDate?.invalid && (formSubscription.controls.effectiveDate?.dirty || formSubscription.controls.effectiveDate?.touched)">
            <label [innerText]="'Effective Date'"></label>
            <input
              matInput
              [matDatepicker]="effectiveDatePicker"
              [min]="minEffectiveDate"
              [max]="maxEffectiveDate"
              name="effectiveDate"
              formControlName="effectiveDate"
              (dateChange)="onDateValueChanged()">
            <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #effectiveDatePicker></mat-datepicker>
            <div class="bp-field-error"
              *ngIf="formSubscription.controls.effectiveDate?.invalid && (formSubscription.controls.effectiveDate?.dirty || formSubscription.controls.effectiveDate?.touched)">
              <div *ngIf="formSubscription.controls.effectiveDate?.errors?.required">
                <span [innerText]="'Effective Date is required.'"></span>
              </div>
            </div>
          </div>

          <div class="bp-form-field input-date" [class.required]="expDateIsRequired"
            [class.bp-invalid]="formSubscription.controls.expirationDate?.invalid && (formSubscription.controls.expirationDate?.dirty || formSubscription.controls.expirationDate?.touched)">
            <label [innerText]="'Expiration Date'"></label>
            <input
              matInput
              [min]="minDate"
              [max]="maxExpirationDate"
              [matDatepicker]="expirationDatePicker"
              name="expirationDate"
              formControlName="expirationDate">
            <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #expirationDatePicker></mat-datepicker>
            <div class="bp-field-error"
              *ngIf="formSubscription.controls.expirationDate?.invalid && (formSubscription.controls.expirationDate?.dirty || formSubscription.controls.expirationDate?.touched)">
              <div *ngIf="formSubscription.controls.expirationDate?.errors?.required">
                <span [innerText]="'Expiration Date is required.'"></span>
              </div>
            </div>
          </div>
          <div class="checkbox-content">
            <mat-checkbox
              formControlName="neverExpires"
              (change)="onChangeExpireCheck($event)">
              <span [innerText]="'Never Expires'"></span>
            </mat-checkbox>
          </div>

          <div class="bp-form-field status">
            <label [innerText]="'Status'"></label>
            <mat-form-field appearance="outline" class="bp-form-field bp-dropdown filter-input">
              <mat-select formControlName="statusType" placeholder="Select" disableOptionCentering
                panelClass="bp-filter-selector">
                <mat-option *ngFor="let type of statusTypeListSubscriptionAddModal" [value]="type">
                  {{type.statusName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
      </form>

      <bp-accordion [title]="'Subscription'" [isExpanded]="isExpanded">
        <div class="filter">
          <app-filter-grid
            [searchPlaceholder]="'Search by Subscription ID'"
            [categoryList]="filterCategoryList"
            [filterByModel]="filterByModel"
            (changeCategory)="onChangeCategory($event)"
            (changeFilter)="onChangeFilter($event)">
          </app-filter-grid>
        </div>
        <bpe-grid
          *ngIf="!loadingSubcriptions"
          [columnDefs]="columnDefs"
          [data]="subscriptionList"
          [optionsTmpl]="myGridTemplate"
          [isSelectable]="isSelectable"
          (onSelectionChange)="onSelectionChange($event)"
          [pagination]="pagination"
          (onPageChange)="onPageChange($event)"
          (onSort)="onSort($event)">
        </bpe-grid>
      </bp-accordion>
    </div>


    <div class="btn-container buttons-right">
      <button type="button" class="secondary md" (click)="onCloseModal()">Cancel</button>
      <button type="submit" class="primary md" (click)="onSave()" [disabled]="!formSubscription?.valid || (!formSubscription?.valid && !isReadOnly)">Save</button>
    </div>

    <ng-template #myTemplate let-item="item">
      <button mat-menu-item class="item-btn" (click)="onDeleteRow(item)">
        <span>Remove</span>
      </button>
    </ng-template>
  </mat-dialog-content>

</div>
