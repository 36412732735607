import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { IBpChip, IconRefEnum } from 'library';
import { Subscription } from 'rxjs';
import {
  IBondProNGCarrierClientDto,
  ICustomerTypeDto,
  INGBondProCustomerDto,
  INGStatusTypeInstanceDto,
  IServiceResponseDto,
} from '../../interfaces/license-interfaces';
import { LicenseService } from '../../services/license.service';
import { MessageHelperService } from '../../services/message-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivityHistoryDialogComponent } from '../activity-history/activity-history-dialog/activity-history-dialog.component';
import { ActivityHistoryService, ActivityKey, commonYesNoFormatter, SearchCriteriaOperator, SearchOperator } from '../../services/activity-history.service';

@Component({
  selector: 'app-add-edit-client',
  templateUrl: './add-edit-client.component.html',
  styleUrls: ['./add-edit-client.component.scss'],
})
export class AddEditClientComponent implements OnInit, OnDestroy {
  @Input() statusList!: INGStatusTypeInstanceDto[];
  @Output() updateModel = new EventEmitter();
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    if (value) {
      this.onDisableForm();
    }
  }
  @Input() set clientModel(value: IBondProNGCarrierClientDto) {
    if (value) {
      this._clientModel = value;
      this.setFormValue(value);
    }
  }

  appList!: any[];
  private _subs = [] as Subscription[];
  private _clientModel!: IBondProNGCarrierClientDto;
  akaChipList: Array<IBpChip> = [];
  titleForm: string = 'Add Customer';
  editIcon = IconRefEnum.Edit;
  clientForm!: FormGroup;
  editMode!: boolean;
  isEditForm: boolean = false;
  _disabled!: boolean;
  clientId?: number;
  bondProCustomers!: INGBondProCustomerDto[];
  customerTypeList!: ICustomerTypeDto[];

  activityIcon = IconRefEnum.History;
  constructor(
    private licenseService: LicenseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private messageService: MessageHelperService,
    public dialog: MatDialog,
    private activityHistoryService: ActivityHistoryService,

  ) {
    this.getDropdownValue();
    this.getCustomerTypeList();
    this.buildForm();
  }

  ngOnInit() {
    this._subs.push(
      this.route.params.subscribe(params => {
        this.clientId = params.clientId;
      }));

    if (this.clientId  && this.clientId > 0) {
      this.isEditForm = true;
      this.titleForm = 'Customer';
    }

    if (this.isEditForm) this.onDisableForm();
    this.clientForm.controls.customerNumber.disable();
    this.clientForm.controls.contractReferenceNumber.disable();
    this.clientForm.controls.clientDescription.disable();
    this.getBondProCustomers();
    this.listenForBondProCustomerIdChanges();
  }

  getDropdownValue() {
    this.appList = [
      {
        label: 'Internal',
        key: true,
      },
      {
        label: 'External',
        key: false,
      },
    ];
  }

  getCustomerTypeList(){
    this._subs.push(
      this.licenseService
        .getCustomerType()
        .subscribe((response: ICustomerTypeDto[]) => {
          this.customerTypeList = response
        })
    );
    this.customerTypeList = []
  }

  buildForm(): void {
    this.clientForm = this.formBuilder.group({
      bondProCustomerId: [null, [Validators.required, Validators.maxLength(256)]],
      ngCarrierClientId: [null, [Validators.required, Validators.maxLength(256)]],
      clientName: [null, [Validators.required, Validators.maxLength(256)]],
      customerNumber: [null, [Validators.maxLength(64)]],
      customerTypeCode: [null,[Validators.required]],
      contractReferenceNumber: [null],
      akaNames: [[]],
      clientDescription: [null],
      notes: [null],
      isInternalApp: [null,[Validators.required]],
    });
  }

  setFormValue(value: IBondProNGCarrierClientDto) {
    this.setAkaValue(value);
    this.clientId = value.ngCarrierClientId;
    this.clientForm.controls.clientName.setValue(value.clientName);
    this.clientForm.controls.customerNumber.setValue(value.customerNumber);
    this.clientForm.controls.customerTypeCode.setValue(value.customerTypeCode);
    this.clientForm.controls.contractReferenceNumber.setValue(value.contractReferenceNumber);
    this.clientForm.controls.clientDescription.setValue(value.clientDescription);
    this.clientForm.controls.notes.setValue(value.notes);
    this.clientForm.controls.isInternalApp.setValue(value.isInternalApp);
    //this.clientForm.controls.bondProCustomerId.setValue(value.ngCarrierClientId, { emitEvent: false });
    this.clientForm.controls.ngCarrierClientId.setValue(value.ngCarrierClientId, { emitEvent: false });
  }

  setAkaValue(value: any) {
    if (value.akaNames) {
      this.akaChipList = value.akaNames.map((x: any) => {
        return {
          value: x.akaName, isSelectable: false, isRemovable: true, isDisabled: false, isSelected: false
        }
      });
    }
  }

  onCancel() {
    if (!this.isEditForm) {
      this.onBackToClientManagement();
      return;
    }
    this.setFormValue(this._clientModel);
    this.onDisableForm();
  }

  onSave() {
    const clientModel: IBondProNGCarrierClientDto = this.clientForm.getRawValue();
    clientModel.akaNames = this.getAkaValue(this.akaChipList);
    clientModel.customerTypeName = this.getCustomerTypeName(clientModel.customerTypeCode)
    this.editMode ? this.updateClient(clientModel) : this.createClient(clientModel);
  }

  getAkaValue(akaNameList: Array<IBpChip>) {
    if (akaNameList.length === 0) return akaNameList;
    return akaNameList.map(item => {
      return {
        AkaName: item.value
      };
    });
  }

  getCustomerTypeName(customerTypeCode: string){
    return this.customerTypeList.find((item: any) => item.customerTypeCode === customerTypeCode)?.name
  }

  getBondProCustomers() {
    this._subs.push(
      this.licenseService
        .getBondProCustomers()
        .subscribe((response: INGBondProCustomerDto[]) => {
          this.bondProCustomers = response
        })
    );
  }

  listenForBondProCustomerIdChanges() {
    this._subs.push(
      this.clientForm.controls.bondProCustomerId.valueChanges.subscribe(val => {
        if (!val) {
          return
        }
        const customer = this.bondProCustomers.find((c) => c.bondProCustomerId === val);
        if (customer) {
          this.clientForm.controls.ngCarrierClientId.setValue(customer.bondProCustomerId);
          this.clientForm.controls.clientName.setValue(customer.customerName);
          this.clientForm.controls.customerNumber.setValue(customer.customerCode);
          this.clientForm.controls.customerTypeCode.setValue(customer.customerTypeCode);
          this.clientForm.controls.contractReferenceNumber.setValue(customer.contractReferenceNumber);
          this.clientForm.controls.clientDescription.setValue(customer.customerDescription);
        }
      })
    );
  }

  createClient(clientModel: IBondProNGCarrierClientDto) {
    this._subs.push(
      this.licenseService
        .createClient(clientModel)
        .subscribe((response: IServiceResponseDto) => {
          if (response.success) {
            this.toastr.success('Has been created successfully', 'Success');
            if (response?.items?.length > 0) {
              const clientId = response.items[0];
              this.router.navigateByUrl(`/license/client/${clientId}`);
            }
          } else {
            const messageModel = {
              title: 'Create Customer',
              message: 'Unable to create this customer due to the following:'
            }
            this.messageService.showErrorModal(messageModel, response.errorMessages);
          }
        })
    );
  }

  updateClient(clientModel: IBondProNGCarrierClientDto) {
    clientModel.ngCarrierClientId = this.clientId;
    clientModel.statusTypeName = this.getStatusTypeName(this._clientModel.statusTypeId);
    this._subs.push(
      this.licenseService
        .updateClient(clientModel)
        .subscribe((response: IServiceResponseDto) => {
          if (response.success) {
            this._clientModel = clientModel;
            this.updateModel.emit(response.items[0]);
            this.toastr.success('The customer has been updated!', 'Success');
            this.onDisableForm();
          } else {
            const messageModel = {
              title: 'Update Customer',
              message: 'Unable to update this customer due to the following:'
            }
            this.messageService.showErrorModal(messageModel, response.errorMessages);
          }
        })
    );
  }

  getStatusTypeName(statusTypeId: number | undefined) {
    const foundItem: any = (this.statusList as any || []).find((item: any) => item.statusTypeId === statusTypeId)
    return foundItem ? foundItem.statusName : void 0;
  }

  onEditClient() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.clientForm.enable();
      this.clientForm.controls.bondProCustomerId.disable()
      this.clientForm.controls.clientName.disable()
      this.clientForm.controls.customerNumber.disable()
      this.clientForm.controls.customerTypeCode.disable()
      this.clientForm.controls.contractReferenceNumber.disable()
      this.clientForm.controls.clientDescription.disable()
    }
  }

  onDisableForm() {
    this.editMode = false;
    this.clientForm.disable();
  }

  onBackToClientManagement() {
    this.router.navigateByUrl(`/license`);
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  showActivity() {
    const dialogRef = this.dialog.open(ActivityHistoryDialogComponent, {
      maxHeight: '100vh'
    });
    dialogRef.componentInstance.classification = 'Client';
    // dialogRef.componentInstance.extraColumns = [
    //   {
    //     field: 'ClientId',
    //     title: 'Client ID',
    //   },
    //   {
    //     field: 'ClientName',
    //     title: 'Client Name',
    //   }
    // ]
    dialogRef.componentInstance.extraDetailHeaders = [
      {
        key: 'ClientId',
        label: 'Client ID'
      },
      {
        key: 'ClientName',
        label: 'Client Name'
      },
    ];
    dialogRef.componentInstance.extraGridDetailHeaders = [
      {
        value: this._clientModel.ngCarrierClientId,
        label: 'Client ID'
      },
      {
        value: this._clientModel.clientName,
        label: 'Client Name'
      },
    ];
    dialogRef.componentInstance.diffConfig = {
      historicalFields: ['CreatedBy', 'CreateDate', 'ModifyDate', 'ModifiedBy'],
      onlyDateFields: [],
      customPropertyFormatters: {'IsInternalApp': commonYesNoFormatter},
      rootPropertySection: 'Client Section',
    }
    dialogRef.componentInstance.extraSearchCriterias = this.activityHistoryService.buildCriteriaFilter(
      'ClientId',
      this._clientModel.ngCarrierClientId
    )
    dialogRef.componentInstance.filterConfig = {
      enabled: true,
      placeholder: 'Search by Description',
      buildSearchCriterias: (searchValue) => {
        let criterias: any[] = [];
        if (!searchValue) {
          return criterias;
        }
        const descriptionCriterias = [
          {
            Operator: SearchOperator.AND,
            Criteria: {
              Operator: SearchCriteriaOperator.EQ,
              Key: ActivityKey.Description,
              Value: searchValue,
            },
          },
        ];
        criterias = criterias.concat(descriptionCriterias);
        return criterias
      }
    }
    dialogRef.afterClosed().subscribe((statusTypeId: any) => {
      console.log('status type id', statusTypeId)
      if (statusTypeId) {}
    });
  }
}
