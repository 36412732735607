<div class="add-license-modal">
  <div class="header">
    <div class="title">
      <span *ngIf="!licenseModal" [innerText]="'Add License'"></span>
      <span *ngIf="licenseModal && !editMode" [innerText]="'License'"></span>
      <span *ngIf="editMode" [innerText]="'Modify License'"></span>
      <span *ngIf="licenseModal && (!editMode && (licenseModal?.licenseStatusTypeName === 'Pending' || licenseModal?.licenseStatusTypeName === 'Pending Activation'))" class="btn-icon" [bpMatIcon]="editIcon" (click)="onEditMode()"></span>
    </div>
    <div>
      <span [bpIcon]="closeIcon" (click)="onCloseModal()"></span>
    </div>
  </div>

  <mat-dialog-content>
    <div *ngIf="licenseModal" class="form-header">
      <span *ngIf="licenseModal" (click)="showActivity()" class="activity-icon" [bpMatIcon]="activityIcon"></span>
    </div>

    <form [formGroup]="formAddLicense" class="form-add-license">
      <div class="body-license">
        <div class="form-add-license" style="padding: 0;">
          <div class="bp-form-field required row"
            [class.bp-invalid]="formAddLicense.controls.licenseTypeId?.invalid && (formAddLicense.controls.licenseTypeId?.dirty || formAddLicense.controls.licenseTypeId?.touched)">
            <label [innerText]="'License Type'"></label>
            <mat-form-field appearance="outline" class="bp-form-field bp-dropdown filter-input">
              <mat-select formControlName="licenseTypeId" placeholder="Select" disableOptionCentering
                panelClass="bp-filter-selector">
                <mat-option *ngFor="let type of licenseTypeList" [value]="type.licenseTypeId">
                  {{type.licenseName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="bp-field-error"
              *ngIf="formAddLicense.controls.licenseTypeId?.invalid && (formAddLicense.controls.licenseTypeId?.dirty || formAddLicense.controls.licenseTypeId?.touched)">
              <div *ngIf="formAddLicense.controls.licenseTypeId?.errors?.required">
                <span [innerText]="'License Type is required.'"></span>
              </div>
            </div>
          </div>
          <div class="bp-form-field required row"
            [class.bp-invalid]="formAddLicense.controls.nGCarrierClientInstanceId?.invalid && (formAddLicense.controls.nGCarrierClientInstanceId?.dirty || formAddLicense.controls.nGCarrierClientInstanceId?.touched)">
            <label [innerText]="'Tenant ID'"></label>
            <mat-form-field appearance="outline" class="bp-form-field bp-dropdown filter-input">
              <mat-select formControlName="nGCarrierClientInstanceId" disableOptionCentering
                panelClass="bp-filter-selector">
                <mat-option *ngFor="let tenant of clientInstances" [value]="tenant.ngCarrierClientInstanceId">
                  {{tenant.dbCorporationId}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="bp-field-error"
              *ngIf="formAddLicense.controls.nGCarrierClientInstanceId?.invalid && (formAddLicense.controls.nGCarrierClientInstanceId?.dirty || formAddLicense.controls.nGCarrierClientInstanceId?.touched)">
              <div *ngIf="formAddLicense.controls.nGCarrierClientInstanceId?.errors?.required">
                <span [innerText]="'Tenant ID is required.'"></span>
              </div>
            </div>
          </div>

          <div class="bp-form-field required row"
            [class.bp-invalid]="formAddLicense.controls.effectiveDate?.invalid && (formAddLicense.controls.effectiveDate?.dirty || formAddLicense.controls.effectiveDate?.touched)">
            <label [innerText]="'Effective Date'"></label>
            <input
              matInput
              [matDatepicker]="effectiveDatePicker"
              name="effectiveDate"
              formControlName="effectiveDate"
              (dateChange)="onDateValueChanged()">
            <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #effectiveDatePicker></mat-datepicker>
            <div class="bp-field-error"
              *ngIf="formAddLicense.controls.effectiveDate?.invalid && (formAddLicense.controls.effectiveDate?.dirty || formAddLicense.controls.effectiveDate?.touched)">
              <div *ngIf="formAddLicense.controls.effectiveDate?.errors?.required">
                <span [innerText]="'Effective Date is required.'"></span>
              </div>
            </div>
          </div>

          <div class="bp-form-field row" [class.required]="expDateIsRequired"
            [class.bp-invalid]="formAddLicense.controls.expirationDate?.invalid && (formAddLicense.controls.expirationDate?.dirty || formAddLicense.controls.expirationDate?.touched)">
            <label [innerText]="'Expiration Date'"></label>
            <input
              matInput
              [matDatepicker]="expirationDatePicker"
              name="expirationDate"
              formControlName="expirationDate"
              [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #expirationDatePicker></mat-datepicker>
            <div class="bp-field-error"
              *ngIf="formAddLicense.controls.expirationDate?.invalid && (formAddLicense.controls.expirationDate?.dirty || formAddLicense.controls.expirationDate?.touched)">
              <div *ngIf="formAddLicense.controls.expirationDate?.errors?.required">
                <span [innerText]="'Expiration Date is required.'"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="never-expire-field">
          <mat-checkbox
            formControlName="neverExpires"
            (change)="onChangeExpireCheck($event)">
            <span [innerText]="'Never Expires'"></span>
          </mat-checkbox>
        </div>
      </div>

      <div class="bp-form-field" style="width: 100%;">
        <label [innerText]="'Notes'"></label>
        <textarea matInput rows="3" cols="18" formControlName="notes"></textarea>
      </div>
    </form>
    <div style="display: flex; margin-bottom: 20px;">
      <div class="bp-form-field required row" *ngIf="licenseModal" style="width: 50%; margin-right: 20px;">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <label [innerText]="'License Key'"></label>
          <button class="k-button show-decrip-license" (click)="onViewKey()" *ngIf="licenseKey">
            <span [bpIcon]="bpView"></span>
          </button>
        </div>
        <!-- <div class="bp-form-field row" style="display: flex; align-items: flex-end;" *ngIf="licenseModal"> -->
          <!-- <button type="button" class="primary md" style="height: 31.2px;" (click)="onViewKey()">
            <span class="btn-text">View Key</span>
          </button> -->

        <!-- </div> -->
        <input matInput [disabled]="disabledKey" [(ngModel)]="licenseKey"/>
      </div>
      <div class="bp-form-field row" style="display: flex; align-items: flex-end;" *ngIf="licenseModal && licenseModal?.licenseStatusTypeName === 'Pending' || licenseModal?.licenseStatusTypeName === 'Pending Activation' || licenseModal?.licenseStatusTypeName === 'Active'">
        <button type="button" class="primary md" style="height: 31.2px;" (click)="onRegenerateKey()" [disabled]="(licenseModal?.licenseStatusTypeName === 'Expired' || licenseModal?.licenseStatusTypeName === 'Inactive')">
          <span class="btn-text">Regenerate Key</span>
        </button>
      </div>
    </div>

    <div  class="decrip-license" *ngIf="showDecripLicense">
      <div class="bp-form-field" *ngIf="licenseModal">
        <label [innerText]="'Tenant Id'"></label>
        <input matInput disabled [(ngModel)]="decripLicenseModel.tenantId"/>
      </div>

      <div class="bp-form-field" *ngIf="licenseModal">
        <label [innerText]="'Expiration'"></label>
        <input matInput disabled [ngModel]="decripLicenseModel.expiration | date: 'MM/dd/yyyy hh:mm:ss a'" />
      </div>

      <div class="bp-form-field" *ngIf="licenseModal">
        <label [innerText]="'License Status'"></label>
        <input matInput disabled [(ngModel)]="decripLicenseModel.statusLabel"/>
      </div>

      <div class="bp-form-field" *ngIf="licenseModal">
        <label [innerText]="'License Type Code'"></label>
        <input matInput disabled [(ngModel)]="decripLicenseModel.licenseTypeCode"/>
      </div>
    </div>

    <div class="btn-container buttons-right"
    *ngIf="!licenseModal || (licenseModal?.licenseStatusTypeName === 'Pending' || licenseModal?.licenseStatusTypeName === 'Pending Activation')">
      <button type="button" class="secondary md" (click)="onCloseModal()">Cancel</button>
      <button [disabled]="!formAddLicense.valid || (editMode && formAddLicense.controls.expirationDate?.dirty)" type="submit" class="primary md" (click)="onSave()">Save</button>
    </div>
  </mat-dialog-content>

</div>
