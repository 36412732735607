<div class="add-relationship-modal">
    <div class="header">
      <div class="title">
        <span>Add Relationship</span>
      </div>
      <div>
        <span [bpIcon]="closeIcon" (click)="onCloseModal()"></span>
      </div>
    </div>
  
    <mat-dialog-content>
      <div class="tenant-details">
            <div class="grid-header">
              <app-filter-grid
                [searchPlaceholder]="'Search by Customer Name'"
                (changeFilter)="onChangeFilter($event)">
              </app-filter-grid>
            </div>
          
            <bpe-grid
              [isMultipleSelection]="true"
              [isSelectable]="true"
              [showViewMore]="false"
              [columnDefs]="columnDefs"
              [data]="ngClients"
              [disabled]="false"
              [pagination]="pagination"
              selectionKey="ngCarrierClientId"
              (onPageChange)="onPageChange($event)"
              (onSelectedKeysChange)="onSelectedKeysChange($event)"
              (onSort)="onSortChange($event)">
            </bpe-grid>
      </div>
      <div class="btn-container buttons-right"
        *ngIf="true">
        <button type="button" class="secondary md" (click)="onCloseModal()">Cancel</button>
        <button [disabled]="false" type="submit" class="primary md" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-content>
</div>
