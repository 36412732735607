import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bpe-datepicker',
  templateUrl: './datepicker.tmpl.html',
  // styles: [
  //   './datepicker.styles.scss'
  // ]
})
export class BpeDatepickerComponent implements OnInit {
  @Input() label: string = 'Choose Date';

  constructor() { }

  ngOnInit(): void {
  }

}
