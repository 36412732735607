import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTimeService } from 'library';
import { isBoolean, isObject, startCase, isNumber } from 'lodash'
import { Observable } from 'rxjs';
import { ApiSettings } from '../apiSettings';

const defaultRootPropertySection = 'Form Section'

const defaultDateFormatFields = [
  'ExpirationDate',
  'IssuerExpirationDate',
  'EffectiveDate',
  'IssuerEffectiveDate',
]

const defaultHistoricalFields = [
  'CreatedBy',
  'CreatedDate',
  'ModifyDate',
  'ModifiedBy',
  'CreateDate',
]

export const commonYesNoFormatter = (value: boolean) => {
  if (isBoolean(value) && value === true) {
    return 'Yes'
  } else if (isBoolean(value) && value === false) {
    return 'No'
  }
  return value
}

export const commonReleaseRequiredFormatter = (value: boolean) => {
  if (value === null) {
    return 'Not Declared'
  }
  if (isBoolean(value) && value === true) {
    return 'Yes'
  } else if (isBoolean(value) && value === false) {
    return 'No'
  }
  return value
}

const defaultPropertyFormatters = {
  ReleaseRequired: commonReleaseRequiredFormatter,
  ContinuousCertificateRequired: commonYesNoFormatter,
  CancellationNoticePeriod: commonYesNoFormatter,
  CancellationNoticeClause: commonYesNoFormatter,
  CancellationStatute: commonYesNoFormatter,
  CancellationConfirmationRequired: commonYesNoFormatter,
  DurationPartialTermEnd: function (value: any) {
    if (isBoolean(value) && value === true) {
      return 'At End'
    } else if (isBoolean(value) && value === false) {
      return 'At Beginning'
    }
    return value
  },
  FreeTermFlag: commonYesNoFormatter,
  PaymentReleaseRequired: commonReleaseRequiredFormatter,
  PerformanceReleaseRequired: commonReleaseRequiredFormatter,
}

const defaultConfig = {
  historicalFields: defaultHistoricalFields,
  onlyDateFields: defaultDateFormatFields,
  rootPropertySection: defaultRootPropertySection,
  customPropertyFormatters: defaultPropertyFormatters,
  multipleRootOptions: [],
}

export const SearchOperator = {
  AND: 'AND',
  OR: 'OR',
}

export const SearchCriteriaOperator = {
  EQ: 'Eq',
  ELEM_MATCH: 'ElemMatch',
}

export const ActivityKey: any = {
  AssignmentType: 'AssignmentType',
  AssignmentValue: 'AssignmentValue',
  ActivityCustomFields: 'ActivityCustomFields',
  Classification: 'Classification',
  CompareByPk: 'CompareByPk',
  CompareWithAllPk: 'CompareWithAllPk',
  CreatedBy: 'CreatedBy',
  CreatedDateTime: 'CreatedDateTime',
  Description: 'Description',
  Difference: 'Difference',
  Id: 'Id',
  UserId: 'UserId',
  NewObject: 'NewObject',
  PreviousObject: 'PreviousObject',
  Section: 'Section',
}

@Injectable({
    providedIn: 'root'
})
export class ActivityHistoryService {

    private apiUrl = ApiSettings.api_url + '/ActivityHistory';

    constructor(private http: HttpClient, private dateTimeServices: DateTimeService) {
    }

    // getActivityHistoryList(request) {
    //     return super.postRequest(request, '/ActivityHistory/List')
    // }

    getActivityHistoryList(request: any): Observable<any> {
        return this.http.post<any>(
            this.apiUrl + '/GetActivities',
            request
          );
    }
    
    mapActivityHistoryData(response: any) {
        return {
            items: response.result.map((item: any) => {
                const newItem = { ...item }
                item.activityCustomFields.forEach((customField: any) => {
                    newItem[`${customField.key}`] = customField.value
                })
                return newItem
            }),
            page: response.resultPaging.pageNumber,
            pageSize: response.resultPaging.pageSize,
            totalPages: Math.ceil(response.resultPaging.totalCount / response.resultPaging.pageSize),
            totalRecords: response.resultPaging.totalCount,
        }
    }

    buildCriteriaFilter(type: any, value: any) {
        if (!value) {
            return []
        }
        return [
            {
                Operator: SearchOperator.AND,
                Criteria: {
                    Operator: SearchCriteriaOperator.EQ,
                    Key: ActivityKey.AssignmentType,
                    Value: type,
                },
            },
            {
                Operator: SearchOperator.AND,
                Criteria: {
                    Operator: SearchCriteriaOperator.EQ,
                    Key: ActivityKey.AssignmentValue,
                    Value: value,
                },
            },
        ]
    }

    buildSimpleCriteria(key: any, value: any, operator: any) {
        return {
            Operator: operator || void 0,
            Criteria: {
                Operator: SearchCriteriaOperator.EQ,
                Key: key,
                Value: value,
            },
        }
    }

    buildCustomFieldCriteria(key: any, value: any, operator: any) {
        return {
            Criteria: {
                // Operator: 'Eq',
                Operator: SearchCriteriaOperator.ELEM_MATCH,
                Key: ActivityKey.ActivityCustomFields,
                SubKey: key,
                Value: value,
            },
            Operator: operator || void 0,
        }
    }

    buildInputCriteria(
        customFieldKeys: any[] = [],
        value: any,
        extraCriterias: any[] = [],
        operator = SearchOperator.AND
    ) {
        const inputSearchCriteria: any = {
            Operator: operator,
            CriteriaField: [],
        }
        customFieldKeys.forEach((key) => {
            let keyCriteria = {}
            if (ActivityKey[`${key}`]) {
                keyCriteria = this.buildSimpleCriteria(key, value, SearchOperator.OR)
            } else {
                keyCriteria = this.buildCustomFieldCriteria(key, value, SearchOperator.OR)
            }
            inputSearchCriteria.CriteriaField.push(keyCriteria)
        })
        extraCriterias.forEach((extraCriteria) => {
            inputSearchCriteria.CriteriaField.push(extraCriteria)
        })
        return inputSearchCriteria
    }

    getDifferencesSections(activityDetails = [], config = defaultConfig) {
        const {
            historicalFields = defaultHistoricalFields,
            onlyDateFields = defaultDateFormatFields,
            rootPropertySection = defaultRootPropertySection,
            customPropertyFormatters = defaultPropertyFormatters,
            multipleRootOptions = [],
        } = config
        const differences: any = activityDetails
        let allItemsCollapsible = false
        if (differences.length === 1 && !differences[0].displayName) {
            allItemsCollapsible = true
        }
        const newmap = this._getSectionsMap(differences, 'propertySection')
        console.log('[new version] map:', newmap)
        const newdiffarray = this._convertSectionsMapToArray(
            newmap,
            historicalFields,
            onlyDateFields,
            rootPropertySection,
            allItemsCollapsible,
            true,
            customPropertyFormatters,
            multipleRootOptions
        )
        console.log('array:', newdiffarray)
        return newdiffarray
    }

    _getDifferencesSectionsFromArray(
        diffItems = [],
        historicalFields = defaultHistoricalFields,
        onlyDateFields = defaultDateFormatFields,
        rootPropertySection = defaultRootPropertySection,
        customPropertyFormatters = defaultPropertyFormatters,
        multipleRootOptions = []
    ): any {
        const newmap = this._getSectionsMap(diffItems, 'propertyName')
        const newdiffarray = this._convertSectionsMapToArray(
            newmap,
            historicalFields,
            onlyDateFields,
            rootPropertySection,
            true,
            true,
            customPropertyFormatters,
            multipleRootOptions
        )
        return newdiffarray
    }

    _getSectionsMap(diffDetails = [], sectionKey: any) {
        if (!diffDetails || diffDetails.length === 0) {
            return {}
        }
        const differences = diffDetails
        const sectionsMap: any = {}
        differences.forEach((item: any) => {
            if (item[`${sectionKey}`] && item.differences && item.differences.length > 0) {
                const key = item[`${sectionKey}`]
                sectionsMap[`${key}`] = sectionsMap[`${key}`] || []
                sectionsMap[`${key}`].push(item)
            }
        })
        return sectionsMap
    }

    _convertSectionsMapToArray(
        differencesMap = {},
        historicalFields = defaultHistoricalFields,
        onlyDateFields = defaultDateFormatFields,
        rootPropertySection: any,
        allItemsCollapsible = false,
        includeHistoricalFields = true,
        customPropertyFormatters = defaultPropertyFormatters,
        multipleRootOptions = []
    ) {
    return Object.entries(differencesMap)
        .map(([key, value]: [any, any]) => {
            let isCollapsible = allItemsCollapsible || key === rootPropertySection
            // Ignore rootPropertySection if multiple roots are provided.
            if (multipleRootOptions && multipleRootOptions.length > 0) {
                isCollapsible = allItemsCollapsible || multipleRootOptions.includes(key as never)
            }
            let diffItems = value
            if (isCollapsible) {
                diffItems = value
                .reduce((items: any, item: any) => {
                    ;(item.differences || []).forEach((dataItem: any) => {
                        items.push(dataItem)
                    })
                    return items
                }, [])
                .filter((item: any) => item.isSimpleProperty && !item.isEnumerable)
                .filter(
                    (item: any) => !(isObject(item.simpleNewValue) || isObject(item.simpleOriginalValue))
                )
                .filter(
                    (item: any) =>
                    !(
                        (item.simpleNewValue === null || item.simpleNewValue === undefined) &&
                        (item.simpleOriginalValue === null || item.simpleOriginalValue === undefined)
                    )
                )
                .filter((item: any) => {
                    if (includeHistoricalFields) {
                        return true
                    } else {
                        return !historicalFields.includes(item.displayName)
                    }
                })
                .map((item: any) => {
                    return {
                        displayName: item.displayName,
                        displayNameFormatted: startCase(item.displayName),
                        simpleOriginalValueFormatted: this._formatValue(
                            item.simpleOriginalValue,
                            item,
                            onlyDateFields,
                            customPropertyFormatters
                        ),
                        simpleNewValueFormatted: this._formatValue(
                            item.simpleNewValue,
                            item,
                            onlyDateFields,
                            customPropertyFormatters
                        ),
                        isHistoricalItem: historicalFields.includes(item.displayName),
                    }
                })
                const uniqueItems: any = {}
                diffItems.forEach((item: any) => {
                    uniqueItems[`${item.displayNameFormatted}`] = item
                })
                diffItems = Object.values(uniqueItems)
                return {
                    sectionName: this._formatSectionName(key),
                    isCollapsible: isCollapsible,
                    differenceItems: diffItems,
                    isEmpty: diffItems.length === 0,
                }
            }
            const differenceSections =
                this._getDifferencesSectionsFromArray(
                    diffItems,
                    historicalFields,
                    onlyDateFields,
                    rootPropertySection,
                    customPropertyFormatters,
                    multipleRootOptions
                ) || []
            return {
                sectionName: this._formatSectionName(key),
                isCollapsible: isCollapsible,
                differenceItems: null,
                originalItems: diffItems,
                differenceSections: differenceSections,
                isEmpty: differenceSections.every(
                    (diffSection: any) => diffSection.differenceItems.length === 0
                ),
                isArray: diffItems.every((diffItem: any) => !!diffItem.isEnumerableItem),
            }
        })
        .filter((section) => !section.isEmpty)
    }

    _formatValue(
        value: any,
        differenceItem: any,
        onlyDateFields = defaultDateFormatFields,
        customPropertyFormatters: any = {}
    ) {
        if (customPropertyFormatters[`${differenceItem.displayName}`]) {
            const customFormat = customPropertyFormatters[`${differenceItem.displayName}`]
            return customFormat(value)
        }
        if (isBoolean(value)) {
            return value
        }
        if (isNumber(value)) {
            return value
        }
        if (typeof value === 'string') {
            const valueReplaced = value.replace(/[0-9]/g, 'X')
            if (valueReplaced.includes('XXXX-XX-XXTXX:XX:XX')) {
                let showTime = true
                if (onlyDateFields.includes(differenceItem.displayName)) {
                    showTime = false
                }
                const formattedDate = this.dateTimeServices.toLocalTime(value, showTime)
                if (formattedDate) {
                    return formattedDate
                }
            }
        }
        return value
    }

    _formatSectionName(sectionName = '') {
        const splitted = sectionName.split('.')
        return splitted[splitted.length - 1]
    }
}

