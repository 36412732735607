import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IconRefEnum,DateTimeService } from 'library';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  IBondProClientInstanceInfoRequestDto,
  IBondProNGCarrierClientDto,
  ILicenseKeyRequestDto,
  INGCarrierClientInstanceDto,
  INGCarrierLicensesInstanceDto,
  INGLicenseKeyDto,
  INGLicenseStatusTypeCode,
  INGLicenseTypeInstanceDto,
  INGStatusTypeInstanceDto,
  IServiceResponseDto,
  IViewClientLicenseInfo,
} from '../../../interfaces/license-interfaces';
import { CommonHelperService } from '../../../services/common-helper.service';
import { LicenseService } from '../../../services/license.service';
import { MessageHelperService } from '../../../services/message-helper.service';
import { InformationModalComponent } from '../information-modal/information-modal.component';
import { ActivityHistoryDialogComponent } from '../../activity-history/activity-history-dialog/activity-history-dialog.component';
import {
  ActivityHistoryService,
  ActivityKey,
  SearchCriteriaOperator,
  SearchOperator,
} from '../../../services/activity-history.service';

dayjs.extend(utc);

@Component({
  selector: 'app-add-license-modal',
  templateUrl: './add-license-modal.component.html',
  styleUrls: ['./add-license-modal.component.scss'],
})
export class AddLicenseModalComponent implements OnInit, OnDestroy {
  public licenseModal!: IViewClientLicenseInfo;

  @Input('licenseModal')
  public set _licenseModal(value: IViewClientLicenseInfo) {
      if (value) {
          this.licenseModal = value;
          this.setDates(this.licenseModal);
      }
  }

  @Input() statusList!: INGStatusTypeInstanceDto[];
  @Input() clientId!: number;
  @Input() clientModel!: IBondProNGCarrierClientDto;

  private _subs = [] as Subscription[];
  closeIcon = IconRefEnum.BpClose;
  bpView = IconRefEnum.BpView;
  calendarIcon = IconRefEnum.BpCalendar;
  editIcon = IconRefEnum.Edit;
  editMode: boolean = false;
  formAddLicense!: FormGroup;
  licenseTypeList!: Array<INGLicenseTypeInstanceDto>;
  expDateIsRequired: boolean = true;
  disabledKey: boolean = true;
  licenseKey: string = '';
  minDate: any;
  clientInstances: INGCarrierClientInstanceDto[] = [];
  decripLicenseModel: any;
  showDecripLicense: boolean = false;

  activityIcon = IconRefEnum.History;

  constructor(
    private licenseService: LicenseService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddLicenseModalComponent>,
    private _commonHelperService: CommonHelperService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private messageService: MessageHelperService,
    private activityHistoryService: ActivityHistoryService,
    private dateTimeService: DateTimeService
  ) {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  ngOnInit() {
    this.loadAllClientInstancesList();
    this.buildForm();
    this.getLicenseType();
  }

  buildForm(): void {
    if (this.licenseModal) {
      this.expDateIsRequired =
        this.licenseModal?.expirationDate !== undefined &&
        this.licenseModal?.expirationDate !== null
          ? true
          : false;
    }

    this.formAddLicense = this.formBuilder.group({
      licenseTypeId: [this.licenseModal?.licenseTypeId, [Validators.required]],
      nGCarrierClientInstanceId: [
        this.licenseModal?.ngCarrierClientInstanceId,
        [Validators.required],
      ],
      effectiveDate: [this.licenseModal?.effectiveDate, [Validators.required]],
      expirationDate: [
        this.licenseModal?.expirationDate,
        [Validators.required],
      ],
      notes: [this.licenseModal?.notes],
      neverExpires: [!this.expDateIsRequired],
    });
    if (this.licenseModal) {
      this.licenseKey = this.licenseModal.licenseKey ?? '';
      this.formAddLicense.disable();
    }
  }

  setDates(licenseModal: IViewClientLicenseInfo) {
    if (licenseModal?.effectiveDate) {
      licenseModal.effectiveDate = dayjs
        .utc(licenseModal.effectiveDate)
        .local()
        .toDate();
    }
    if (licenseModal?.expirationDate) {
      licenseModal.expirationDate = dayjs
        .utc(licenseModal.expirationDate)
        .local()
        .toDate();
    }
  }

  loadAllClientInstancesList() {
    const requestDto = {
      NGCarrierClientId: this.clientId,
    } as IBondProClientInstanceInfoRequestDto;
    this._subs.push(
      this.licenseService
        .getNGAllCarrierInstances(requestDto)
        .subscribe((n) => {
          this.clientInstances = n;
        })
    );
  }

  onEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      if (
        this.licenseModal.licenseStatusTypeCode ===
          INGLicenseStatusTypeCode.PENDING ||
        this.licenseModal.licenseStatusTypeCode ===
          INGLicenseStatusTypeCode.PENDING_ACTIVATION
      ) {
        this.formAddLicense.enable();
        this.formAddLicense.controls.nGCarrierClientInstanceId.disable();
        if (!this.expDateIsRequired) {
          this.formAddLicense.controls.expirationDate.disable();
        }
      }
      // else {
      //   if (this.licenseModal.licenseStatusTypeCode !== INGLicenseStatusTypeCode.EXPIRED
      //     && this.licenseModal.licenseStatusTypeCode !== INGLicenseStatusTypeCode.ACTIVE
      //     && this.licenseModal.licenseStatusTypeCode !== INGLicenseStatusTypeCode.INACTIVE)
      //   {
      //     this.formAddLicense.controls.notes.enable();
      //   }
      // }
    }

    /* if (this.licenseModal?.licenseStatusTypeCode !== INGLicenseStatusTypeCode.EXPIRED)
    {
      this.editMode = false
    } */
  }

  onSave() {
    const licenseModel = this.formAddLicense.getRawValue();
    if (licenseModel.effectiveDate) {
      licenseModel.effectiveDate = licenseModel.effectiveDate._isAMomentObject
        ? licenseModel.effectiveDate.toDate()
        : licenseModel.effectiveDate;
    }
    if (licenseModel.expirationDate) {
      licenseModel.expirationDate = licenseModel.expirationDate._isAMomentObject
        ? licenseModel.expirationDate.toDate()
        : licenseModel.expirationDate;
    }
    licenseModel.ngCarrierClientId = this.clientModel.ngCarrierClientId;
    licenseModel.licenseName = this.getLicenseTypeName(
      licenseModel.licenseTypeId
    );

    if(licenseModel.licenseTypeId)
    {
      licenseModel.licenseTypeCode = this.licenseTypeList.find(x=> x.licenseTypeId === licenseModel.licenseTypeId)?.licenseTypeCode;

    }

    if (this.licenseModal?.licenseId) {
      licenseModel.licenseStatusTypeId = this.licenseModal.licenseStatusTypeId
      licenseModel.licenseStatusTypeName = this.getLicenseStatusTypeName(
        this.licenseModal.licenseStatusTypeId
      );
      this.updateLicense(licenseModel, this.licenseModal.licenseId);
    } else {
      this.createLicense(licenseModel);
    }
  }

  getLicenseTypeName(licenseTypeId: number | undefined) {
    const foundItem = (this.licenseTypeList || []).find(
      (item) => item.licenseTypeId === licenseTypeId
    );
    return foundItem ? foundItem.licenseName : void 0;
  }

  getLicenseStatusTypeName(licenseStatusTypeId: number | undefined) {
    const foundItem = (this.statusList || []).find(
      (item) => item.statusTypeId == licenseStatusTypeId
    );
    return foundItem ? foundItem.statusName : void 0;
  }

  formatDate(dateChange: any) {
    const modelDate = new Date(dateChange).toString();
    return this._commonHelperService.changeDateFormat(modelDate, 'yyyy-MM-dd');
  }

  onDateValueChanged() {
    const dateSeleted = new Date(
      this.formAddLicense.controls.effectiveDate.value
    );
    const currentDate = new Date();
    dateSeleted.setDate(dateSeleted.getDate() + 1);
    this.minDate =
      dateSeleted.getTime() > currentDate.getTime()
        ? dateSeleted
        : this.minDate;
  }

  createLicense(licenseModel: INGCarrierLicensesInstanceDto) {
    this._subs.push(
      this.licenseService
        .createNGCarrierLicenses(licenseModel)
        .subscribe((response: IServiceResponseDto) => {
          if (response.success) {
            this.toastr.success('Has been updated successfully', 'Success');
            this.dialogRef.close(true);
          } else {
            this.showErrorModal(response.errorMessages);
          }
        })
    );
  }

  updateLicense(instanceModel: INGCarrierLicensesInstanceDto, licenseId: any) {
    instanceModel.licenseId = licenseId;
    this._subs.push(
      this.licenseService
        .updateNGCarrierLicenses(instanceModel)
        .subscribe((response: IServiceResponseDto) => {
          if (response.success) {
            this.toastr.success('The License has been updated!', 'Success');
            this.dialogRef.close(true);
          } else {
            this.toastr.error(
              'There was an error, please try again',
              'Error'
            );
          }
        })
    );
  }

  showErrorModal(errorMessages: string[]) {
    const dialogRef = this.dialog.open(InformationModalComponent);
    dialogRef.componentInstance.title = 'Create License';
    dialogRef.componentInstance.message =
      'Unable to create a license due to the following:';
    dialogRef.componentInstance.messageDetails = errorMessages;
    dialogRef.componentInstance.messageIcon = IconRefEnum.BpWarning;
    dialogRef.afterClosed().subscribe((result: any) => {});
  }

  onCloseModal(): void {
    if (this.licenseModal) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close();
    }
  }

  onChangeExpireCheck(data: any) {
    this.expDateIsRequired = !data.checked;
    if (this.expDateIsRequired) {
      this.formAddLicense.get('expirationDate')?.enable();
    } else {
      this.formAddLicense.get('expirationDate')?.reset();
      this.formAddLicense.get('expirationDate')?.disable();
    }
  }

  getLicenseType() {
    const ACTIVE_STATUS_TYPE_ID = 1
    this._subs.push(
      this.licenseService
        .getNGLicenseTypes()
        .subscribe((response: INGLicenseTypeInstanceDto[]) => {
          this.licenseTypeList = response.filter((l) => l.statusTypeId === ACTIVE_STATUS_TYPE_ID && l.licenseTypeCode !== 'B2B_PORTAL').sort((a, b) => a.licenseName.localeCompare(b.licenseName));
          // we were asked to exclude B2B_POTAL from here
        })
    );
  }

  onViewKey() {
    if (this.showDecripLicense) {
      this.showDecripLicense = false
      return
    }
    const licenseKeyRequestDto = {
      licenseKey: this.licenseModal.licenseKey,
    } as ILicenseKeyRequestDto;

    this._subs.push(
      this.licenseService
        .viewLicenseKey(licenseKeyRequestDto)
        .subscribe((response: IServiceResponseDto) => {
          if (response.success) {
            this.decripLicenseModel = response.items[0];
             this.decripLicenseModel.statusLabel = this._commonHelperService.getStatusByID(this.decripLicenseModel.licenseStatusId);
            this.decripLicenseModel.expiration =  this.dateTimeService.toLocalTime(this.decripLicenseModel.expiration, true)
            this.showDecripLicense = true

            // this.licenseKey = response.items[0];
            // this.formAddLicense.controls.expirationDate.markAsPristine()
          } else {
            const messageModel = {
              title: 'View License',
              message: 'Unable to view license due to the following:',
            };
            this.messageService.showErrorModal(
              messageModel,
              response.errorMessages
            );
          }
        })
    );
  }

  onRegenerateKey() {
    const licenseModel: INGLicenseKeyDto = this.formAddLicense.value;
    licenseModel.neverExpires = false;

    licenseModel.effectiveDate = this.formatDate(licenseModel.effectiveDate);
    if (licenseModel.expirationDate) {
      licenseModel.expirationDate = this.formatDate(
        licenseModel.expirationDate
      );
    }
    if (this.licenseModal.dbCorporationId) {
      licenseModel.tenantId = this.licenseModal.dbCorporationId;
    }
    if (this.licenseModal.licenseId) {
      licenseModel.licenseId = this.licenseModal.licenseId;
    }
    if (this.licenseModal.licenseType) {
      licenseModel.licenseType = this.licenseModal.licenseType;
    }

    if (this.licenseModal.licenseTypeCode) {
      licenseModel.licenseTypeCode = this.licenseModal.licenseTypeCode;
    }

    if (this.licenseModal.licenseStatusTypeId) {
      licenseModel.licenseStatusTypeId = this.licenseModal.licenseStatusTypeId;
    }
    licenseModel.ngCarrierClientId = this.clientModel.ngCarrierClientId;
    licenseModel.licenseName = this.getLicenseTypeName(
      this.licenseModal.licenseTypeId
    );
    licenseModel.licenseStatusTypeName = this.getLicenseStatusTypeName(
      this.licenseModal.licenseStatusTypeId
    );

    this._subs.push(
      this.licenseService
        .regenerateKeyNGSubscription(licenseModel)
        .subscribe((response: IServiceResponseDto) => {
          if (response.success) {
            this.toastr.success(
              'The License key has been regenerated and changes have been saved!',
              'Success'
            );
            this.licenseKey = response.items[0];
            this.decripLicenseModel.tenantId = licenseModel.tenantId;
            this.decripLicenseModel.expiration = !licenseModel.expirationDate ?  new Date(2100, 1, 1) : licenseModel.expirationDate;
            this.decripLicenseModel.statusLabel = this._commonHelperService.getStatusByID(licenseModel.licenseStatusTypeId);
            this.decripLicenseModel.licenseTypeCode = licenseModel.licenseTypeCode;


            this.formAddLicense.controls.expirationDate.markAsPristine();
          } else {
            const messageModel = {
              title: 'Regenerate License',
              message:
                'Unable to regenerate key this license due to the following:',
            };
            this.messageService.showErrorModal(
              messageModel,
              response.errorMessages
            );
          }
        })
    );
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  showActivity() {
    const dialogRef = this.dialog.open(ActivityHistoryDialogComponent, {
      maxHeight: '100vh',
    });
    dialogRef.componentInstance.classification = 'License';
    dialogRef.componentInstance.extraColumns = [
      {
        field: 'LicenseType',
        title: 'License Type',
      },
    ];
    dialogRef.componentInstance.extraDetailHeaders = [
      {
        key: 'LicenseId',
        label: 'License ID',
      },
      {
        key: 'LicenseType',
        label: 'License Type',
      },
    ];
    dialogRef.componentInstance.extraGridDetailHeaders = [
      {
        value: this.clientModel.ngCarrierClientId,
        label: 'Client ID',
      },
      {
        value: this.clientModel.clientName,
        label: 'Client Name',
      },
      {
        value: this.licenseModal.licenseId,
        label: 'License ID',
      },
    ];
    dialogRef.componentInstance.diffConfig = {
      historicalFields: ['CreatedBy', 'CreateDate', 'ModifyDate', 'ModifiedBy'],
      onlyDateFields: [],
      customPropertyFormatters: {},
      rootPropertySection: 'License Section',
    };
    const tenantIdCriterias = this.activityHistoryService.buildCriteriaFilter(
      'LicenseId',
      this.licenseModal.licenseId
    );
    dialogRef.componentInstance.extraSearchCriterias = tenantIdCriterias;
    dialogRef.componentInstance.filterConfig = {
      enabled: true,
      placeholder: 'Search by Description',
      buildSearchCriterias: (searchValue) => {
        let criterias: any[] = [];
        if (!searchValue) {
          return criterias;
        }
        const descriptionCriterias = [
          {
            Operator: SearchOperator.AND,
            Criteria: {
              Operator: SearchCriteriaOperator.EQ,
              Key: ActivityKey.Description,
              Value: searchValue,
            },
          },
        ];
        criterias = criterias.concat(descriptionCriterias);
        return criterias;
      },
    };
    dialogRef.afterClosed().subscribe((statusTypeId: any) => {
      console.log('status type id', statusTypeId);
      if (statusTypeId) {
      }
    });
  }
}
