<div class="bp-left-sidebar"
     [ngClass]="{'expanded': _sideBarService.isExpanded && _sideBarService.isVisible, 'collapsed': !_sideBarService.isExpanded || !_sideBarService.isVisible, 'invisible': !_sideBarService.isVisible}">

  <!-- Logo & Navigation items -->
  <div class="navbar-container">

    <div class="logo">
      <span>
        <img class="collapsed-logo" src="assets/images/BP-default@2x.png" alt="BP" />
        <img class="expanded-logo" src="assets/images/BP-expanded@2x.png" alt="Bond Pro" />
      </span>
    </div>

    <div class="navbar" style="overflow: hidden;">

      <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl" class="sidebar-tree">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
          <li class="mat-tree-node">
            <a routerLink="{{node.url}}" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <div class="tree-node-backdrop"></div>

              <div class="tree-node-content">
                <div class="tree-node-icon" *ngIf="node.icon" [ngClass]="node.customClass" [bpIcon]="node.icon"></div>
                <div class="tree-node-text" title="{{node.name}}">
                  <span>{{node.name}}</span>
                  <!-- use a disabled button to provide padding for tree leaf -->
                  <!-- <button mat-icon-button disabled class="toggle-button"></button> -->
                </div>
              </div>
            </a>
          </li>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <li>
            <div class="mat-tree-node nested-tree-holder" matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name" [ngClass]="{'active': node._isActive}">
              <div class="tree-node-backdrop"></div>

              <div class="tree-node-content">
                <div class="tree-node-icon" *ngIf="node.icon" [ngClass]="node.customClass" [bpIcon]="node.icon"></div>
                <div class="tree-node-text" title="{{node.name}}">
                  <span>{{node.name}}</span>
                  <button mat-icon-button
                          class="toggle-button">
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <ul [class.sidebar-tree-invisible]="!treeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </ul>
          </li>
        </mat-nested-tree-node>
      </mat-tree>

    </div>

  </div>

  <!-- Toggle Button -->
  <div class="actions">

    <div class="visibility-toggler"
         (click)="_sideBarService.toggleSidebarVisibility()">
      <i class="bpi-icon" [ngClass]="{'bpi-Hide': _sideBarService.isVisible, 'bpi-View': !_sideBarService.isVisible}"></i>
      <span class="text">HIDE NAVIGATION</span>
    </div>

    <div class="expansion-toggler"
         (click)="_sideBarService.toggleSidebarExpansion()">
      <i class="bpi-icon bpi-Chevron-Right"></i>
      <i class="bpi-icon bpi-Chevron-Right icon-2"></i>
    </div>

  </div>

</div>

<div class="visibility-toggler-floating"
     (click)="_sideBarService.toggleSidebarVisibility()"
     [ngClass]="{'visible': !_sideBarService.isVisible}">
  <i class="bpi-icon bpi-view"></i>
</div>
