<div class="bp-accordion" [ngClass]="{'expanded': isExpanded, 'disabled': isDisabled}">
    
    <div class="accordion-toggler" (click)="toggleExpansion()">
        <mat-icon>expand_more</mat-icon>
    </div>

    <div class="accordion-title" (click)="toggleExpansion()">
        {{title}}
        <div class="accordion-hint" *ngIf="hint">{{hint}}</div>
    </div>
    
    <mat-accordion>
        <mat-expansion-panel [disabled]="isDisabled" [expanded]="isExpanded" hideToggle>
            <ng-content></ng-content>
        </mat-expansion-panel>
    </mat-accordion>

</div>