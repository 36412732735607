import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
  } from '@angular/core';
  import { MatDialog, MatDialogRef } from '@angular/material/dialog';
  import { FormBuilder, FormGroup } from '@angular/forms';
  import { IBpeGridColumn, IconRefEnum, DateTimeService } from 'library';
import { ToastrService } from 'ngx-toastr';
import { IBondProClientInfoRequestDto, INGCarrierClientModel, IPagingSettings } from '../../../interfaces/license-interfaces';
import { ActivityHistoryService, ActivityKey } from '../../../services/activity-history.service';
import { ActivityHistoryDetailsDialogComponent } from '../activity-history-details-dialog/activity-history-details-dialog.component';
import { ActivityHistoryDynamicComponent } from '../activity-history-dynamic-grid/activity-history-dynamic-grid.component';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { upperFirst, upperCase } from 'lodash'

export interface DetailHeader {
    label: string;
    key: string;
}

export interface GridDetailHeader {
    label: string;
    value: string | number | undefined;
}

export interface FilterConfig {
    enabled: boolean;
    placeholder: string;
    buildSearchCriterias: (searchValue: string) => any[];
}

@Component({
    selector: 'app-activity-history-dialog',
    templateUrl: './activity-history-dialog.component.html',
    styleUrls: ['./activity-history-dialog.component.scss'],
})
export class ActivityHistoryDialogComponent implements OnInit, OnDestroy {

    @Input() classification!: string;
    @Input() extraColumns!: IBpeGridColumn[];
    @Input() extraSearchCriterias!: any[];
    @Input() diffConfig!: any;
    @Input() extraDetailHeaders: DetailHeader[] = [];
    @Input() extraGridDetailHeaders: GridDetailHeader[] = [];
    @Input() filterConfig!: FilterConfig;

    @Output() onViewDetail = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();

    historyData!: any;
    showActivityDetails!: boolean;
    differenceSections!: any[];
    showSelectedSectionDifferences!: boolean;
    selectedSectionDifferences!: any[];
    showArraySectionDifferences!: boolean;
    selectedArraySection!: any;
    filters!: any;
    filterCriterias!: any[];

    showViewMore: boolean = true;
    columnDefs: IBpeGridColumn[] = [];
    pagination!: IPagingSettings;

    closeIcon = IconRefEnum.BpClose;

    formFilter!: FormGroup;
    searchIcon = IconRefEnum.BpSearch;
    _sub!: Subscription;
  
    constructor(
        private dialogRef: MatDialogRef<ActivityHistoryDialogComponent>,
        private activityHistoryService: ActivityHistoryService,
        private dateTimeServices: DateTimeService,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,) {}
  
    ngOnInit() {
        this.buildForm();
        this.subscribeToSearchInput();
        this.historyData = { items: [] }
        this.extraColumns = this.extraColumns || []
        this.filterCriterias = this.filterCriterias || []
        this.extraSearchCriterias = this.extraSearchCriterias || []
        this.classification = this.classification || ''
        // main details
        this.showActivityDetails = false
        this.differenceSections = []
  
        // more details
        this.showSelectedSectionDifferences = false
        this.selectedSectionDifferences = []
  
        // array section
        this.showArraySectionDifferences = false
        this.selectedArraySection = null

        const ActivityColumn = {
            activityId: {
              field: 'id',
              title: 'Activity Id',
              isVisible: true,
            },
            section: {
              field: 'section',
              title: 'Section',
              isVisible: true,
            },
            description: {
              field: 'description',
              title: 'Description',
              isVisible: true,
            },
          
            createdDate: {
              field: 'createdDateTime',
              title: 'Created Date/Time',
              bodyCellTemplate: (col: any, row: any) => {
                return this.dateTimeServices.toLocalTime(row.createdDateTime, true)
              },
              isVisible: true,
            },
            createdBy: {
              field: 'createdBy',
              title: 'Created By',
              isVisible: true,
            },
        }
  
        this.columnDefs = [
          ActivityColumn.activityId,
          // disable sort since sort cannot be applied on custom fields
          ...this.extraColumns.map((item) => ({ ...item, enableSort: false })),
          ActivityColumn.section,
          ActivityColumn.description,
          ActivityColumn.createdDate,
          ActivityColumn.createdBy,
        ]
        this.pagination = {
            page: 1,
            pageSize: 20,
            sortColumn: ActivityKey.CreatedDateTime,
            direction: 'DESC',
            totalRecords: 0,
        };
        this.getGridData(this.pagination);
    }
  
    ngOnDestroy() {
        this._sub.unsubscribe();
    }

    buildForm(): void {
        this.formFilter = this.formBuilder.group({
          search: [null],
        });
    }

    subscribeToSearchInput() {
        const inputcontrol = this.formFilter.controls.search;
        this._sub = inputcontrol.valueChanges
          .pipe(debounceTime(500))
          .subscribe((dataValue) => {
            this.onFilterByChange(dataValue);
          });
    }

    onFilterByChange(search: string) {
        this.filterCriterias = this.filterConfig.buildSearchCriterias(search);
        this.applyFilters()
    }

    onPageChange(event: any) {
        this.pagination.page = event.page;
        this.pagination.pageSize = event.pageSize;
        this.getGridData(this.pagination)
    }
    
    onSort(event: any) {
        this.pagination.direction = event.direction;
        this.pagination.sortColumn = event.sortColumn;
        this.getGridData(this.pagination)
    }

    onGoDetails(value: any) {
        console.log('on selected change', value);
        // this.onViewActivityItem(dataItem)
        this.differenceSections = this.activityHistoryService.getDifferencesSections(
            value.difference,
            this.diffConfig
        )
        const dialogRef = this.dialog.open(ActivityHistoryDetailsDialogComponent, {
            maxWidth: '100vw',
            width: '1265px',
            // maxHeight: '100vh'
        });
        dialogRef.componentInstance.selectedActivity = value;
        dialogRef.componentInstance.extraDetailHeaders = this.extraDetailHeaders;
        dialogRef.componentInstance.differenceSections = this.differenceSections;
        dialogRef.componentInstance.onSectionSelected.subscribe(
            (sectionSelected: any) => {
                this.onSectionSelected(sectionSelected, value)
            }
        );
        dialogRef.afterClosed().subscribe((statusTypeId: any) => {
            console.log('status type id', statusTypeId)
            if (statusTypeId) {}
        });
    }

    onCloseModal() {
      this.onModalClosed();
      this.dialogRef.close();
    }

    // call this on ng on changes
    applyFilters() {
        if (this.filterCriterias) {
            this.getGridData(this.pagination)
        }
    }
  
    getGridData(pagination: IPagingSettings) {
        let extraCriterias: any = []
        let classificationCriterias: any = []
        if (this.extraSearchCriterias) {
            extraCriterias = extraCriterias.concat(this.extraSearchCriterias)
        }
        if (this.filterCriterias) {
            extraCriterias = extraCriterias.concat(this.filterCriterias)
        }
        if (this.classification) {
            classificationCriterias.push({
            Operator: 'AND',
            Criteria: {
                Operator: 'Eq',
                Key: 'Classification',
                Value: this.classification,
            },
            })
        }
        const request = {
            Search: {
                CriteriaField: [...classificationCriterias, ...extraCriterias],
                OrderBy: {
                    Key: upperFirst(pagination.sortColumn || ActivityKey.CreatedDateTime),
                    Direction: upperCase(pagination.direction || 'DESC'),
                },
                GroupByCorrelation: true,
            },
            IncludeActivityData: true,
            IgnoreSnapshots: false,
            Paging: {
                PageNumber: pagination.page,
                PageSize: pagination.pageSize,
            },
        }
        this.getActivityHistoryList(request)
    }
  
    getActivityHistoryList(request: any) {
        this.activityHistoryService.getActivityHistoryList(request)
            .subscribe(
                (response) => {
                    if (response?.hasError) {
                        this.toastr.error(
                            response?.errorMessages[0] || 'Error trying to get activity history data'
                        )
                    return
                    }
                    this.historyData = this.activityHistoryService.mapActivityHistoryData(response)
                    this.pagination.totalRecords = this.historyData.totalRecords
                },
                (error) => {
                    if (error.data && error.data.responseStatus) {
                        this.toastr.error(error.data.responseStatus.message)
                    }
                },
            );
    }

    onSectionSelected(sectionDifferences: any, selectedActivity: any) {
        if (sectionDifferences.isArray) {
            this.showArraySectionDifferences = true
            this.selectedArraySection = sectionDifferences
            const dialogRef = this.dialog.open(ActivityHistoryDynamicComponent, {
                maxWidth: '100vw',
                width: '1250px',
            });
            dialogRef.componentInstance.selectedActivity = selectedActivity;
            dialogRef.componentInstance.extraDetailHeaders = this.extraDetailHeaders;
            dialogRef.componentInstance.differenceSection =  this.selectedArraySection;
            dialogRef.afterClosed().subscribe((statusTypeId: any) => {
                if (statusTypeId) {}
            });
        } else {
            this.showSelectedSectionDifferences = true
            this.selectedSectionDifferences = sectionDifferences.differenceSections
            const dialogRef = this.dialog.open(ActivityHistoryDetailsDialogComponent, {
                maxWidth: '100vw',
                width: '1250px',
            });
            dialogRef.componentInstance.differenceSections = this.selectedSectionDifferences;
            dialogRef.componentInstance.extraDetailHeaders = this.extraDetailHeaders;
            dialogRef.componentInstance.selectedActivity= selectedActivity;
            dialogRef.afterClosed().subscribe((statusTypeId: any) => {
                if (statusTypeId) {}
            });
        }
    }

    onModalClosed() {
        this.historyData = { items: [] }
        this.filters = null
    }
}
