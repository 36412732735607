<div class="tenant-details">
  <div class="header">
    <app-filter-grid
      [searchPlaceholder]="'Search by Tenant ID'"
      (changeFilter)="onChangeFilter($event)"
      [type]="'number'">
    </app-filter-grid>
    <div class="header__actions">
      <span (click)="showActivity()" class="activity-icon" [bpMatIcon]="activityIcon"></span>
      <button type="button" class="primary md bp-icon-button" (click)="onAddEditInstance()" [disabled]="disabled">
        <span class="btn-icon" [bpMatIcon]="addIcon"></span>
        <span class="btn-text">Add</span>
      </button>
    </div>
  </div>

  <bpe-grid
    [showViewMore]="showViewMore"
    [columnDefs]="columnDefs"
    [data]="_clientInstances"
    [disabled]="disabled"
    [optionsTmpl]="myTemplate"
    [pagination]="pagination"
    (onPageChange)="onPageChange($event)"
    (onSort)="onSortChange($event)"
    (onViewDetails)="onAddEditInstance($event)">
  </bpe-grid>
</div>

<ng-template #myTemplate let-item="item">
  <button mat-menu-item class="item-btn" (click)="onRemove(item)">
    <span>Remove</span>
  </button>
</ng-template>
