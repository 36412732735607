import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'bp-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss']
})

export class BpAccordionComponent {
    @Input() title!: string;
    @Input() hint!: string;
    @Input() isExpanded: boolean = false;
    @Input() isDisabled: boolean = false;
    @Output() toggleEvent: EventEmitter<boolean> = new EventEmitter();
    
    constructor() {}

    toggleExpansion() {
        if (this.isDisabled) return;
        this.isExpanded = !this.isExpanded;
        this.toggleEvent.emit(this.isExpanded);
    }
}
