import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiSettings } from '../apiSettings';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private configuration: IServerConfiguration = {ApiAddress : "", IdentityServerAddress:""};
  private apiUrl = ApiSettings.api_url + '/Configuration';

  constructor(private http: HttpClient) { }
  loadConfig() {
    return this.http.get<IServerConfiguration>(this.apiUrl +'/ConfigurationData')
      .toPromise()
      .then(result => {
        this.configuration = <IServerConfiguration>(result);
      }, error => console.error(error));
  }
  getBuildNumber(): Observable<IBuildInfo> {
    return this.http.get<IBuildSettings>(this.apiUrl +'/BuildNumber')
      .pipe(
        map((buildSettings) => {
          const ciphertextB64 = buildSettings.settings;
          const key = environment.encryptKey;
          const decrypted: any = ApiSettings.decryptData(key, ciphertextB64);
          return JSON.parse(decrypted);
        })
      );
  }
  get apiAddress() {
    return this.configuration.ApiAddress;
  }
  get identityServerAddress() {
    return this.configuration.IdentityServerAddress;
  }
}
export interface IServerConfiguration {
  ApiAddress: string;
  IdentityServerAddress: string;
}

export interface IBuildSettings {
  settings: string;
}

export interface IBuildInfo {
  appVersion: string;
  appBuild: string;
}
