<div class="client-license">
  <app-license-client-header
    [clientModel]="clientModel"
    [statusList]="statusList"
    (updateStatus)="loadClientInfo(); loadClientLicenseList(); loadRelationshipList();">
  </app-license-client-header>
  <app-add-edit-client
    [clientModel]="clientModel"
    [statusList]="statusList"
    [disabled]="isClientReadOnly"
    (updateModel)="onUpdateModel($event)">
  </app-add-edit-client>

  <bp-accordion [title]="'Tenant Instances'" [isExpanded]="isExpanded">
    <app-tenant-details
      [disabled]="isClientReadOnly"
      [instancesModel]="clientInstances"
      [clientModel]="clientModel"
      [pagination]="pagination"
      (reloadTenantList)="onLoadTenantList($event)"
      (pageChange)="onPageChangeTenant($event)"
      (sortChange)="onSortChangeTenant($event)">
    </app-tenant-details>
  </bp-accordion>

  <bp-accordion [title]="'Relationship'" [isExpanded]="isExpanded">
    <app-relationship-details
      [disabled]="isClientReadOnly"
      [relationships]="relationships"
      [clientModel]="clientModel"
      [statusList]="statusList"
      [pagination]="paginationRelationships"
      (reloadRelationshipsList)="onLoadRelationshipsList($event)"
      (pageChange)="onPageChangeRelationships($event)"
      (sortChange)="onSortChangeRelationships($event)">
    </app-relationship-details>
  </bp-accordion>

  <bp-accordion [title]="'License & Subscription'" [isExpanded]="isExpanded">
    <app-license-details
    [disabled]="isClientReadOnly"
    [clientId]="_clientId"
    [licenseLicense]="clientLicenses"
    [pagination]="paginationLicense"
    [statusList]="statusList"
    [clientModel]="clientModel"
    (reloadLicenseList)="onLoadLicenseList($event)"
    (pageChange)="onPageChangeLicense($event)"
    (sortChange)="onSortChangeLicense($event)">
  </app-license-details>
  </bp-accordion>

</div>
