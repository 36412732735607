import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, ViewEncapsulation, TemplateRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IconRefEnum } from '../../enums/iconRefEnum';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'bp-stateful-textbox',
  templateUrl: './bp-stateful-textbox.component.html',
  styleUrls: ['./bp-stateful-textbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BpStatefulTextboxComponent,
      multi: true,
    }
  ]
})

export class BpStatefulTextboxComponent implements ControlValueAccessor, OnChanges {
  @Input() parentForm!: FormGroup;
  @Input() filedName!: string;
  @Input() label!: string;
  @Input() maxInputLengt!: string;
  @Input() state!: string;

  @Output() onValueChange = new EventEmitter<any>();

  public value!: string;

  public changed!: (value: string) => void;
  public touched!: () => void;
  public isDisabled!: boolean;

  bpCheckMark = IconRefEnum.BpCheckMark;
  errorMark = IconRefEnum.BpClose;

  userInputUpdate = new Subject<string>();

  constructor() {}


  ngOnChanges(changes: SimpleChanges) {
    if(changes.state){
      
    }
  }

  ngOnInit() {
    this.userInputUpdate.pipe(
      debounceTime(500)
    ).subscribe((searchTerm: string) => {
      this.onValueChange.emit({ text: searchTerm});
    })
  }

  writeValue(value: any): void {
    this.value = value;
  }

  public onChange(event: Event): void {
    const value: string =
      (<HTMLInputElement>event.target).value;

    this.changed(value);
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }
  registerOnTouched(fn: any): void {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled
  }

  onCustomInputChange(searchTerm: string) {
    this.userInputUpdate.next(searchTerm);
  }

  ngOnDestroy() {
    this.userInputUpdate.complete();
  }
}
