<div class="activity-diff-grid">
    <div layout="row">
      <div
        *ngIf="!differenceSections || differenceSections.length === 0"
        flex
        class="empty-layout"
      >
        <h3>No changes to display</h3>
      </div>
    </div>
    
    <div
        *ngIf="differenceSections && differenceSections.length > 0"
        class="diff-grid"
    >
      <div class="layout-row flex-100 diff-grid__header">
        <div class="flex-20 diff-grid__cell" flex="20">
          <div class="bpe-data-label diff-grid__header__field">{{ headerLabels.propertyName }}</div>
        </div>
        <div class="flex-33 diff-grid__cell" flex="33">
          <div class="bpe-data-label">{{ headerLabels.oldValue }}</div>
        </div>
        <div class="flex-33 diff-grid__cell" flex="33">
          <div class="bpe-data-label">{{ headerLabels.newValue }}</div>
        </div>
      </div>
  
      <div *ngFor="let diffSection of differenceSections; first as isFirst" class="diff-grid__section">
        <div *ngIf="diffSection.isCollapsible && !diffSection.isEmpty">
            <mat-expansion-panel (opened)="collapsedSection[diffSection.sectionName] = true"
                    (closed)="collapsedSection[diffSection.sectionName] = false" [expanded]="isFirst">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ diffSection.sectionName }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div
                    class="section-list"
                    *ngFor="let diffItem of diffSection.differenceItems"
                >
                    <div class="layout-row flex-100 section__item" [ngClass]="diffItem.isHistoricalItem ? 'section__item--historical' : ''">
                        <div class="flex-20 section__item__identifier-name diff-grid__cell" flex="20">
                            <div class="bpe-data-label">{{ diffItem.displayNameFormatted }}</div>
                        </div>
                        <div class="flex-33 diff-grid__cell" flex="33">
                            <div class="section__item__prev-value">
                            <div class="bpe-data-value">{{ diffItem.simpleOriginalValueFormatted }}</div>
                            </div>
                        </div>
                        <div class="flex-33 diff-grid__cell" flex="33">
                            <div class="section__item__new-value">
                            <div class="bpe-data-value">{{ diffItem.simpleNewValueFormatted }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div *ngIf="!diffSection.isCollapsible && !diffSection.isEmpty">
          <div class="layout-row flex-100 section__header section__header--non-root">
            <div class="flex-20">
              <span class="section__header__name section__header__name--non-root">
                {{ diffSection.sectionName }}
              </span>
            </div>
            <div class="flex-33">
              <a class="grid-actions-btn bp-icon-view" (click)="showMoreDetails(diffSection)">
                <span class="eye-icon" [bpMatIcon]="eyeIcon"></span>
                <span class="underline">View Activity Detail</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
