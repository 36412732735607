<div class="client-license-management">
  <!-- <bp-tabs-bar [tabsList]="_routeTabsService.routeTabsList"></bp-tabs-bar> -->
  <section>
    <section class="header">
      <app-filter-management-grid
        [statusList]="statusTypeList"
        [customerTypeList]="customerTypeList"
        [advancedIsOpen]="advancedFilterIsOpen"
        (openAdvancedFiler)="onOpenAdvancedFilter($event)"
        (changeValueFilter)="onChangeValueFilter($event)"></app-filter-management-grid>
      <div class="header__actions">
        <span (click)="showActivity()" class="activity-icon" [bpMatIcon]="activityIcon"></span>
        <button type="button" class="primary md bp-icon-button" (click)="onGoCreateClient()">
          <span class="btn-icon" [bpMatIcon]="addIcon"></span>
          <span class="btn-text">Add</span>
        </button>
      </div>
    </section>
    <app-advanced-filter-management *ngIf="advancedFilterIsOpen"
      [statusList]="statusTypeList"
      [customerTypeList]="customerTypeList"
      (cancel)="onApplyAdvancedCancel($event)"
      (reset)="onApplyAdvancedClear($event)"
      (clear)="onApplyAdvancedClear($event)"
      (applyFilters)="onApplyAdvancedFilter($event)">
    </app-advanced-filter-management>
    <bpe-grid
      [showViewMore]="showViewMore"
      [columnDefs]="columnDefs"
      [data]="ngClients"
      [optionsTmpl]="myTemplate"
      [pagination]="pagination"
      (onPageChange)="onPageChange($event)"
      (onSort)="onSort($event)"
      (onViewDetails)="onGoDetails($event)">
    </bpe-grid>
  </section>
</div>

<ng-template #myTemplate let-item="item">
  <button mat-menu-item class="item-btn" (click)="onChangeStatus(item)">
    <span>Modify status</span>
  </button>
  <button mat-menu-item class="item-btn" (click)="onDisable(item)">
    <span>Disable</span>
  </button>
</ng-template>