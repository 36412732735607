<div
  class="pp-ng-select"
  [ngClass]="{ 'ppns-has-value': disPlayValue, 'clearable': ((clearable && !disabled && !control.disabled) ? true : false) }"
>
  <ng-select
    #select
    [items]="options"
    [multiple]="multiple"
    [bindValue]="returnKey ? (returnKey === 'NULL' ? 'name' : returnKey) : 'name'"
    [bindLabel]="displayKey ? displayKey : 'name'"
    [closeOnSelect]="closeOnSelect"
    [searchable]="searchable"
    [searchFn]="disableInternalFiltering ? searchFunction : undefined"
    (search)="onSearch($event)"
    [placeholder]="placeholder || 'Select'"
    [formControl]="control"
    (change)="onChange($event)"
    (focus)="onFocus.emit()"
    [required]="required"
    [clearable]="((clearable == true && disabled == false) ? true : false) || false"
    [ngClass]="{
              'disabled': disabled || control.disabled,
              'required': showRequiredOnSelectedOption,
              'border_red': control?.hasError('required') && control.touched,
              'lai-has-value': disPlayValue
            }"
    [appendTo]="appendTo"
    (close)="onClose.emit($event)"
  >
    <ng-template *ngIf="multiple" ng-header-tmp>
      <div style="padding-left: 4px;">
        <div style="padding: 0px 6px 0px 0px; width: 100%;" class="bp-form-field bp-search-input filter-input" kendoTooltip position="bottom" [callout]="false" title="Search">
          <input type="text" placeholder="Search" (input)="onSearchMultiple(select, $event)" matInput>
          <span [bpIcon]="searchIcon"></span>
        </div>
        <div style="padding-top: 5px;">
          <mat-checkbox id="select-unselect" [checked]="options.length === control.value?.length" (change)="selectUnselectAll($event.checked)">
            <span>Select All</span>
          </mat-checkbox>
        </div>
      </div>
    </ng-template>

    <ng-template *ngIf="multiple" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <mat-checkbox id="item-{{index}}" [ngModel]="item$.selected" (click)="false" title="{{getTooltipValue(item)}}">
                <span>{{getDisplayValue(item)}}</span>
              </mat-checkbox>
    </ng-template>

    <ng-template *ngIf="multiple" ng-label-tmp let-item="item">
      <span></span>
    </ng-template>
    <ng-template
      *ngIf="hasCurrencyList && !multiple"
      ng-option-tmp
      ng-label-tmp
      let-i="index"
      let-item="item"
    >
      {{parseInt(item.name)|currency}}
    </ng-template>

    <ng-template
      *ngIf="!hasCurrencyList && !multiple"
      ng-option-tmp
      ng-label-tmp
      let-i="index"
      let-item="item"
    >
      <div class="ellipsis-wrap" title="{{getTooltipValue(item)}}">
        {{getDisplayValue(item)}}
      </div>
    </ng-template>

    <ng-template
      *ngIf="isDuration && !multiple"
      ng-option-tmp
      ng-label-tmp
      let-i="index"
      let-item="item"
    >
      {{item}}
    </ng-template>
  </ng-select>

  <div (click)="select.open()" *ngIf="multiple && options" class="selected-mutiple">
    <span kendoTooltip position="bottom" [callout]="false" [title]="getSelectedItems()" tooltipClass="selected-title-tooltip" >{{ getSelectedItems() }}</span>
  </div>
  <div (click)="select.open()" *ngIf="multiple && options" class="selected-mutiple-counter">
    <span>{{ control.value?.length || 0 }}</span>
  </div>

  <span class="ngs-clear-btn" (click)="clearData()">X</span>

  <span
    class="hint"
    style="font-size: 10px; color: #C81E29; position: relative; top: 5px"
    *ngIf="control?.hasError('required') && control.touched"
  >
    <span *ngIf="!name">Please fill this fields</span>
    <span *ngIf="name">{{name}} is required.</span>
  </span>
  <span
    *ngIf="enableClearIcon && control.valid"
    class="lai-clear-btn"
    (click)="clearData()"
    >X Clear</span
  >
</div>
