import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { BpDropDownService } from '../../services/bp-drop-down.service';
import { ISelectOptionsConfig } from '../bp-drop-down/bp-drop-down.component';

@Component({
    selector: 'bp-drop-down-options',
    templateUrl: './bp-drop-down-options.component.html',
    styleUrls: ['./bp-drop-down-options.component.scss']
})

export class BpDropDownOptionsComponent {
    @Input() options: Array<ISelectOptionsConfig> = [];
    @Input() selectedValues: Array<number> = [];
    @Input() columnsPerRow = 1;

    selectAllOptions = false;

    constructor(
        private _cdr: ChangeDetectorRef,
        private _bpDropDownService: BpDropDownService
    ) { }

    onOptionLabelClick(optionId: number, event: any): void {
        if (event) {
            event.stopImmediatePropagation();
        }
        const selectedOption = this.options.find(option => {
            return option.id === optionId;
        });
        if (selectedOption) {
            this.onOpitonCheck(optionId, {checked: !selectedOption.isSelected});
        }
    }
    onOpitonCheck(optionId: number, checkedValue: any, event?: any): void {
        const isChecked = checkedValue.checked;
        if (event) {
            event.stopImmediatePropagation();
        }
        if (!isChecked) {
            const removeModelIndex = this.selectedValues.findIndex(model => {
                return model === optionId;
            });
            const updateModelOption = this.options.find(option => {
                return option.id === optionId;
            });

            if (removeModelIndex !== -1) {
                this.selectedValues.splice(removeModelIndex, 1);
            }
            if (updateModelOption) {
                updateModelOption.isSelected = isChecked;
            }
            this.selectAllOptions = isChecked;
        } else {
            const specificOption = this.options.find(option => {
                return option.id === optionId;
            });
            if (specificOption) {
                specificOption.isSelected = isChecked;
                this.selectedValues.push(specificOption.id);
            }
        }
        this.emitValue();
    }

    swapOptionsState(): void {
        this.options.forEach(option => {
            option.isSelected = this.selectAllOptions;
            const selectedIndex = this.selectedValues.findIndex(selectedVal => {
                return selectedVal === option.id;
            });
            if (this.selectAllOptions) {
                if (selectedIndex === -1) {
                    this.selectedValues.push(option.id);
                }
            } else {
                if (selectedIndex !== -1) {
                    this.selectedValues.splice(selectedIndex);
                }
            }
        });
        this._cdr.detectChanges();
        this.emitValue();
    }

    configSelectValuesToOptions(): void {
        const selectedValuesLength = this.selectedValues.length;
        for (let i = 0; i < selectedValuesLength; i++) {
            const specificOption = this.options.find(option => {
                return option.id === this.selectedValues[i];
            });
            if (specificOption) {
                specificOption.isSelected = true;
            }
        }
    }

    emitValue(): void {
        this._bpDropDownService.setSelectedOptions({
            options: this.options,
            selectedValues: this.selectedValues
        });
    }

    updateValues(): void {
        this._cdr.detectChanges();
        this.configSelectValuesToOptions();
    }

    calculateItemWidth(): string {
        return (100 / this.columnsPerRow) + '%';
    }
}
