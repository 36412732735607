<div class="activity-history-dynamic-grid">
    <div class="header">
        <div class="title">
            <span [innerText]="'Activity Detail'"></span>
        </div>
        <div>
            <span [bpIcon]="closeIcon" (click)="onCloseModal()"></span>
        </div>
    </div>

    <mat-dialog-content>
        <div class="main-content">
            <div class="model-details">
                <div class="detail">
                  <label [innerText]="'Activity Id'"></label>
                  <label class="value">{{selectedActivity.id}}</label>
                </div>
                <div *ngFor="let header of extraDetailHeaders" class="detail">
                  <label>{{header.label}}</label>
                  <label class="value">{{selectedActivity[header.key]}}</label>
                </div>
                <div class="detail">
                  <label [innerText]="'Section'"></label>
                  <label class="value">{{selectedActivity.section}}</label>
                </div>
                <div class="detail">
                  <label [innerText]="'Created BY'"></label>
                  <label class="value">{{selectedActivity.createdBy}}</label>
                </div>
                <div class="detail">
                  <label [innerText]="'Created Date/Time'"></label>
                  <label class="value">{{formatDate(selectedActivity.createdDateTime)}}</label>
                </div>
            </div>
            <div class="activity-history-dynamic-grid">
                <h4 class="activity-label">{{ differenceSection.sectionName }}</h4>
                <div class="mw-grid activity-dynamic-table-detail">
                    <table class="activity-dynamic-table"
                            style="width: 100%;">
                        <thead class="activity-dynamic-table-head">
                            <tr>
                                <th colspan="2"
                                    *ngFor="let col of columnNames">
                                    {{ col }}
                                </th>
                            </tr>

                            <tr>
                                <th class="sub-header"
                                    *ngFor="let subCol of subColumnNames">
                                    {{ subCol.name }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="activity-dynamic-table-row"
                                *ngFor="let row of rowValues">
                                <td *ngFor="let cell of row.cellList">
                                    <div class="section__item">
                                        <div [ngClass]="{'section__item__prev-value': cell.type === 'prev', 'section__item__new-value': cell.type === 'new'}">
                                            <div class="value">
                                                {{ cell.value }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="btn-container buttons-right">
            <button type="button" class="secondary md" (click)="onCloseModal()">Close</button>
        </div>
    </mat-dialog-content>
</div>
