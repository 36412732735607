<div class="license-client-header">
  <div class="client-details">
    <div class="detail">
      <label [innerText]="'Customer ID'"></label>
      <label class="value" [innerText]="_clientModel?.ngCarrierClientId"></label>
    </div>
    <div class="detail">
      <label [innerText]="'Created Date/Time'"></label>
      <label class="value" [innerText]="_clientModel?.createDate | bpeDate: true"></label>
    </div>
    <div class="detail">
      <label [innerText]="'Last Modified Date/Time'"></label>
      <label class="value" [innerText]="_clientModel?.modifyDate | bpeDate: true"></label>
    </div>
    <div class="detail">
      <label [innerText]="'Last Modified By'"></label>
      <label class="value" [innerText]="_clientModel?.modifiedBy"></label>
    </div>
    <div class="detail" [ngSwitch]="_clientModel?.statusTypeId">
      <label [innerText]="'Status'"></label>
      <label *ngSwitchCase="_statusList?.ACTIVE.id" class="value active" [innerText]="_statusList?.ACTIVE.label"></label>
      <label *ngSwitchCase="_statusList?.INACTIVE.id" class="value inactive" [innerText]="_statusList?.INACTIVE.label"></label>
      <label *ngSwitchCase="_statusList?.DISABLED.id" class="value disabled" [innerText]="_statusList?.DISABLED.label"></label>
      <label *ngSwitchCase="_statusList?.PENDING.id" class="value pending" [innerText]="_statusList?.PENDING.label"></label>

      <label *ngSwitchCase="_statusList?.PENDING_ACTIVATION.id" class="value" [innerText]="_statusList?.PENDING_ACTIVATION.label"></label>
    </div>
  </div>
  <div class="button-content" *ngIf="_statusList && _clientModel && _clientModel?.statusTypeId !== _statusList?.DISABLED.id">
    <button
      type="button"
      class="primary md align-right"
      (click)="onChangeStatus()">
      <span *ngIf="_clientModel?.statusTypeId === _statusList?.ACTIVE.id" class="btn-text">Inactivate</span>
      <span *ngIf="_clientModel?.statusTypeId !== _statusList?.ACTIVE.id" class="btn-text">Activate</span>
    </button>
  </div>
</div>
