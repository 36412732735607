import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IconRefEnum } from 'library';
import { INGStatusTypeInstanceDto } from '../../../interfaces/license-interfaces';

@Component({
  selector: 'app-update-status-modal',
  templateUrl: './update-status-modal.component.html',
  styleUrls: ['./update-status-modal.component.scss'],
})
export class UpdateStatusModalComponent implements OnInit, OnDestroy {
  @Input() statusTypeList!: Array<INGStatusTypeInstanceDto>;

  formStatus!: FormGroup;
  closeIcon = IconRefEnum.BpClose;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<UpdateStatusModalComponent>
  ) {
    this.buildForm();
  }

  ngOnInit() { }

  buildForm(): void {
    this.formStatus = this.formBuilder.group({
      statusType: [null, [Validators.required]],
    });
  }

  onSave(): void {
    const statusId = this.formStatus.controls.statusType.value;
    //const licenseStatus = this.statusTypeList.find(x=> x.statusTypeId === statusId)
    this.dialogRef.close(statusId);
  }

  onCloseModal(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() { }
}
