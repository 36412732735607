import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IconRefEnum } from 'library';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit, OnDestroy {
  @Input() title!: string;
  @Input() message!: string;
  @Input() messageDetails!: string[];
  @Input() messageIcon!: any;
  @Input() cancelBtn!: string;
  @Input() acceptBtn!: string;

  @Output() cancel = new EventEmitter<any>();
  @Output() accept = new EventEmitter<any>();
  closeIcon = IconRefEnum.BpClose;

  constructor(private dialogRef: MatDialogRef<ConfirmationModalComponent>) {}

  ngOnInit() {}

  onCloseModal() {
    this.dialogRef.close();
  }

  onAcceptModal() {
    const accept = true;
    this.dialogRef.close(accept);
  }

  ngOnDestroy() {}
}
