import {
    Input, AfterViewInit, OnChanges, OnDestroy, ElementRef, Directive, HostListener,
    TemplateRef, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef,
    ComponentRef, ContentChild, Output, EventEmitter, SimpleChanges, OnInit, Component
} from '@angular/core';
import { Subscription } from 'rxjs';
import { BpDropDownService, IBpDropDownEmit } from '../services/bp-drop-down.service';

@Directive({
    selector: '[bpDropDown]'
})

export class BpDropDownDirective implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    @Input() config: ISelectConfig = {
        selectedValues: [],
        customComponent: null,
    };
    @Output() valueChange = new EventEmitter();
    componentRef!: ComponentRef<any>;
    domElem!: HTMLElement;
    trialCount = 1;
    subscriptions!: Subscription;

    constructor(
        private elementRef: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private _bpCustomTempInjectorService: BpDropDownService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateComponentValues();
    }

    ngOnInit(): void {
        this.createComponentConfig();
        this._bpCustomTempInjectorService.setInitialSelectedValues(this.config.selectedValues);
        this.subscriptions = this._bpCustomTempInjectorService.getSelectedOptionsObservable$().subscribe(
            (emitValue: IBpDropDownEmit) => {
                if (emitValue) {
                    this.valueChange.emit(emitValue);
                }
            }
        );
    }

    ngAfterViewInit(): void {
        this.appendComponentToDom();
        this.updateComponentValues();
    }

    updateComponentValues(): void {
        if (this.componentRef) {
            this.componentRef.instance.selectedValues = this.config.selectedValues;
            if (this.config.hasOptions) {
                this.componentRef.instance.options = this.config.options;
            }
            if (this.config.columnsPerRow) {
                this.componentRef.instance.columnsPerRow = this.config.columnsPerRow;
            }
            this.componentRef.instance.updateValues();
        }
    }

    createComponentConfig(): void {
        // 1. Creating a component reference from the component
        this.componentRef = this.componentFactoryResolver
            .resolveComponentFactory(this.config.customComponent)
            .create(this.injector);

        // 2. Attaching component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(this.componentRef.hostView);

        // 3. Get DOM element from component
        this.domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;
    }

    appendComponentToDom(): void {
        if (this.elementRef.nativeElement.offsetParent) {
            // 4. Append DOM element to the body
            document.getElementsByClassName('bp-custom-temp-container')[0].appendChild(this.domElem);
        } else {
            if (this.trialCount > 20) { return; }
            setTimeout(() => {
                this.trialCount++;
                this.appendComponentToDom();
            }, 500);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}

export interface ISelectConfig {
    selectedValues: Array<number>;
    customComponent: any;
    hasOptions?: boolean;
    options?: any;
    optionsWidthInPx?: number;
    columnsPerRow?: number;
}
