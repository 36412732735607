<form [formGroup]="formFilter" class="filter-grid">
  <div class="bp-form-field bp-search-input filter-input" kendoTooltip position="bottom" [callout]="false"[title]="searchPlaceholder">
    <input [type]="type" [placeholder]="searchPlaceholder" formControlName="search" matInput>
    <span [bpIcon]="searchIcon"></span>
  </div>

  <mat-form-field appearance="outline" class="bp-form-field bp-dropdown filter-input" *ngIf="categoryList">
    <mat-select formControlName="category" [placeholder]="categoryPlaceholder" disableOptionCentering
      (selectionChange)="onCategoryChange()" panelClass="bp-filter-selector">
      <mat-option *ngFor="let type of categoryList" [value]="type.key">
        {{type.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="bp-form-field bp-dropdown filter-input"
    *ngIf="filterByModel && formFilter.controls.category.value">
    <mat-select formControlName="filterBy" [placeholder]="filterByPlaceholder" disableOptionCentering
      (selectionChange)="onFilterByChange()" panelClass="bp-filter-selector">
      <mat-option *ngFor="let type of filterByModel.list" [value]="type[filterByModel.key]">
        {{type[filterByModel.label]}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
