import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { IconRefEnum } from 'library';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-grid',
  templateUrl: './filter-grid.component.html',
  styleUrls: ['./filter-grid.component.scss'],
})
export class FilterGridComponent implements OnInit, OnDestroy {
  @Input() categoryList!: any[];
  @Input() filterByModel!: any;
  @Input() type: string = 'text';
  @Input() searchPlaceholder: string = 'Search';
  @Output() changeFilter = new EventEmitter();
  @Output() changeCategory = new EventEmitter();
  @Input() advancedIsOpen: boolean = false;



  _sub!: Subscription;
  formFilter!: FormGroup;
  searchIcon = IconRefEnum.BpSearch;
  categoryPlaceholder: string = 'Select';
  filterByPlaceholder: string = 'Select';
  constructor(private formBuilder: FormBuilder) {
    this.buildForm();
  }

  ngOnInit() {
    this.subscribeToSearchInput();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['advancedIsOpen']) {
      this.resetFormFilter()
    }
  }

  resetFormFilter(): void {
    if(this.advancedIsOpen) {
      this.formFilter.controls.search.disable();
      this.formFilter.controls.search.reset();
      this.formFilter.controls.category.disable();
      this.formFilter.controls.category.reset();
      this.formFilter.controls.filterBy.disable();
      this.formFilter.controls.filterBy.reset();
    } else {
      this.formFilter.controls.search.enable();
      this.formFilter.controls.category.enable();
      this.formFilter.controls.filterBy.enable();
    }
  }

  buildForm(): void {
    this.formFilter = this.formBuilder.group({
      search: [null],
      category: [null],
      filterBy: [null],
    });
  }

  subscribeToSearchInput() {
    const inputcontrol = this.formFilter.controls.search;
    this._sub = inputcontrol.valueChanges
      .pipe(debounceTime(500))
      .subscribe((dataValue) => {
        this.onFilterByChange();
      });
  }

  onCategoryChange() {
    this.formFilter.controls.filterBy.reset();
    const category: string = this.formFilter.controls.category.value;
    this.changeCategory.emit(category);
  }

  onFilterByChange() {
    const formValue: string = this.formFilter.value;
    this.changeFilter.emit(formValue);
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
