import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiSettings } from '../apiSettings';
import { MsalService } from '@azure/msal-angular';

import {
  IBondProClientInstanceInfoRequestDto,
  IBondProClientInfoRequestDto,
  IBondProClientLicenseRequestDto,
  IBondProNGCarrierClientDto,
  IPaginationResult,
  IServiceResponseDto,
  INGCarrierClientInstanceDto,
  INGCarrierLicensesInstanceDto,
  IBondProLicenseSubscriptionRequestDto,
  INGSubscriptionInstanceDto,
  INGSubscriptionTypeInstanceDto,
  INGStatusTypeInstanceDto,
  IBondProNGClientStatusDto,
  INGLicenseTypeInstanceDto,
  INGLicenseKeyDto,
  INGUpdateLicenseStatusDto,
  ILicenseKeyRequestDto,
  ClientInstanceAuthenticationKey,
  AuthenticationType,
  AuthenticationIN,
  ServiceAuthentication,
  INGBondProCustomerDto,
  ApplicationType,
  INGLicenseCheckDuplicate,
  ICustomerTypeDto,
  SaveBusinessToBusinessRequestDto,
  RemoveBusinessToBusinessRequestDto,
  BondProUpdateClientRelationStatusRequestDto,
  BondProClientBusinessToBusinessRequestDto,
} from '../interfaces/license-interfaces';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  private apiUrl = ApiSettings.api_url + '/ngLicense';

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) { }

  // Clients
  getNGCarrierClients(
    request: IBondProClientInfoRequestDto
  ): Observable<IPaginationResult> {
    return this.http.post<IPaginationResult>(
      this.apiUrl + '/GetNGClients',
      request
    );
  }

  getClientInfo(client: any): Observable<IServiceResponseDto> {
    /*return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/GetClientDetails',
      clientId
    );*/
    return this.http.get<any>(
      this.apiUrl + '/GetClientDetails/' + client.clientId
    );
  }

  createClient(
    clientDto: IBondProNGCarrierClientDto
  ): Observable<IServiceResponseDto> {
    clientDto.modifiedBy = this.userService.currentUserValue;
    clientDto.createdBy = this.userService.currentUserValue;
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/CreateNGClient',
      clientDto
    );
  }

  updateClient(
    clientDto: IBondProNGCarrierClientDto
  ): Observable<IServiceResponseDto> {
    clientDto.modifiedBy = this.userService.currentUserValue;
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/UpdateNGClient',
      clientDto
    );
  }

  updateClientStatus(
    clientDto: IBondProNGClientStatusDto
  ): Observable<IServiceResponseDto> {
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/UpdateNGClientStatus',
      clientDto
    );
  }

  disableClient(
    clientId: any,
    modifiedBy?: string
  ): Observable<IServiceResponseDto> {
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/DisableNGClient',
      { clientId, modifiedBy }
    );
  }

  // Instances
  getNGCarrierInstances(
    request: IBondProClientInstanceInfoRequestDto
  ): Observable<IPaginationResult> {
    return this.http.post<IPaginationResult>(
      this.apiUrl + '/GetNGClientInstances',
      request
    );
  }

  getNGAllCarrierInstances(
    request: IBondProClientInstanceInfoRequestDto
  ): Observable<any[]> {
    return this.http.get<any[]>(
      this.apiUrl +
        '/GetAllNGClientInstances?clientId=' +
        request.NGCarrierClientId
    );
  }

  createNGInstance(
    instanceDto: INGCarrierClientInstanceDto
  ): Observable<IServiceResponseDto> {
    instanceDto.modifiedBy = this.userService.currentUserValue;
    instanceDto.createdBy = this.userService.currentUserValue;
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/CreateNGInstance',
      instanceDto
    );
  }

  updateNGInstance(
    instanceDto: INGCarrierClientInstanceDto
  ): Observable<IServiceResponseDto> {
    instanceDto.modifiedBy = this.userService.currentUserValue;
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/UpdateNGInstance',
      instanceDto
    );
  }

  deleteNGInstance(
    instanceId: any,
    modifiedBy?: string
  ): Observable<IServiceResponseDto> {
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/RemoveNGInstance',
      { instanceId, modifiedBy }
    );
  }

  // Licenses
  getNGCarrierLicenses(
    request: IBondProClientLicenseRequestDto
  ): Observable<IPaginationResult> {
    return this.http.post<IPaginationResult>(
      this.apiUrl + '/GetNGClientLicenses',
      request
    );
  }

  createNGCarrierLicenses(
    licenseDto: INGCarrierLicensesInstanceDto
  ): Observable<IServiceResponseDto> {
    licenseDto.modifiedBy = this.userService.currentUserValue;
    licenseDto.createdBy = this.userService.currentUserValue;
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/CreateBondProLicense',
      licenseDto
    );
  }

  updateNGCarrierLicenses(
    licenseDto: INGCarrierLicensesInstanceDto
  ): Observable<IServiceResponseDto> {
    licenseDto.modifiedBy = this.userService.currentUserValue;
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/UpdateBondProLicense',
      licenseDto
    );
  }

  deleteNGCarrierLicenses(
    licenseId: any,
    clientId?: any,
    licenseType?: string,
    modifiedBy?: string
  ): Observable<IServiceResponseDto> {
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/RemoveBondProLicense',
      {
        licenseId,
        ngCarrierClientId: clientId,
        licenseName: licenseType,
        modifiedBy,
      }
    );
  }

  updateLicenseStatus(
    licenseStatusDto: INGUpdateLicenseStatusDto
  ): Observable<IServiceResponseDto> {
    licenseStatusDto.modifiedBy = this.userService.currentUserValue;
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/UpdateBondProLicenseStatus',
      licenseStatusDto
    );
  }

  // Subscription
  getNGSubscription(
    request: IBondProLicenseSubscriptionRequestDto
  ): Observable<IPaginationResult> {
    return this.http.post<IPaginationResult>(
      this.apiUrl + '/GetNGClientSubscriptions',
      request
    );
  }

  createNGSubscription(
    subscriptionDto: INGSubscriptionInstanceDto
  ): Observable<IServiceResponseDto> {
    subscriptionDto.modifiedBy = this.userService.currentUserValue;
    subscriptionDto.createdBy = this.userService.currentUserValue;
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/CreateBondProSubscription',
      subscriptionDto
    );
  }

  updateNGSubscription(
    subscriptionDto: INGSubscriptionInstanceDto
  ): Observable<IServiceResponseDto> {
    subscriptionDto.modifiedBy = this.userService.currentUserValue;
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/UpdateBondProSubscription',
      subscriptionDto
    );
  }

  deleteNGSubscription(
    subscriptionId: any,
    modifiedBy?: string
  ): Observable<IServiceResponseDto> {
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/RemoveBondProSubscription',
      { subscriptionId, modifiedBy }
    );
  }

  viewLicenseKey(licenseKeyDto: ILicenseKeyRequestDto): Observable<IServiceResponseDto> {
    return this.http.post<IServiceResponseDto>(this.apiUrl + '/DecryptLicense',licenseKeyDto);
  }

  regenerateKeyNGSubscription(
    licenseKeyDto: INGLicenseKeyDto
  ): Observable<IServiceResponseDto> {
    licenseKeyDto.modifiedBy = this.userService.currentUserValue;
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/Regenerate',
      licenseKeyDto
    );
  }

  saveBusinessToBusiness(request: SaveBusinessToBusinessRequestDto): Observable<IServiceResponseDto> {
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/SaveBusinessToBusiness',
      request
    );
  }

  getNGClientBusinessToBusiness(request: BondProClientBusinessToBusinessRequestDto): Observable<IPaginationResult> {
    return this.http.post<IPaginationResult>(
      this.apiUrl + '/GetNGClientBusinessToBusiness',
      request
    );
  }

  removeBusinessToBusiness(request: RemoveBusinessToBusinessRequestDto): Observable<IServiceResponseDto> {
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/RemoveBusinessToBusiness',
      request
    );
  }

  updateBusinessToBusinessStatus(request: BondProUpdateClientRelationStatusRequestDto): Observable<IServiceResponseDto> {
    return this.http.post<IServiceResponseDto>(
      this.apiUrl + '/UpdateBusinessToBusinessStatus',
      request
    );
  }

  getNGLicenseTypes(): Observable<INGLicenseTypeInstanceDto[]> {
    return this.http.get<INGLicenseTypeInstanceDto[]>(
      this.apiUrl + '/GetLicenseTypes'
    );
  }

  getNGSubscriptionTypes(): Observable<INGSubscriptionTypeInstanceDto[]> {
    return this.http.get<INGSubscriptionTypeInstanceDto[]>(
      this.apiUrl + '/GetSubscriptionTypes'
    );
  }

  getNGStatusTypes(): Observable<INGStatusTypeInstanceDto[]> {
    return this.http.get<INGStatusTypeInstanceDto[]>(
      this.apiUrl + '/GetStatusTypes'
    );
  }

  getSubscriptionByLicenseTypeId(licenseTypeId: any): Observable<INGSubscriptionTypeInstanceDto[]> {
    return this.http.get<INGSubscriptionTypeInstanceDto[]>(
      this.apiUrl + '/GetSubscriptionsByLicenseTypeId/' + licenseTypeId
    );
  }

  getClientInstanceAuthenticationKeys(nGCarrierClientInstanceId: number): Observable<ClientInstanceAuthenticationKey[]> {
    return this.http.get<ClientInstanceAuthenticationKey[]>(
      this.apiUrl + '/GetClientInstanceAuthenticationKeys/' + nGCarrierClientInstanceId
    );
  }
  
  getApplicationTypes(): Observable<ApplicationType[]> {
    return this.http.get<ApplicationType[]>(
      this.apiUrl + '/GetApplicationTypes'
    );
  }

  checkDuplicateClientInstanceName(checkDuplicate: INGLicenseCheckDuplicate): Observable<boolean> {
    return this.http.post<boolean>(
      this.apiUrl + '/CheckDuplicateClientInstanceName',
      checkDuplicate
    );
  }

  getAuthenticationTypes(): Observable<AuthenticationType[]> {
    return this.http.get<AuthenticationType[]>(
      this.apiUrl + '/GetAuthenticationType'
    );
  }

  getAuthenticationIN(): Observable<AuthenticationIN[]> {
    return this.http.get<AuthenticationIN[]>(
      this.apiUrl + '/GetAuthenticationIN'
    );
  }

  getServiceAuthenticationList(): Observable<ServiceAuthentication[]> {
    return this.http.get<ServiceAuthentication[]>(
      this.apiUrl + '/GetServiceAuthentication'
    );
  }

  getBondProCustomers(): Observable<INGBondProCustomerDto[]> {
    return this.http.get<INGBondProCustomerDto[]>(
      this.apiUrl + '/GetAvailableBondProMasterCustomers'
    );
  }

  getCustomerType(): Observable<ICustomerTypeDto[]> {
    return this.http.get<ICustomerTypeDto[]>(
      this.apiUrl + '/GetCustomerTypes'
    );
  }

  getBondProCustomerById(ngCarrierClientId: number): Observable<INGBondProCustomerDto[]> {
    return this.http.get<INGBondProCustomerDto[]>(
      this.apiUrl + '/GetBondProCustomerById/' + ngCarrierClientId
    );
  }
}
