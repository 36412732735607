<div *ngIf="formDynamic" class="advanced-filter-management">
  <form [formGroup]="formDynamic" class="form-filter-management">
    <ng-template ngFor let-input [ngForOf]="listFields">
      <div class="bp-form-field">
        <div class="label-conteiner">
          <label [innerText]="input.label"></label>
          <span class="clear" (click)="onClearInput(input.controlName)">Clear</span>
        </div>
        <input *ngIf="input.type !== 'select'" matInput [name]="input.controlName" [type]="input.type" [formControlName]="input.controlName" />

        <mat-select *ngIf="input.type === 'select' && input.controlName === 'clientStatuses'" [formControlName]="input.controlName" [placeholder]="'Select value'" disableOptionCentering
          panelClass="bp-filter-selector">
          <mat-option *ngFor="let type of statusList" [value]="type.statusName">
            {{type.statusName}}
          </mat-option>
        </mat-select>

        <mat-select *ngIf="input.type === 'select' && input.controlName === 'customerTypeCodes'" [formControlName]="input.controlName" [placeholder]="'Select value'" disableOptionCentering
          panelClass="bp-filter-selector">
          <mat-option *ngFor="let type of customerTypeList" [value]="type.customerTypeCode">
            {{type.name}}
          </mat-option>
        </mat-select>
      </div>
    </ng-template>
  </form>
  <div class="btn-container buttons-right">
    <button type="button" class="secondary md" (click)="onCancel()">Cancel</button>
    <button type="button" class="secondary md" (click)="onClear()">Clear All</button>
    <button type="button" class="secondary md" (click)="onReset()">Reset</button>
    <button [disabled]="!formDynamic.valid" type="submit" class="primary md" (click)="onApplyFilters()">Apply
      Filters</button>
  </div>
</div>
