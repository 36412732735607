import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { SelectOptionsPositionEnum } from '../../enums/selectOptionsPositionEnum';
import { BpFieldControl } from '../../directives/fieldcontrol/bpFieldControl.class';
import { ISelectConfig } from '../../directives/bp-drop-down.directive';
import { IBpDropDownEmit } from '../../services/bp-drop-down.service';
import { BpDropDownOptionsComponent } from '../bp-drop-down-options/bp-drop-down-options.component';

@Component({
    selector: 'bp-drop-down',
    templateUrl: './bp-drop-down.component.html'
})

export class BpDropDownComponent implements OnInit, OnChanges {

    //#region Multi Select Properties
    @Input() config: IMultiSelectConfig = {
        selectedValues: [],
        customComponent: null,
        hasOptions: false,
        options: [],
        optionsPosition: SelectOptionsPositionEnum.Left,
        fieldControl: null
    };
    @Output() valueChange = new EventEmitter();
    viewOptions: Array<ISelectOptionsConfig> = [];
    showOptions = false;
    selectOptionsPosition = SelectOptionsPositionEnum;
    customTempConfig!: ISelectConfig;
    optionsWidth = '98%';
    //#endregion Multi Select Properties

    constructor(private _cdRef: ChangeDetectorRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.configInitialOptions();
        this.setCustomTempConfig();
    }

    ngOnInit(): void {
        this.customTempConfig = {
            selectedValues: this.config.selectedValues,
            customComponent: this.config.customComponent,
            hasOptions: this.config.hasOptions,
            options: this.config.options,
            columnsPerRow: this.config.columnsPerRow,
            optionsWidthInPx: this.config.optionsWidthInPx
        };
        this.configInitialOptions();
        this.optionsWidth = this.config.optionsWidthInPx
            ? this.config.optionsWidthInPx + 'px' : '98%';
    }

    //#region Multi Select Methods

    configInitialOptions(): void {
        this.viewOptions = [];
        this.config.options?.map(option => {
            const modalIndex = this.config.selectedValues.findIndex(model => {
                return model === option.id;
            });
            option.isSelected = modalIndex !== -1;
        });
        this.config.options?.forEach(option => {
            if (option.isSelected) {
                this.viewOptions.push(option);
            }
        });
    }

    onTempSelectionChange(tempValue: IBpDropDownEmit) {
        this.viewOptions = JSON.parse(JSON.stringify(tempValue.options));
        this.config.options = tempValue.options;
        this.config.selectedValues = tempValue.selectedValues;
        this.emitValue();
    }

    emitValue(): void {
        this.valueChange.emit({
            selectedValues: this.config.selectedValues,
            options: this.config.options
        });
        if (this.config.fieldControl) {
            this.config.fieldControl.validateField();
        }
    }

    onViewOptionsFocus(event: any): void {
        event.stopImmediatePropagation();
        this.swapOptionsView();
    }

    onOpitonCheck(optionId: number, isChecked: boolean, event?: any): void {
        if (event) {
            event.stopImmediatePropagation();
        }
        if (!isChecked) {
            const removeViewIndex = this.viewOptions.findIndex(option => {
                return option.id === optionId;
            });
            const removeModelIndex = this.config.selectedValues.findIndex(model => {
                return model === optionId;
            });
            const updateModelOption = this.config.options?.find(option => {
                return option.id === optionId;
            });
            if (removeViewIndex !== -1) {
                this.viewOptions.splice(removeViewIndex, 1);
            }
            if (removeModelIndex !== -1) {
                this.config.selectedValues.splice(removeModelIndex, 1);
            }
            if (updateModelOption) {
                updateModelOption.isSelected = isChecked;
            }
        } else {
            const specificOption = this.config.options?.find(option => {
                return option.id === optionId;
            });
            if (specificOption) {
                specificOption.isSelected = isChecked;
                this.viewOptions.push(specificOption);
                this.config.selectedValues.push(specificOption.id);
            }
        }
        // this.customTempConfig.selectedValues = this.config.selectedValues;
        this.setCustomTempConfig();
        this.emitValue();
    }

    setCustomTempConfig(): void {
        this.customTempConfig = {
            selectedValues: this.config.selectedValues,
            customComponent: this.config.customComponent,
            hasOptions: this.config.hasOptions,
            options: this.config.options,
            columnsPerRow: this.config.columnsPerRow,
            optionsWidthInPx: this.config.optionsWidthInPx
        };
        this._cdRef.detectChanges();
    }

    swapOptionsView(): void {
        this.showOptions = !this.showOptions;
    }

    //#endregion Multi Select Methods
}

export interface ISelectOptionsConfig {
    id: number;
    name: string;
    displayName?: string;
    isSelected?: boolean;
    [key: string]: any;
}

export interface IMultiSelectConfig {
    selectedValues: Array<number>;
    customComponent: any;
    hasOptions: boolean;
    options?: Array<ISelectOptionsConfig>;
    optionsPosition?: SelectOptionsPositionEnum;
    optionsWidthInPx?: number;
    columnsPerRow?: number;
    fieldControl?: BpFieldControl|any;
    keepElementParentClasses?: Array<string>;
}
