import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IconRefEnum } from 'library';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss'],
})
export class InformationModalComponent implements OnInit, OnDestroy {
  @Input() title!: string;
  @Input() message!: string;
  @Input() messageDetails!: string[];
  @Input() messageIcon!: any;
  @Input() cancelBtn!: string;

  @Output() cancel = new EventEmitter<any>();
  closeIcon = IconRefEnum.BpClose;

  constructor(private dialogRef: MatDialogRef<InformationModalComponent>) {}

  ngOnInit() {}

  onCloseModal() {
    this.dialogRef.close();
  }

  ngOnDestroy() {}
}
