import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../environments/environment';
import * as CryptoJS from 'crypto-js'

export interface ConfigurationData {
  // public static  api_url = "";
  azureAD_ClientId: string;
  azureAD_TenantId: string;
  azureAD_RedirectUri: string;
  azureAD_RedirectUriMain: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiSettings {

constructor(private http: HttpClient)
{}

  public static  api_url = "";
  public static  azureAD_ClientId = "";
  public static  azureAD_TenantId = "";
  public static  azureAD_RedirectUri = "";
  public static  azureAD_RedirectUriMain = "";


  load() {
    const configNameFile = environment.production ? 'production' : 'development';
    return fetch('./assets/config/' + configNameFile + '.json')
      .then(
        (data) => {
          return data.json()
        }
      )
      .then(
        (config) => {
          ApiSettings.setApiUrl(config.Application.apiUrl);
          return fetch(ApiSettings.api_url + '/Configuration/ConfigurationData')
        }
      )
      .then(
        (data) => {
          return data.json()
        }
      )
      .then(
        (configurationEncrypted) => {
          const ciphertextB64 = configurationEncrypted.settings;
          const key = environment.encryptKey;
          const decrypted: any = ApiSettings.decryptData(key, ciphertextB64);
          return JSON.parse(decrypted);
        }
      )
      .then(
        (configurationData: ConfigurationData) => {
          ApiSettings.initConfiguration(configurationData);
          return Promise.resolve(configurationData)
        }
      )
  }

  static setApiUrl(apiUrl: string) {
    ApiSettings.api_url = apiUrl;
  }

  static initConfiguration(configurationData: ConfigurationData) {
    ApiSettings.azureAD_ClientId =configurationData.azureAD_ClientId;
    ApiSettings.azureAD_TenantId =configurationData.azureAD_TenantId;
    ApiSettings.azureAD_RedirectUri =configurationData.azureAD_RedirectUri;
    ApiSettings.azureAD_RedirectUriMain =configurationData.azureAD_RedirectUriMain;
  }

  static decryptData(key: any, ciphertextB64: any) {                              // Base64 encoded ciphertext, 32 bytes string as key
    var key: any = CryptoJS.enc.Utf8.parse(key);                             // Convert into WordArray (using Utf8)
    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);   // Use zero vector as IV
    var decrypted = CryptoJS.AES.decrypt(ciphertextB64, key, {iv: iv}); // By default: CBC, PKCS7
    return decrypted.toString(CryptoJS.enc.Utf8);                       // Convert into string (using Utf8)
  }
}
