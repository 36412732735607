<div class="bpe-grid">
  <div class="bg-grid-card">
    <kendo-grid #grid [data]="data" [pageSize]="pageSize" [skip]="skip" [pageable]="false" [sortable]="true"
      [sort]="sortDescriptor" (sortChange)="handleSortChange($event)" [selectable]="isSelectable" [resizable]="true"
      (selectionChange)="selectionChange($event)" [kendoGridSelectBy]="selectionKey"
      [(selectedKeys)]="selectedIds" (selectedKeysChange)="selectedKeysChange()">
      <kendo-grid-checkbox-column *ngIf="isMultipleSelection" [width]="40"></kendo-grid-checkbox-column>
      <kendo-grid-column title="" *ngIf="showViewMore" [width]="35">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
          <button class="k-button" (click)="viewDetails(dataItem)" [disabled]="disabled">
            <span [bpIcon]="bpView"></span>
          </button>
        </ng-template>
      </kendo-grid-column>


      <kendo-grid-column title="" [width]="35">
        <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
          <button kendoPopoverAnchor [popover]="myPopover" showOn="click" kendoButton themeColor="primary"
            mat-icon-button>
            <span [bpIcon]="bpMoreVertical"></span>
          </button>
          <kendo-popover #myPopover position="bottom" [callout]="false">
            <ng-template kendoPopoverBodyTemplate>
              <div class="item-selection">
                <button kendoPopoverAnchor [popover]="myPopover2" showOn="hover" kendoButton themeColor="primary">
                  <span [bpIcon]="BpColumns"></span>
                  <span>Columns</span>
                </button>
              </div>



            </ng-template>
          </kendo-popover>

          <kendo-popover #myPopover2 position="bottom" [callout]="false" [animation]="tooltipAnimation"
            popupClass="inner-wrapper">
            <ng-template kendoPopoverBodyTemplate>
              <div class="columns-list-content">
                <div *ngFor="let itemCol of columnDefs; let i = index" class="item-content">
                  <input type="checkbox" [id]="'terms' + i" kendoCheckBox [(ngModel)]=" itemCol.isVisible" />
                  <label class="k-checkbox-label" [for]="'terms' + i" style="text-transform: capitalize;">{{itemCol.title}}</label>
                </div>
              </div>

            </ng-template>
          </kendo-popover>
        </ng-template>

        <ng-template [ngIf]="optionsTmpl" kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
          <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="disabled || dataItem.disableRow">
            <span [bpIcon]="bpMoreVertical"></span>
          </button>
          <mat-menu #menu="matMenu">
            <ng-template [ngTemplateOutlet]="optionsTmpl" [ngTemplateOutletContext]="{item: dataItem}"></ng-template>
          </mat-menu>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [field]="col.field" [title]="col.title" [attr.width]="col.width || null"
        *ngFor="let col of columnDefs" [hidden]="!col.isVisible">

        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf="!col.bodyCellTemplate">
          <div kendoTooltip position="bottom" [callout]="tooltipoOpt.callout">
            <span [title]="dataItem[col.field]">
              {{ dataItem[col.field] }}
            </span>
          </div>
        </ng-template>

        <ng-template *ngIf="col.bodyCellTemplate" kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div kendoTooltip position="bottom" [callout]="tooltipoOpt.callout">
            <div [title]="dataItem[col.field]"
              [innerHtml]="col.bodyCellTemplate(col, dataItem) | safeHtml"></div>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <kendo-datapager *ngIf="pagination && pagination.totalRecords && pagination.page && pagination.pageSize"
      [total]="pagination.totalRecords" [pageSize]="pagination.pageSize"
      [skip]="(pagination.pageSize * pagination.page) - pagination.pageSize" [type]="'input'"
      (pageChange)="pageChange($event)">
      <ng-template kendoDataPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
        <kendo-datapager-input></kendo-datapager-input>
        <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
        <kendo-datapager-page-sizes [pageSizes]="pageSizesList"></kendo-datapager-page-sizes>
        <kendo-datapager-info></kendo-datapager-info>
      </ng-template>

      <kendo-datapager-messages page="Enter page" of="of" items="items" itemsPerPage="items per page">
      </kendo-datapager-messages>
    </kendo-datapager>
  </div>
</div>
