import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IBpeGridColumn, IconRefEnum, DateTimeService } from 'library';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/internal/Subscription';
import { IBondProNGCarrierClientDto, INGCarrierClientInstanceDto, INGStatusTypeInstanceDto, INGUpdateLicenseStatusDto, IViewClientLicenseInfo } from '../../interfaces/license-interfaces';
import { LicenseService } from '../../services/license.service';
import { AddLicenseModalComponent } from '../dialog/add-license-modal/add-license-modal.component';
import { ConfirmationModalComponent } from '../dialog/confirmation-modal/confirmation-modal.component';
import { InformationModalComponent } from '../dialog/information-modal/information-modal.component';
import { UpdateStatusModalComponent } from '../dialog/update-status-modal/update-status-modal.component';
import { kebabCase } from 'lodash';

@Component({
  selector: 'app-license-details',
  templateUrl: './license-details.component.html',
  styleUrls: ['./license-details.component.scss'],
})
export class LicenseDetailsComponent implements OnInit, OnDestroy {
  @Output() reloadLicenseList = new EventEmitter();
  @Output() pageChange = new EventEmitter();
  @Output() sortChange = new EventEmitter();
  @Input() disabled!: boolean;
  @Input() licenseLicense!: IViewClientLicenseInfo[];
  @Input() statusList!: INGStatusTypeInstanceDto[];
  @Input() clientInstances!: INGCarrierClientInstanceDto[];
  @Input() clientId!: number;
  @Input() pagination!: any;
  @Input() clientModel!: IBondProNGCarrierClientDto;

  private _subs = [] as Subscription[];
  // public clientInstances!: INGCarrierClientInstanceDto[];
  public columnDefs: IBpeGridColumn[] = [];
  licenseSelected!: IViewClientLicenseInfo;
  enabledOptions: boolean = true;
  searchFilter: any;
  constructor(
    private licenseService: LicenseService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private dateTimeService: DateTimeService
  ) {}

  ngOnInit() {
    this.defColumns();
  }

  defColumns() {
    this.columnDefs = [
      { title: 'LicenseId', field: 'licenseId' },
      { title: 'License Type', field: 'licenseType' },
      { title: 'Tenant Id', field: 'dbCorporationId' },
      {
        title: 'Effective Date', field: 'effectiveDate', bodyCellTemplate: (col, row) => {

          return this.dateTimeService.toLocalTime(row.effectiveDate, false)
        }
      },
      {
        title: 'Expiration Date', field: 'expirationDate', bodyCellTemplate: (col, row) => {

          return row.expirationDate ? this.dateTimeService.toLocalTime(row.expirationDate, false) : 'Never Expires'
        }
      },
      {
        title: 'LAST MODIFIED DATETIME', field: 'modifyDate', bodyCellTemplate: (col, row) => {

          return this.dateTimeService.toLocalTime(row.modifyDate, true)
        }
      },
      { title: 'LAST MODIFIED BY', field: 'modifiedBy' },
      {
        title: 'Status', field: 'licenseStatusTypeName', bodyCellTemplate: (col, row) => {

          return `<span class="bpe-status bpe-status-${this.kebabCase(row.licenseStatusTypeName)}">
                    <span>${row.licenseStatusTypeName}</span>
                  </span>`
        }
      },
    ];
  }

  kebabCase(x: string) {
    return kebabCase(x);
  }

  onSelectionChange(data: any) {
    this.licenseSelected = data.selectedRows.length > 0 ? data.selectedRows[0].dataItem : undefined;
  }

  getAvailableStatus(status: any) {
    return this.statusList.filter(
        (item) => {
         return status.includes(item.statusTypeCode)
       }

/*         return (
          item.statusTypeCode === 'ACTIVE' ||
          item.statusTypeCode === 'INACTIVE'
        ); */
    );
  }

  onFilterApply(searchTerm?: any) {
    this.searchFilter = searchTerm;
    this.reloadLicenseList.emit(this.searchFilter);
  }


  updateLicenseStatus(statusTypeId: number, rowData: IViewClientLicenseInfo) {
    const clientModel: INGUpdateLicenseStatusDto = {
      licenseId: rowData.licenseId,
      statusTypeId: statusTypeId,
      ngCarrierClientInstanceId: rowData.ngCarrierClientInstanceId,
      tenantId: rowData.dbCorporationId,
      statusTypeName: this.getStatusTypeName(statusTypeId),
      licenseName: rowData.licenseType,
      ngCarrierClientId: this.clientModel.ngCarrierClientId,
      licenseTypeId: rowData.licenseTypeId,
      licenseTypeCode: rowData.licenseTypeCode
    };
    this._subs.push(
      this.licenseService.updateLicenseStatus(clientModel).subscribe((n) => {
        if (n.success) {
          this.toastr.success(
            'The status has been updated successfully',
            'Success'
          );
          this.reloadLicenseList.emit(this.searchFilter);
        } else {
          const title= 'Update Status License';
          const message= 'You cannot update this License';
          this.showErrorModal(title, message, n.errorMessages);
        }
      })
    );
  }

  getStatusTypeName(statusTypeId: number | undefined) {
    const foundItem = (this.statusList || []).find((item) => item.statusTypeId === statusTypeId);
    return foundItem ? foundItem.statusName : void 0;
  }

  removeLicense(licenseId: number, licenseType:string, modifiedBy?: string) {
    this._subs.push(
      this.licenseService.deleteNGCarrierLicenses(licenseId, this.clientModel.ngCarrierClientId, licenseType, modifiedBy).subscribe((n) => {
        if (n.success) {
          this.toastr.success('Has been deleted successfully', 'Success');
          this.licenseSelected.licenseId = undefined;
          this.reloadLicenseList.emit(this.searchFilter);
        } else {
          const title= 'Remove License';
          const message= 'You cannot remove this License?';
          this.showErrorModal(title, message, n.errorMessages);
        }
      })
    );
  }

  onOpenEditModal(value: IViewClientLicenseInfo) {
    const dialogRef = this.dialog.open(AddLicenseModalComponent, {
      width: '900px',
      disableClose: true
    });

    dialogRef.componentInstance.licenseModal = value;
    dialogRef.componentInstance.clientId = this.clientId;
    dialogRef.componentInstance.statusList = this.statusList;
    dialogRef.componentInstance.clientModel = this.clientModel;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadLicenseList.emit(this.searchFilter);
      }
    });
  }

  onOpenRemoveModal(value: IViewClientLicenseInfo) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent);
    dialogRef.componentInstance.title = 'Remove License';
    dialogRef.componentInstance.message =
      'Are you sure you want to remove this License?';
    dialogRef.componentInstance.messageDetails = [
      'you need remove subscriptions',
    ];
    dialogRef.componentInstance.messageIcon = IconRefEnum.BpWarning;
    dialogRef.componentInstance.cancelBtn = 'Cancel';
    dialogRef.componentInstance.acceptBtn = 'Confirm';
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && value.licenseId) {
        this.removeLicense(value.licenseId, value.licenseType, value.modifiedBy);
      }
    });
  }

  onOpenEditStatus(rowData: IViewClientLicenseInfo) {
    if (!this.statusList) return;
    const dialogRef = this.dialog.open(UpdateStatusModalComponent);

    let arrayAvailableStatuses = ['ACTIVE','INACTIVE'];

    if(rowData.licenseStatusTypeCode === 'PENDING_ACTIVATION')
    {
      arrayAvailableStatuses.shift();
    }

    arrayAvailableStatuses = arrayAvailableStatuses.filter(x=> x !== rowData.licenseStatusTypeCode);

    dialogRef.componentInstance.statusTypeList = this.getAvailableStatus(arrayAvailableStatuses);
    dialogRef.afterClosed().subscribe((statusTypeId: any) => {
      if (statusTypeId) {
        this.updateLicenseStatus(statusTypeId, rowData);
      }
    });
  }

  showErrorModal(title: string, message: string, errorMessages: string[]) {
    const dialogRef = this.dialog.open(InformationModalComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.messageDetails = errorMessages;
    dialogRef.componentInstance.messageIcon = IconRefEnum.BpWarning;
    dialogRef.afterClosed().subscribe((result: any) => { });
  }

  onPageChange(event: any) {
    this.pageChange.emit(event)
  }

  onSortChange(event: any) {
    this.sortChange.emit(event)
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }
}
