export const Regex = {
    StrictEmail: /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,4})$/,
    Website: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
    StrictRequire: /[a-zA-Z0-9]/g,
    // Start Phone Regex
    Phone: /^[ +0-9-\()]*$/,
    PhoneUS: /^(\+?1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/g,
    PhoneCA: /^(\+1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/g,
    // End Phone Regex

    // Start Postal code Regex
    PostalCode: /^[ a-zA-z0-9-\()]*$/,
    PostalCodeUS: /^[0-9]{5}(?:-[0-9]{4})?$/,
    PostalCodeCA: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
    // End Postal code Regex
};
