<div class="bp-stateful-textbox">
  <div class="input-group">
    
	<input type="text" [value]="value" [disabled]="isDisabled" (input)="onChange( $event ); onCustomInputChange( $event.target.value )" (blur)="touched()">

	<span class="input-group-addon" *ngIf="state" [ngClass]="{'has-error': state === 'error', 'has-success': state === 'success'}">
		<span *ngIf="state === 'success'" [bpIcon]="bpCheckMark"></span>
		<span *ngIf="state === 'error'" [bpIcon]="errorMark"></span>
	</span>
  </div>

  <div class="bp-field-error"
    *ngIf="parentForm?.get(filedName)?.invalid && (parentForm?.get(filedName)?.dirty || parentForm?.get(filedName)?.touched)">
    <div *ngIf="parentForm?.get(filedName)?.errors?.maxlength">
      <span>Maximum {{maxInputLengt}} characters allowed.</span>
    </div>
	<div *ngIf="parentForm?.get(filedName)?.errors?.required">
		<span>{{label}} is required.</span>
	  </div>
  </div>
</div>
