import { Component, OnInit, Input ,} from '@angular/core';

@Component({
  selector: 'bpe-data-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})

export class SpinnerComponent  {

  constructor() { }

}
