import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  BondProClientBusinessToBusinessRequestDto,
  IBondProClientInstanceInfoRequestDto,
  IBondProClientLicenseRequestDto,
  IBondProNGCarrierClientDto,
  INGCarrierClientInstanceDto,
  INGCarrierClientModel,
  INGCarrierClientRelationshipModel,
  INGClientStatusTypeId,
  INGStatusTypeInstanceDto,
  IViewClientLicenseInfo,
} from '../../interfaces/license-interfaces';
import { LicenseService } from '../../services/license.service';

@Component({
  selector: 'app-client-license',
  templateUrl: './client-license.component.html',
  styleUrls: ['./client-license.component.scss'],
})
export class ClientLicenseComponent implements OnInit {
  public clientModel!: IBondProNGCarrierClientDto;
  public clientInstances: INGCarrierClientInstanceDto[] = [];
  public allclientInstances: INGCarrierClientInstanceDto[] = [];
  public clientLicenses: IViewClientLicenseInfo[] = [];
  public statusList!: INGStatusTypeInstanceDto[];
  public relationships: INGCarrierClientRelationshipModel[] = [];
  private _subs = [] as Subscription[];
  pagination!: IBondProClientInstanceInfoRequestDto;
  paginationLicense!: IBondProClientLicenseRequestDto;
  paginationRelationships!: BondProClientBusinessToBusinessRequestDto;
  isExpanded: boolean = true;
  //inactiveStatusID!: number;
  isClientReadOnly: boolean = false;

  _clientId!: number;
  constructor(
    private route: ActivatedRoute,
    private licenseService: LicenseService
  ) {
    this.pagination = this.setPaginationInstanceValues();
    this.paginationLicense = this.setPaginationInstanceValues();
    this.paginationRelationships = this.setPaginationRelationshipValues();
  }

  ngOnInit() {
    this._subs.push(
      this.route.params.subscribe((params) => {
        this._clientId = params.clientId;
      })
    );

    if (this._clientId > 0) {
      this.loadClientInfo();
      this.getStatusType();
      this.loadClientInstancesList();
      this.loadClientLicenseList();
      this.loadRelationshipList();
    }
  }

  clientReadOnly()
  {
    if(this.clientModel.statusTypeId === INGClientStatusTypeId.INACTIVE || this.clientModel.statusTypeId === INGClientStatusTypeId.DISABLED )
    {
      this.isClientReadOnly = true;
    }
    else
    {
      this.isClientReadOnly = false;
    }
  }


  loadClientInfo() {
    const requestDto = { clientId: this._clientId };
    this._subs.push(
      this.licenseService.getClientInfo(requestDto).subscribe((n) => {
        if (n?.items && n?.items.length > 0) {
          this.clientModel = n.items[0] as IBondProNGCarrierClientDto;
          this.clientReadOnly();
        }
      })
    );
  }

  getStatusType(): void {
    this._subs.push(
      this.licenseService.getNGStatusTypes().subscribe((n) => {
        this.statusList = n;
      })
    );
  }

  onPageChangeTenant(event: any) {
    this.pagination.page = event.page
    this.pagination.pageSize = event.pageSize
    this.loadClientInstancesList()
  }

  onSortChangeTenant(event: any) {
    this.pagination.direction = event.direction
    this.pagination.sortColumn = event.sortColumn
    this.loadClientInstancesList()
  }

  loadClientInstancesList() {
    const requestDto = {
      NGCarrierClientId: this._clientId,
      ...this.pagination
    } as IBondProClientInstanceInfoRequestDto;
    this._subs.push(
      this.licenseService.getNGCarrierInstances(requestDto).subscribe((n) => {
        this.clientInstances = n.items;
        this.pagination.totalRecords = n.totalRecords;
      })
    );
  }

  onPageChangeLicense(event: any) {
    this.paginationLicense.page = event.page
    this.paginationLicense.pageSize = event.pageSize
    this.loadClientLicenseList()
  }

  onSortChangeLicense(event: any) {
    this.paginationLicense.direction = event.direction
    this.paginationLicense.sortColumn = event.sortColumn
    this.loadClientLicenseList()
  }


  loadClientLicenseList() {
    const requestDto = {
      NGCarrierClientId: this._clientId,
      ...this.paginationLicense
    } as IBondProClientLicenseRequestDto;
    this._subs.push(
      this.licenseService.getNGCarrierLicenses(requestDto).subscribe((n) => {
        this.clientLicenses = n.items;
        this.paginationLicense.totalRecords = n.totalRecords;
      })
    );
  }

  onUpdateModel(clientModelResp: any) {
    this.clientModel = clientModelResp;
  }

  onLoadTenantList(searchTerm?: any) {
    this.pagination = this.setPaginationInstanceValues(searchTerm);
    this.loadClientInstancesList();
  }

  setPaginationInstanceValues(searchTerm?: any) {
    return {
      page: 1,
      pageSize: 20,
      sortColumn: 'modifyDate',
      direction: 'asc',
      ...searchTerm,
    };
  }

  onLoadLicenseList(searchTerm?: any) {
    this.paginationLicense = this.setPaginationInstanceValues(searchTerm);
    this.loadClientLicenseList();
  }

  loadRelationshipList() {
    const requestDto: BondProClientBusinessToBusinessRequestDto = {
      ...this.paginationRelationships,
      nGCarrierClientId: this._clientId,
    };
    this._subs.push(
      this.licenseService.getNGClientBusinessToBusiness(requestDto).subscribe((n) => {
        // this.clientInstances = n.items;
        this.paginationRelationships.totalRecords = n.totalRecords;
        this.relationships = n.items;
      })
    );
  }

  onLoadRelationshipsList(searchTerm?: any) {
    this.paginationRelationships = this.setPaginationRelationshipValues(searchTerm);
    this.loadRelationshipList();
  }

  setPaginationRelationshipValues(searchTerm?: any) {
    return {
      page: 1,
      pageSize: 20,
      sortColumn: 'modifyDate',
      direction: 'asc',
      ...searchTerm,
    };
  }

  onPageChangeRelationships(event: any) {
    this.paginationRelationships.page = event.page
    this.paginationRelationships.pageSize = event.pageSize
    this.loadRelationshipList()
  }

  onSortChangeRelationships(event: any) {
    this.paginationRelationships.direction = event.direction
    this.paginationRelationships.sortColumn = event.sortColumn
    this.loadRelationshipList()
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }
}
