<div class="bp-tabs" *ngIf="!isTabtertiary">

  <mat-tab-group *ngIf="!tabsBarTemplate" dynamicHeight [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="selectedTabChanged($event)">

    <ng-container *ngFor="let tab of tabsList; index as i" >

      <mat-tab label="{{tab.label}}"
              *ngIf="!tab.isHidden"
              [disabled]="tab.isDisabled"
              class="{{tab.customClass}}">

        <ng-template mat-tab-label>
          <!-- For simple tab name -->
          <span *ngIf="!tab.hasDropdown">
            {{tab.label}}
          </span>

          <!-- For tab with dropdown list -->
          <span class="bp-tab-label" (click)="stopPropagation($event)" [matMenuTriggerFor]="bp_tabs_dropdown" *ngIf="tab.hasDropdown">
            {{tab.label}}
            <span class="bp-tab-icon" [bpIcon]="_iconRefEnum.BpCaret" [Size]="'md'"></span>

            <mat-menu #bp_tabs_dropdown="matMenu">
              <button mat-menu-item (click)="onSubMenuClick($event, tab, option, i)"
                *ngFor="let option of tab.dropdownList">
                <span>{{tab.dropdownOptionDisplayKey ? option[tab.dropdownOptionDisplayKey] : option}}</span>
              </button>
            </mat-menu>

          </span>

        </ng-template>

      </mat-tab>

    </ng-container>

  </mat-tab-group>

  <!-- For Custom Template View -->
  <ng-container *ngIf="tabsBarTemplate">

    <ng-container *ngTemplateOutlet="tabsBarTemplate"></ng-container>

  </ng-container>

</div>

<div class="bp-tabs-tertiary" *ngIf="isTabtertiary">
  <mat-tab-group *ngIf="!tabsBarTemplate" dynamicHeight [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="selectedTabChanged($event)">

    <ng-container *ngFor="let tab of tabsList; index as i" >

      <mat-tab label="{{tab.label}}"
              *ngIf="!tab.isHidden"
              [disabled]="tab.isDisabled"
              class="{{tab.customClass}}">

        <ng-template mat-tab-label>
          <!-- For simple tab name -->
          <span *ngIf="!tab.hasDropdown">
            {{tab.label}}
          </span>

        </ng-template>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>
