import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IconRefEnum, DateTimeService } from 'library';
import { startCase } from 'lodash'
import { DetailHeader } from '../activity-history-dialog/activity-history-dialog.component';

@Component({
  selector: 'app-activity-history-dynamic-grid',
  templateUrl: './activity-history-dynamic-grid.component.html',
  styleUrls: ['./activity-history-dynamic-grid.component.scss'],
})
export class ActivityHistoryDynamicComponent implements OnInit, OnDestroy, OnChanges {

  @Input() differenceSection: any;
  @Input() selectedActivity!: any;
  @Input() extraDetailHeaders: DetailHeader[] = [];

  closeIcon = IconRefEnum.BpClose;

  rowValues: any[] = []
  columnNames: any;
  subColumnNames: any;

  constructor(
    private dialogRef: MatDialogRef<ActivityHistoryDynamicComponent>,
    private dateTimeServices: DateTimeService,
  ) {
  }
  ngOnChanges(changes: SimpleChanges): void {
      if (!changes.differenceSection?.isFirstChange()) {
        this.initData()
      }
  }
  ngOnInit() {
      this.initData()
  }
  ngOnDestroy() { }

  onCloseModal(): void {
    this.dialogRef.close();
  }

  initData() {
    if (
      this.differenceSection.originalItems.length === 0 ||
      this.differenceSection.differenceSections.length === 0
    ) {
      return
    }
    let columnKeys = this.differenceSection.originalItems[0].columns.map(
      (item: any) => item.customName
    )

    this.rowValues = []
    this.differenceSection.differenceSections.forEach((item: any) => {
      const cellList: any[] = []
      const diffItemList = item.differenceItems
      columnKeys.forEach((columnKey: any) => {
        const diffItem = diffItemList.find((diffItem: any) => diffItem.displayName === columnKey)
        if (diffItem) {
          cellList.push({
            type: 'prev',
            value: diffItem.simpleOriginalValueFormatted,
            key: columnKey,
          })
          cellList.push({
            type: 'new',
            value: diffItem.simpleNewValueFormatted,
            key: columnKey,
          })
        } else {
          cellList.push({
            type: 'prev',
            value: null,
            key: columnKey,
            isEmpty: true,
          })
          cellList.push({
            type: 'new',
            value: null,
            key: columnKey,
          })
        }
      })
      this.rowValues.push({
        cellList,
        itemName: item.sectionName,
      })
    })
    const columnsToExclude: any[] = this.getColumnsToExclude()
    columnKeys = columnKeys.filter((item: any) => !columnsToExclude.includes(item))
    this.columnNames = columnKeys.map((item: any) => startCase(item))
    this.subColumnNames = []
    this.columnNames.forEach(() => {
      this.subColumnNames.push({ name: 'Previous Value' })
      this.subColumnNames.push({ name: 'New Value' })
    })
    this.removeColumnsToExlude(columnsToExclude)
  }

  removeColumnsToExlude(columnsToExclude: any[] = []) {
    this.rowValues.forEach((item: any) => {
      item.cellList = (item.cellList || []).filter((cell: any) => !columnsToExclude.includes(cell.key))
    })
  }

  getColumnsToExclude(): any[] {
    const columnsToExclude: any[] = []
    const columnsCount: any = {}

    this.rowValues.forEach((item: any) => {
      item.cellList.forEach((cell: any) => {
        if (cell.isEmpty) {
          columnsCount[`${cell.key}`] = columnsCount[`${cell.key}`] || 0
          columnsCount[`${cell.key}`] += 1
        }
      })
    })
    Object.entries(columnsCount).forEach((columnCount: any) => {
      if (columnCount[1] === this.rowValues.length) {
        columnsToExclude.push(columnCount[0])
      }
    })

    return columnsToExclude
  }

  formatDate(date: any) {
    return this.dateTimeServices.toLocalTime(date, true)
}

}
