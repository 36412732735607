<div class="dropdown-container">
    <div class="view-options-container">
        <div tabindex="0" (click)="onViewOptionsFocus($event)">
            <ng-container *ngFor="let option of viewOptions">
                <div class="selected-option" *ngIf="option.isSelected">
                    <span>{{option.name}}</span>
                    <button class="close-icon" (click)="onOpitonCheck(option.id, false, $event)"></button>
                </div>
            </ng-container>
        </div>
        <div *ngIf="showOptions && (config?.hasOptions ? config?.options?.length : true)"
            class="manage-options-container" tabindex="0" bpClickOutside
            [keepElementClassList]="config?.keepElementParentClasses ?? []"
            (clickOutside)="swapOptionsView()" [class.left]="config.optionsPosition === selectOptionsPosition.Left"
            [class.right]="config.optionsPosition === selectOptionsPosition.Right" [style.width]="optionsWidth">
            <div>
                <button class="close-icon options-close" (click)="swapOptionsView()"></button>
            </div>
            <div class="bp-custom-temp-container" bpDropDown [config]="customTempConfig"
                (valueChange)="onTempSelectionChange($event)">
            </div>
        </div>
    </div>
</div>