import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  public userName!: string;

  constructor(private authService: MsalService) { }

  public get currentUserValue(): string {
    const activeAccount = this.authService.instance.getActiveAccount()
    if (activeAccount) {
      this.userName = activeAccount.username
    } else {
      this.userName = this.authService.instance.getAllAccounts()[0]?.username
    }
    return this.userName;
  }
}
