import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ICustomerTypeDto, INGStatusTypeInstanceDto } from '../../../interfaces/license-interfaces';

@Component({
  selector: 'app-advanced-filter-management',
  templateUrl: './advanced-filter-management.component.html',
  styleUrls: ['./advanced-filter-management.component.scss'],
})
export class AdvancedFilterManagementComponent implements OnInit {
  @Input() statusList: Array<INGStatusTypeInstanceDto> = [];
  @Input() customerTypeList: Array<ICustomerTypeDto> = [];
  @Output() cancel = new EventEmitter();
  @Output() reset = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Output() applyFilters = new EventEmitter();

  formDynamic!: FormGroup;
  listFields!: Array<FieldDynamicForm>;
  constructor(private formBuilder: FormBuilder) {
    this.getFieldsForm();
    this.buildForm();
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['statusList'].firstChange) {
      this.statusList = changes['statusList'].currentValue.filter((item: any) => item.statusTypeId != 0)
    }

    if (changes['customerTypeList'].firstChange) {
      this.customerTypeList = changes['customerTypeList'].currentValue.filter((item: any) => item.customerTypeCode != 'All')
    }
  }

  buildForm() {
    const formGroup: any = new FormGroup({});
    this.listFields.forEach((formControl: FieldDynamicForm) => {
      const validators = formControl.isRequired ? [Validators.required] : [];
      formGroup.addControl(
        formControl.controlName,
        new FormControl(null, validators)
      );
    });
    this.formDynamic = formGroup;
  }

  onClearInput(controlInput: string) {
    this.formDynamic.controls[controlInput].reset();
  }

  onCancel() {
    this.formDynamic.reset();
    this.cancel.emit();
  }

  onClear() {
    // this.clear.emit();
    this.formDynamic.reset();
  }

  onReset() {
    this.reset.emit();
    this.formDynamic.reset();
  }
  onApplyFilters() {
    this.applyFilters.emit(this.formDynamic.value);
  }

  getFieldsForm(): void {
    this.listFields = [
      {
        label: 'Customer Id',
        controlName: 'ngCarrierClientId',
        isRequired: false,
        type: 'number'
      },
      {
        label: 'Customer Name',
        controlName: 'clientName',
        isRequired: false,
        type: 'text'
      },
      {
        label: 'Description',
        controlName: 'clientDescription',
        isRequired: false,
        type: 'text'
      },
      {
        label: 'Customer Number',
        controlName: 'customerNumber',
        isRequired: false,
        type: 'text'
      },
      {
        label: 'Contract Reference Number',
        controlName: 'contractReferenceNumber',
        isRequired: false,
        type: 'text'
      },
      {
        label: 'Customer Type',
        controlName: 'customerTypeCodes',
        isRequired: false,
        type: 'select',
      },
      {
        label: 'Last Modified By',
        controlName: 'modifiedBy',
        isRequired: false,
        type: 'text'
      },
      {
        label: 'Status',
        controlName: 'clientStatuses',
        isRequired: false,
        type: 'select',
      },
    ];
  }
}
export interface FieldDynamicForm {
  controlName: string;
  label: string;
  isRequired: boolean;
  value?: any;
  type: string;
}
