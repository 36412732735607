import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GridModule } from '@progress/kendo-angular-grid';
import { PagerModule } from '@progress/kendo-angular-pager';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { PopupModule } from '@progress/kendo-angular-popup';
import { InputsModule } from '@progress/kendo-angular-inputs';

import { MaterialModule } from './material.module';
import { ExternalModuleImports } from './external.imports';

import { AppHeaderComponent } from './components/header/app-header.component';
import { AppHorizontalMenuComponent } from './components/horizontal-menu/app-horizontal-menu.component';
import { AppSideBarComponent } from './components/sidebar/app-sidebar.component';
import { BpAccordionComponent } from './components/accordion/accordion.component';
import { BpDropDownComponent } from './components/bp-drop-down/bp-drop-down.component';
import { BpDropDownOptionsComponent } from './components/bp-drop-down-options/bp-drop-down-options.component';
import { BpeDatepickerComponent } from './components/form-fields/datepicker/datepicker.component';
import { BpeGridComponent } from './components/grid/grid.component';
import { BpIconComponent } from './components/icons/bp-icon/bp-icon.component';
import { BpMatChipsComponent } from './components/bp-mat-chips/bp-mat-chips.component';
import { BpMatIconComponent } from './components/icons/bp-mat-icon/bp-mat-icon.component';
import { BpTabsBarComponent } from './components/bp-tabs-bar/bp-tabs-bar.component';
import { BpStatefulTextboxComponent } from './components/bp-stateful-textbox/bp-stateful-textbox.component';
import { BreadCrumbsComponent } from './components/breadcrumb/breadcrumbs.component';
import { CustomSpinnerComponent } from './components/spinner/custom-spinner/custom-spinner.component';
import { SpinnerComponent } from './components/spinner/data-loading-spinner/spinner.component';
import { BpStatusComponent } from './components/bpe-status/bpe-status.component';

import { BpClickOutsideDirective } from './directives/click-outside.directive';
import { BpDropDownDirective } from './directives/bp-drop-down.directive';
import { BpFieldControlDirective } from './directives/fieldcontrol/bpFieldControl.directive';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';

import { BpHeaderService } from './services/bpHeader.service';
import { BpSideBarService } from './services/bpSidebar.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BpeDatePipe } from './pipes/bpe-date.pipe';
import { LibSelectComponent } from './components/select/select.component';


@NgModule({
  declarations: [
    AppHeaderComponent,
    AppHorizontalMenuComponent,
    AppSideBarComponent,
    BpAccordionComponent,
    BpClickOutsideDirective,
    BpDropDownComponent,
    BpDropDownDirective,
    BpDropDownOptionsComponent,
    BpeDatepickerComponent,
    BpeGridComponent,
    BpFieldControlDirective,
    BpIconComponent,
    BpMatChipsComponent,
    BpMatIconComponent,
    BpTabsBarComponent,
    BpStatefulTextboxComponent,
    BreadCrumbsComponent,
    CustomSpinnerComponent,
    SpinnerComponent,
    BpStatusComponent,
    SafeHtmlPipe,
    BpeDatePipe,
    LibSelectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    GridModule,
    PagerModule,
    TooltipsModule,
    PopupModule,
    InputsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule.forChild([]),
    FontAwesomeModule,
    ...ExternalModuleImports,
  ],
  exports: [
    AppHeaderComponent,
    AppHorizontalMenuComponent,
    AppSideBarComponent,
    BpAccordionComponent,
    BpClickOutsideDirective,
    BpDropDownComponent,
    BpDropDownDirective,
    BpDropDownOptionsComponent,
    BpeDatepickerComponent,
    BpeGridComponent,
    BpFieldControlDirective,
    BpIconComponent,
    BpMatChipsComponent,
    BpMatIconComponent,
    BpTabsBarComponent,
    BpStatefulTextboxComponent,
    BreadCrumbsComponent,
    CustomSpinnerComponent,
    SpinnerComponent,
    BpStatusComponent,
    SafeHtmlPipe,
    BpeDatePipe,
    FontAwesomeModule,
    LibSelectComponent,
  ],
  providers: [BpHeaderService, BpSideBarService],
})
export class LibraryModule {}
