import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { IPalette } from '../../managers/tool-palette.manager.service';
import { ToolPaletteTypeEnum } from '../../enums/toolPaletteType';
import { BpSideBarService } from '../../services/bpSidebar.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-horizontal-menu',
    templateUrl: './app-horizontal-menu.component.html',
    styleUrls: ['./app-horizontal-menu.component.scss']
})

export class AppHorizontalMenuComponent implements OnInit, OnDestroy {
    //#region Horizontal Menu Properties
    subscription!: any;
    navigationLinkList: Array<IPalette> = [];
    showSpinner = false;
    //#endregion Horizontal Menu Properties

    constructor(private _router: Router,
        private _bpSideBarService: BpSideBarService) { }

    ngOnInit(): void {
        this.subscription = this._router.events.subscribe(ev => {
            if (ev instanceof NavigationEnd) {
                this.setActiveItem(ev.urlAfterRedirects);
            }
        });
        const paletteSubs = this._bpSideBarService.getHorizontalMenuList().subscribe(
            menuList => {
                this.navigationLinkList = menuList;
            }
        );
        this.subscription.add(paletteSubs);
        this._bpSideBarService.getToolPaletteList(ToolPaletteTypeEnum.MainMenuBar);
    }

    setActiveItem(activeURL: string): void {
        this.navigationLinkList.map(navigationLink => {
            if (navigationLink.paletteItemList && navigationLink.paletteItemList.length) {
                for (let i = 0, length = navigationLink.paletteItemList.length; i < length; i++) {
                    navigationLink._isActive = false;
                    if (navigationLink.paletteItemList[i].url &&
                        this._router.isActive(navigationLink.paletteItemList[i].url ?? '', false)) {
                        navigationLink._isActive = true;
                        break;
                    }
                }
            } else {
                if (this._router.isActive(navigationLink.url ?? '', false)) {
                    navigationLink._isActive = true;
                } else {
                    navigationLink._isActive = false;
                }
            }
        });
    }

    //#endregion Horizontal Menu Methods

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
