import { Injectable, Output, EventEmitter } from '@angular/core';
import { IPalette, IGetToolPaletteListRequest, IGetToolPaletteListResponse } from '../managers/tool-palette.manager.service';
import { Subject, Observable } from 'rxjs';
import { ToolPaletteTypeEnum } from '../enums/toolPaletteType';

@Injectable({
  providedIn: 'root'
})
export class BpSideBarService {
  @Output() navListChangeListener: EventEmitter<BpNavigationLinkModel[]> = new EventEmitter();

  isExpanded = false;
  isVisible = true;

  private navigationLinksList: BpNavigationLinkModel[] = [];
  private horizontalMainMenuList = new Subject<Array<IPalette>>();
  private configPageList = new Subject<Array<IPalette>>();

  constructor() {
    // This call is to get side bar list. Uncomment it when we don't use horizontal menu
    // this.getNavigationList();
  }

  getNavList(): BpNavigationLinkModel[] {
    return this.navigationLinksList;
  }

  setNavList(list: BpNavigationLinkModel[]) {
    this.navigationLinksList = list;
    this.navListChangeListener.emit(this.navigationLinksList);
  }

  toggleSidebarExpansion() {
    this.isExpanded = !this.isExpanded;
  }

  toggleSidebarVisibility() {
    this.isVisible = !this.isVisible;
  }

  async getNavigationList() {
    try {
      const request = {};
      // const response: any = await this._toolPaletteManager.getToolPaletteList(request);
      const response: any = undefined;
      this.setNavList(response.paletteList);

    } catch (error) {
      console.log(error);
    }
  }

  getHorizontalMenuList(): Observable<Array<IPalette>> {
    return this.horizontalMainMenuList.asObservable();
  }

  getConfigPageList(): Observable<Array<IPalette>> {
    return this.configPageList.asObservable();
  }

  async getToolPaletteList(paletteTypeId: ToolPaletteTypeEnum) {
    try {
      const request: IGetToolPaletteListRequest = {
        paletteType: paletteTypeId
      };
      // const response: IGetToolPaletteListResponse|any = await this._toolPaletteManager.getToolPaletteList(request);
      const response: IGetToolPaletteListResponse|any = undefined;
      if (response && response.paletteList) {
        if (paletteTypeId === ToolPaletteTypeEnum.MainMenuBar) {
          this.horizontalMainMenuList.next(response.paletteList);
        } else if (paletteTypeId === ToolPaletteTypeEnum.ConfigurationPage) {
          this.configPageList.next(response.paletteList);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export interface BpNavigationLinkModel {
  name: string;
  id?: number;
  url?: string;
  icon?: string;
  tooltip?: string;
  description?: string;
  customClass?: string;
  floatDirection?: string;
  key?: string;
  isDisabled?: boolean;
  paletteItemList?: BpNavigationLinkModel[];
  _isActive?: boolean;
}
