import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from '../services/date-time.service';

@Pipe({ name: 'bpeDate' })
export class BpeDatePipe implements PipeTransform {
  constructor(private dateTimeServices: DateTimeService) { }

  transform(date?: any, addTime?: boolean): string {

    if (date) {
        return this.dateTimeServices.toLocalTime(date, addTime || false);
    }

    return '';
  }
}
