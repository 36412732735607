import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { LibraryModule } from 'library';
// import { LibraryModule } from '../../../library/src/library.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { ExternalModuleImports } from './external.imports';

import { AddEditClientComponent } from './components/add-edit-client/add-edit-client.component';
import { AdvancedFilterManagementComponent } from './components/client-license-management/advanced-filter-management/advanced-filter-management.component';
import { ClientLicenseComponent } from './components/client-license/client-license.component';
import { ClientLicenseManagementComponent } from './components/client-license-management/client-license-management.component';
import { FilterManagementGridComponent } from './components/client-license-management/filter-management-grid/filter-management-grid.component';
import { LicenseClientHeaderComponent } from './components/client-license/license-client-header/license-client-header.component';
import { LicenseDetailsComponent } from './components/license-details/license-details.component';
import { LicenseFilterComponent } from './components/license-details/license-filter/license-filter.component';
import { TenantDetailsComponent } from './components/tenant-details/tenant-details.component';
import { FilterGridComponent } from './components/filter-grid/filter-grid.component';
import { LandingComponent } from './components/landing/landing.component';

import { AddLicenseModalComponent } from './components/dialog/add-license-modal/add-license-modal.component';
import { AddTenantModalComponent } from './components/dialog/add-tenant-modal/add-tenant-modal.component';
import { ConfirmationModalComponent } from './components/dialog/confirmation-modal/confirmation-modal.component';
import { InformationModalComponent } from './components/dialog/information-modal/information-modal.component';
import { SubscriptionModalComponent } from './components/dialog/subscription-modal/subscription-modal.component';
import { UpdateStatusModalComponent } from './components/dialog/update-status-modal/update-status-modal.component';

import { MsalInterceptorConfiguration, MsalRedirectComponent, MsalGuard, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { ApiSettings } from './apiSettings';
import { ActivityHistoryDialogComponent } from './components/activity-history/activity-history-dialog/activity-history-dialog.component';
import { ActivityHistoryDetailsDialogComponent } from './components/activity-history/activity-history-details-dialog/activity-history-details-dialog.component';
import { ActivityHistoryDiffGridComponent } from './components/activity-history/activity-history-diff-grid/activity-history-diff-grid.component';
import { ActivityHistoryDynamicComponent } from './components/activity-history/activity-history-dynamic-grid/activity-history-dynamic-grid.component';
import { AddRelationshipModalComponent } from './components/dialog/add-relationship-modal/add-relationship-modal.component';
import { RelationshipDetailsComponent } from './components/relationship-details/relationship-details.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

function appSettingsLoader(appSettingsService: ApiSettings): any {
  const promise = appSettingsService.load().then(() => {});
  return () => promise;
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: ApiSettings.azureAD_ClientId, // This is your client ID
      authority: 'https://login.microsoftonline.com/' + ApiSettings.azureAD_TenantId, // This is your tenant ID
      redirectUri: ApiSettings.azureAD_RedirectUri,
      postLogoutRedirectUri: ApiSettings.azureAD_RedirectUriMain,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: 'main'
  };
}


export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

// Jhimy
// export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
//   const protectedResourceMap = new Map<string, Array<string>>();
//   // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
//   protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);
//   return {
//     interactionType: InteractionType.Redirect,
//     protectedResourceMap
//   };
// }

/* const appInitializerFn = (appConfig: ConfigurationService) => {
  return () => {
    return appConfig.loadConfig();
  };
};
 */
@NgModule({
  declarations: [
    AppComponent,
    AddEditClientComponent,
    AddLicenseModalComponent,
    AddTenantModalComponent,
    ConfirmationModalComponent,
    InformationModalComponent,
    AdvancedFilterManagementComponent,
    ClientLicenseComponent,
    ClientLicenseManagementComponent,
    FilterManagementGridComponent,
    LicenseClientHeaderComponent,
    LicenseDetailsComponent,
    LicenseFilterComponent,
    SubscriptionModalComponent,
    TenantDetailsComponent,
    UpdateStatusModalComponent,
    FilterGridComponent,
    LandingComponent,
    ActivityHistoryDialogComponent,
    ActivityHistoryDetailsDialogComponent,
    ActivityHistoryDiffGridComponent,
    ActivityHistoryDynamicComponent,
    AddRelationshipModalComponent,
    RelationshipDetailsComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    GridModule,
    HttpClientModule,
    LibraryModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    ...ExternalModuleImports,
    // MsalModule.forRoot(new PublicClientApplication({
    //   auth: {
    //     clientId: azureAD_ClientId, // This is your client ID
    //     authority: 'https://login.microsoftonline.com/' + azureAD_TenantId, // This is your tenant ID
    //     redirectUri: azureAD_RedirectUri,
    //     postLogoutRedirectUri: azureAD_RedirectUriMain,
    //   },
    //   cache: {
    //     cacheLocation: 'localStorage',
    //     storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    //   }
    // }),
    //   {
    //     interactionType: InteractionType.Redirect,
    //     authRequest: {
    //       scopes: ['user.read']
    //     },
    //     loginFailedRoute: 'main'
    //   }, MSALInterceptorConfigFactory()),
  ],
  providers: [
    ApiSettings,
    {
      provide: APP_INITIALIZER,
      useFactory: appSettingsLoader,
      deps: [ApiSettings],
      multi: true
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory,
        deps: [ApiSettings]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    // {
    //     provide: MSAL_CONFIG_ANGULAR,
    //     useFactory: MSALAngularConfigFactory,
    //     deps: [ApiSettings]
    // },
      // {
      //     provide: APP_INITIALIZER,
      //     useFactory:(myInitService:ApiSettings)=>()=>myInitService.initSettings(),
      //     deps:[ApiSettings],
      //     multi:true,
      // }

/*         ConfigurationService,
        {
          provide: APP_INITIALIZER,
          useFactory: appInitializerFn,
          multi: true,
          deps: [ConfigurationService]
        } */
  ],

  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {


}


