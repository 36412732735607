<div class="select-options-container">
    <section class="option-actions">
        <div class="action-item">
            <mat-checkbox [checked]="selectAllOptions" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="selectAllOptions" (change)="swapOptionsState()">
                Select All
            </mat-checkbox>
        </div>
    </section>
    <section class="option-items">
        <div *ngFor="let option of options" class="item" [style.width]="calculateItemWidth()">
            <mat-checkbox [checked]="option?.isSelected ?? false" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="option.isSelected" (change)="onOpitonCheck(option.id, $event)">
            </mat-checkbox>
            <div tabindex="0" (click)="onOptionLabelClick(option.id, $event)">
                {{option.displayName ? option.displayName : option.name}}</div>
        </div>
    </section>
</div>