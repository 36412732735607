import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IconRefEnum,DateTimeService } from 'library';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  IBondProNGCarrierClientDto,
  INGCarrierClientModel,
  INGCarrierClientRelationshipModel,
  SaveBusinessToBusinessRequestDto,
} from '../../../interfaces/license-interfaces';
import { CommonHelperService } from '../../../services/common-helper.service';
import { LicenseService } from '../../../services/license.service';
import { MessageHelperService } from '../../../services/message-helper.service';
import { InformationModalComponent } from '../information-modal/information-modal.component';
import { IBpeGridColumn } from '../../../../../../library/src/components/grid/grid.interface';
import { kebabCase } from 'lodash';
import { UserService } from '../../../services/user.service';

dayjs.extend(utc);

declare global {
  interface Window {
      arc:any;
  }
}

@Component({
  selector: 'app-add-relationship-modal',
  templateUrl: './add-relationship-modal.component.html',
  styleUrls: ['./add-relationship-modal.component.scss'],
})
export class AddRelationshipModalComponent implements OnInit, OnDestroy {
  @Input() clientModel!: IBondProNGCarrierClientDto;
  @Input() relationships: INGCarrierClientRelationshipModel[] = [];

  private _subs = [] as Subscription[];
  closeIcon = IconRefEnum.BpClose;
  bpView = IconRefEnum.BpView;
  calendarIcon = IconRefEnum.BpCalendar;
  editIcon = IconRefEnum.Edit;
  editMode: boolean = false;
  expDateIsRequired: boolean = true;
  disabledKey: boolean = true;
  ngClients: INGCarrierClientModel[] = [];
  columnDefs: IBpeGridColumn[] = [];
  decripLicenseModel: any;
  showDecripLicense: boolean = false;
  pagination!: any;
  selectedClientIds: number[] = [];

  constructor(
    private licenseService: LicenseService,
    private dialogRef: MatDialogRef<AddRelationshipModalComponent>,
    private _commonHelperService: CommonHelperService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private messageService: MessageHelperService,
    private dateTimeService: DateTimeService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    window.arc=this
    this.defColumns()
    this.setPaginationValues();
    this.loadClientList();
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  loadClientList() {
    this._subs.push(
      this.licenseService
        .getNGCarrierClients(this.pagination)
        .subscribe((n) => {
          this.ngClients = n.items;
          this.ngClients = this.ngClients.map(x=> {return {...x,disableRow: x.clientStatus === 'Disabled' ? true : false}})
          this.pagination.totalRecords = n.totalRecords;
        })
    );
  }

  onSave() {
    const request: SaveBusinessToBusinessRequestDto = {
      nGCarrierClientId: this.clientModel.ngCarrierClientId as number,
      nGCarrierClientIdRelations: this.selectedClientIds,
      username: this.userService.currentUserValue || '',
    };
    this._subs.push(
      this.licenseService
        .saveBusinessToBusiness(request)
        .subscribe((response) => {
          if (response?.success) {
            this.toastr.success('The relationship has been saved!', 'Success');
            this.dialogRef.close({ event: 'saved-successfuly' });
          } else {
            this.toastr.error(
              response?.errorMessages[0] || 'There was an error, please try again',
              'Error'
            );
          }
        })
    );
  }

  showErrorModal(errorMessages: string[]) {
    const dialogRef = this.dialog.open(InformationModalComponent);
    dialogRef.componentInstance.title = 'Create License';
    dialogRef.componentInstance.message =
      'Unable to create a license due to the following:';
    dialogRef.componentInstance.messageDetails = errorMessages;
    dialogRef.componentInstance.messageIcon = IconRefEnum.BpWarning;
    dialogRef.afterClosed().subscribe((result: any) => {});
  }

  onCloseModal(): void {
    // if (this.licenseModal) {
    //   this.dialogRef.close(true);
    // } else {
    // }
    this.dialogRef.close();
  }

  kebabCase(x: string) {
    return kebabCase(x);
  }

  defColumns() {
    this.columnDefs = [
      { title: 'Customer Name', field: 'clientName' },
      { title: 'CUSTOMER TYPE', field: 'customerTypeName' },
      { title: 'Customer ID', field: 'ngCarrierClientId', width: 60 },
      { title: 'CUSTOMER NUMBER', field: 'customerNumber' },
      {
        title: 'STATUS', field: 'clientStatus', bodyCellTemplate: (col, row) => {

          return `<span class="bpe-status bpe-status-${this.kebabCase(row.clientStatus)}">
                    <span>${row.clientStatus}</span>
                  </span>`
        }
      },
    ];
  }

  onRemove(item: any) {

  }

  onSortChange(event: any) {
    this.pagination.direction = event.direction
    this.pagination.sortColumn = event.sortColumn
    this.loadClientList()
  }

  onPageChange(event: any) {
    this.pagination.page = event.page
    this.pagination.pageSize = event.pageSize
    this.loadClientList()
  }

  onChangeFilter(searchModel: any) {
    this.setPaginationValues({
      clientName: searchModel.search ? searchModel.search : void 0,
    });
    this.loadClientList();
  }

  onSelectedKeysChange($event: any) {
    this.selectedClientIds = $event.selectedIds;
  }

  setPaginationValues(searchTerm?: any) {
    this.pagination = {
      page: 1,
      pageSize: 5,
      sortColumn: 'ngCarrierClientId',
      direction: 'desc',
      totalRecords: 0,
      ...searchTerm,
      clientStatuses : ['Active'],
      customerTypeCodes: this.clientModel.customerTypeCode === 'CARRIER' ? ['AGENCY'] : ['CARRIER'],
      relationBusinessNGCarrierClientIds: (this.relationships || []).map((r) => r.ngCarrierClientIDRelation),
    };
  }
}
