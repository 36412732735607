<div class="horizontal-container">
    <bpe-custom-spinner *ngIf="showSpinner"></bpe-custom-spinner>
    <div>
        <div class="nav-item logo">
            <img class="collapsed-logo" src="assets/images/BP-default@2x.png" alt="BP" />
        </div>
        <div class="nav-items-container">
            <ng-container *ngFor="let navItem of navigationLinkList">
                <button class="nav-item" *ngIf="!navItem.paletteItemList?.length" mat-icon-button
                    [routerLink]="navItem.url" [class.active]="navItem._isActive">
                    {{navItem.name?.toLowerCase()}}
                </button>
                <button class="nav-item" *ngIf="navItem.paletteItemList?.length" mat-icon-button
                    [matMenuTriggerFor]="mainMenu" [matMenuTriggerData]="{subNavItems: navItem.paletteItemList}"
                    [class.active]="navItem._isActive">
                    {{navItem.name?.toLowerCase()}}
                </button>
            </ng-container>
            <mat-menu #mainMenu="matMenu">
                <ng-template matMenuContent let-subNavItems="subNavItems">
                    <ng-container *ngFor="let subNavItem of subNavItems">
                        <button *ngIf="!subNavItem.paletteItemList?.length" class="app-horizontal-sub-menu"
                            mat-menu-item [routerLink]="subNavItem.url"
                            routerLinkActive="active">{{subNavItem.name}}</button>
                        <button *ngIf="subNavItem.paletteItemList?.length" class="app-horizontal-sub-menu" mat-menu-item
                            [matMenuTriggerFor]="subMenu"
                            [matMenuTriggerData]="{subNavItems2: subNavItem.paletteItemList}"
                            [routerLink]="subNavItem.url" routerLinkActive="active">{{subNavItem.name}}</button>
                    </ng-container>
                </ng-template>
            </mat-menu>
            <mat-menu #subMenu="matMenu">
                <ng-template matMenuContent let-subNavItems2="subNavItems2">
                    <button mat-menu-item *ngFor="let subNavItem2 of subNavItems2" [routerLink]="subNavItem2.url"
                        routerLinkActive="active">{{subNavItem2.name}}</button>
                </ng-template>
            </mat-menu>
        </div>
    </div>
</div>