import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IconRefEnum } from 'library';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/internal/Subscription';
import {
  IBondProNGCarrierClientDto,
  IBondProNGClientStatusDto,
  INGStatusTypeInstanceDto,
} from '../../../interfaces/license-interfaces';
import { LicenseService } from '../../../services/license.service';
import { MessageHelperService } from '../../../services/message-helper.service';
import { ConfirmationModalComponent } from '../../dialog/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-license-client-header',
  templateUrl: './license-client-header.component.html',
  styleUrls: ['./license-client-header.component.scss'],
})
export class LicenseClientHeaderComponent implements OnInit, OnDestroy {
  @Output() updateStatus = new EventEmitter();
  @Input() set clientModel(value: IBondProNGCarrierClientDto) {
    if (!value) return;
    this._clientModel = value;
  }
  @Input() set statusList(value: INGStatusTypeInstanceDto[]) {
    if (!value) return;
    this._statusList = value.reduce((result: any, filter) => {
      result[filter.statusTypeCode] = {
        id: filter.statusTypeId,
        label: filter.statusName
      };
      return result;
    }, {});
  }

  _subs = [] as Subscription[];
  _clientModel!: IBondProNGCarrierClientDto;
  _statusList!: any;
  constructor(
    private licenseService: LicenseService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private messageService: MessageHelperService
  ) {}

  ngOnInit() {}

  onChangeStatus() {
    let message, messageDetails;
    let statusId: number;
    if (this._clientModel?.statusTypeId === this._statusList?.ACTIVE.id) {
      statusId = this._statusList.INACTIVE.id;
      message = 'Are you sure you want to Inactive this customer?';
      messageDetails = ['All licenses and subscription will be inactive.'];
    } else {
      statusId = this._statusList.ACTIVE.id;
      message = 'Are you sure you want to activate this customer?';
      messageDetails = [
        'Once customer is active, all licenses and subscription will need to be manually activated.',
      ];
    }
    this.showConfirmationModal(statusId, message, messageDetails);
  }

  showConfirmationModal(
    statusId: number,
    message: string,
    messageDetails: string[]
  ) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent);
    dialogRef.componentInstance.title = 'Modify Customer Status';
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.messageDetails = messageDetails;
    dialogRef.componentInstance.messageIcon = IconRefEnum.BpWarning;
    dialogRef.componentInstance.cancelBtn = 'Cancel';
    dialogRef.componentInstance.acceptBtn = 'Confirm';
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.updateClientStatus(statusId);
      }
    });
  }

  updateClientStatus(statusId: number) {
    const clientModel: IBondProNGClientStatusDto = {
      ngCarrierClientId: this._clientModel.ngCarrierClientId,
      statusTypeId: statusId,
      statusTypeName: this.getStatusTypeName(statusId),
      modifiedBy: this._clientModel.modifiedBy
    };
    this._subs.push(
      this.licenseService.updateClientStatus(clientModel).subscribe((n) => {
        if (n.success) {
          this.toastr.success('The status has been updated successfully', 'Success');
          this.updateStatus.emit();
        } else {
          const messageModel = {
            title: 'Customer',
            message: 'Unable to update status due to the following:'
          }
          this.messageService.showErrorModal(messageModel, n.errorMessages);
        }
      })
    );
  }

  getStatusTypeName(statusTypeId: number) {
    const foundItem: any = Object.values(this._statusList || {}).find((item: any) => item.id === statusTypeId)
    return foundItem ? foundItem.label : void 0;
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }
}
