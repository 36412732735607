import { Injectable } from '@angular/core';
// import { BpTabsModel } from '@lib/bp-shared';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteTabsService {

  routeTabsList: Array<BpTabsModel> = [
    { label: 'Customer License & Subscription Management', onClick: () => this.routeToUrl('/license'), customClass: '', isDisabled: false, isHidden: false }
  ];

  constructor(
    private _router: Router
  ) {
    // Re-routing to the default tab Users when site is reloaded.
    // this.routeToUrl('/');
  }

  routeToUrl(url: string) {
    this._router.navigate(['/license' + url]);
  }

}

export interface BpTabsModel {
  code?: string;
  label: string;
  customClass: string;
  isDisabled: boolean;
  isHidden: boolean;
  hasDropdown?: boolean;
  dropdownList?: Array<any>;
  dropdownOptionDisplayKey?: string;
  onClick($event: any, dropdownOption?: any): void;
}
