import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs'
// import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
/**
 * Represents a service for DateTime.
 */
 @Injectable({
    providedIn: 'root'
})
export class DateTimeService {
  constructor() { }

  toLocalTime(date: any, addTime?: any) {
    if (date && new Date(date).toString() !== 'Invalid Date') {
      if (addTime) {
        return dayjs.utc(date).local().format('MM/DD/YYYY hh:mm:ss A')
      } else {
        return dayjs.utc(date).local().format('MM/DD/YYYY')
      }
    }
    return date
  }

  toLocal(date: any) {
    if (date && new Date(date).toString() !== 'Invalid Date') {
        return dayjs.utc(date).local().toDate()
    }
    return date
  }

  stringDateToLocal(date: any, addTime?: any) {
    if (date && new Date(date).toString() !== 'Invalid Date') {
      if (addTime) {
        return dayjs(date).local().format('MM/DD/YYYY hh:mm:ss A')
      } else {
        return dayjs(date).local().format('MM/DD/YYYY')
      }
    }
    return date
  }
}
