<!-- Chip List without reactive form -->
<mat-form-field class="bp-chip-list" [class.bp-chip-disabled]="chipDisabled" *ngIf="!bpFormGroup">
    <mat-chip-grid #chipGrid [attr.aria-label]="chipAreaLabel" [multiple]="multiChipSelectable">
        <!-- <ng-container *ngTemplateOutlet="matChipTemplate"></ng-container> -->
        <mat-chip-row *ngFor="let chip of chipList"
            [selectable]="chip.isSelectable ? chip.isSelectable : chipSelectable"
            [removable]="chip.isRemovable === false ? chip.isRemovable : chipRemovable"
            [disabled]="chip.isDisabled ? chip.isDisabled : chipDisabled" [selected]="chip.isSelected"
            (selectionChange)="onSelectionChange($event, chip)" (removed)="removeChip(chip)">
            {{chip.value}}
            <mat-icon matChipRemove *ngIf="chip.isRemovable===false ? chip.isRemovable : chipRemovable">cancel</mat-icon>
        </mat-chip-row>
        <input [placeholder]="chipList.length < 1 ? chipPlaceholder : ''" [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="chipSeparatorKeysCodes" [matChipInputAddOnBlur]="chipAddOnBlur" [disabled]="chipDisabled"
            (matChipInputTokenEnd)="addChip($event)">
    </mat-chip-grid>
</mat-form-field>

<!-- Chip List with reactive form -->
<mat-form-field [class.bp-chip-disabled]="chipDisabled" class="bp-chip-list" [formGroup]="bpFormGroup" *ngIf="bpFormGroup">
    <mat-chip-grid #chipGrid [attr.aria-label]="chipAreaLabel" [multiple]="multiChipSelectable"
        aria-orientation="horizontal">
        <mat-chip-row *ngFor="let chip of chipList"
            [selectable]="chip.isSelectable ? chip.isSelectable : chipSelectable"
            [removable]="chip.isRemovable === false ? chip.isRemovable : chipRemovable"
            [disabled]="chip.isDisabled ? chip.isDisabled : chipDisabled" [selected]="chip.isSelected"
            (selectionChange)="onSelectionChange($event, chip)" (removed)="removeChip(chip)">
            {{chip.value}}
            <mat-icon matChipRemove *ngIf="chip.isRemovable===false ? chip.isRemovable : chipRemovable">cancel</mat-icon>
        </mat-chip-row>
        <input #chipFormInput [placeholder]="chipList.length < 1 ? chipPlaceholder : ''" [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="chipSeparatorKeysCodes" [matChipInputAddOnBlur]="chipAddOnBlur"
            [disabled]="chipDisabled"
            (matChipInputTokenEnd)="addChip($event)" [formControlName]="bpFormControlName">
    </mat-chip-grid>
</mat-form-field>

<!-- 
<ng-template #matChipTemplate>
    <mat-chip *ngFor="let chip of chipList" #matChip="matChip"
        [selectable]="chip.isSelectable ? chip.isSelectable : chipSelectable"
        [removable]="chip.isRemovable === false ? chip.isRemovable : chipRemovable"
        [disabled]="chip.isDisabled ? chip.isDisabled : chipDisabled" [selected]="chip.isSelected"
        (click)="(chip.isSelectable ? (chip.isSelected ? matChip.deselect() : matChip.selectViaInteraction()):'')"
        (selectionChange)="onSelectionChange($event, chip)" (removed)="removeChip(chip)">
        {{chip.value}}
        <mat-icon matChipRemove *ngIf="chip.isRemovable===false ? chip.isRemovable : chipRemovable">cancel</mat-icon>
    </mat-chip>
</ng-template> -->