import { Component, AfterViewInit } from '@angular/core';
import { IconRefEnum } from '../../enums/iconRefEnum';
import { BpHeaderService } from '../../services/bpHeader.service';
// import { TokenManager, TokenIdentity} from '@cpi/cpi-core';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements AfterViewInit {
  status = false;
  _iconRefEnum = IconRefEnum;
  // userToken: TokenIdentity;
  userToken: any;

  constructor(
    public _headerService: BpHeaderService,
    // private tokenManager: TokenManager
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      // this.tokenManager.getIdentity().then((value: TokenIdentity) => {
      //   console.log('getIdentity in header', value);
      //   if (value) { this.userToken = value; }
      // });
    }, 1000);
  }

  logout() {
    // this.tokenManager.logout();
  }

}
