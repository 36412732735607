import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { kebabCase } from 'lodash';

@Component({
    selector: 'bpe-status',
    templateUrl: './bpe-status.component.html',
    styleUrls: ['./bpe-status.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class BpStatusComponent implements OnInit {
    @Input() status!: string;

    constructor() { }

    ngOnInit(): void { }

    kebabCase(x: string) {
        return kebabCase(x);
    }
}
