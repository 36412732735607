import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ClientLicenseManagementComponent } from './components/client-license-management/client-license-management.component';
import { ClientLicenseComponent } from './components/client-license/client-license.component';
import { AddEditClientComponent } from './components/add-edit-client/add-edit-client.component';
import { LandingComponent } from './components/landing/landing.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginGuard } from './services/guard/login.guard';



const routes: Routes = [
  {
    path: '',
    redirectTo: '/main',
    pathMatch: 'full'
  },
  {
    path: 'main',
    component: LandingComponent,
    canActivate: [ LoginGuard]
  },
  {
    path: 'license',
    component: ClientLicenseManagementComponent,
    canActivate: [ MsalGuard]
  },
  {
    path: 'license/client/:clientId',
    component: ClientLicenseComponent,
    canActivate: [ MsalGuard]
  },
  {
    path: 'license/create-client',
    component: AddEditClientComponent,
    canActivate: [ MsalGuard]
  },
  {
    path: '**',
    component: LandingComponent,
    canActivate: [ LoginGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
