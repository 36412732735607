<div class="custom-spinner-container">
  <div class="custom-spinner">
    <div class="spinner">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
      <div class="bar4"></div>
      <div class="bar5"></div>
    </div>
  </div>
</div>
