import { Directive, AfterViewInit, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { BpFieldControl, BpValidatorItem } from './bpFieldControl.class';

@Directive({
  selector: '[bpFieldControl]'
})
export class BpFieldControlDirective implements AfterViewInit, OnChanges {
  @Input() ngModel!: string | number | Date;
  @Input() bpFieldControl!: BpFieldControl|any;
  @Input() disabled!: boolean;

  bpFormField: any;
  bpErrorField: any;
  isInitialValidationDone = false;

  constructor(
    private _elementRef: ElementRef
  ) { }

  ngAfterViewInit() {
    this.bpFormField = this._elementRef.nativeElement.closest('.bp-form-field');
    if (!this.bpFormField) {
      throw new Error(`BpFieldControl: bp-form-field wrapper is missing to control the field. Please wrap your field with
                        a div element by adding the class name "bp-form-field" to have bpFieldControl working.`);
    }

    // Setting Untouched to parent and listening for focus.
    this.bpFormField.classList.add('bp-untouched');
    this._elementRef.nativeElement.onfocus = this.removeUntouched.bind(this);
    if (this.bpFieldControl.fieldGroup && !this.bpFieldControl.fieldGroup.shouldSubmitWithErrors) { this._elementRef.nativeElement.onkeydown = this.trySubmit.bind(this); }

    this.bpFieldControl._resetField.subscribe(() => {
      this.resetField();
    });

    this.bpFieldControl._setUntouched.subscribe(() => {
      this.setUntouched();
    });

    this.bpFieldControl._setDirty.subscribe(() => {
      this.removeUntouched();
    });

    this.bpFieldControl._validateField.subscribe(() => {
      this.validate();
    });

    this.bpErrorField = this._elementRef.nativeElement.closest('.bp-form-field').querySelector('.bp-field-error');

    if (!this.isInitialValidationDone) {
      this.validate();
      this.setDisableStatus();
    }
  }

  resetField() {
    this.ngModel = '';
    this.setUntouched();
  }

  setUntouched() {
    this.bpFormField.classList.add('bp-untouched');
    this._elementRef.nativeElement.onfocus = this.removeUntouched.bind(this);
  }

  removeUntouched() {
    this.bpFormField.classList.remove('bp-untouched');
    this._elementRef.nativeElement.onfocus = null;
  }

  trySubmit($event: any) {
    if ($event.which === 13 && this.bpFieldControl?.fieldGroup?.hasError) {
      $event.preventDefault();
      this.bpFieldControl.fieldGroup.setAllFieldsDirty();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Return if no "bp-form-field" wrapper is found.
    if (!this.bpFormField) { return; }

    if (changes.disabled && this.disabled !== undefined) {
      this.setDisableStatus();
      this.validate();
    } else if (changes.ngModel && this.ngModel !== undefined) {
      this.validate();
    }
  }

  setDisableStatus() {
    if (this.disabled) {
      this._elementRef.nativeElement.closest('.bp-form-field').classList.add('bp-disabled');
    } else {
      this._elementRef.nativeElement.closest('.bp-form-field').classList.remove('bp-disabled');
    }
  }

  validate() {
    if (this.bpFieldControl && this.disabled) { // Ignoring errors when field is disabled
      this.bpFieldControl.error = null;
      this.bpFieldControl.hasError = false;
      this.bpFieldControl.__setError();
    } else if (this.bpFieldControl) {
      this.isInitialValidationDone = true;
      this.bpFieldControl.error = null;
      if (this.bpFieldControl.validatorList && this.bpFieldControl.validatorList.length > 0) {
        this.bpFieldControl.validatorList.forEach((validatorItem: BpValidatorItem) => {
          const result = this.runTest(validatorItem.validator);
          if (result) {
            result.message = validatorItem.errorMsg;
            this.bpFieldControl.error = result;
          }
        });
      }
      this.bpFieldControl.hasError = this.bpFieldControl.error ? Object.keys(this.bpFieldControl.error).length > 0 : false;
      this.bpFieldControl.__setError();

      if (this.bpFieldControl.hasError) {
        this._elementRef.nativeElement.closest('.bp-form-field').classList.add('bp-invalid');
        if (this.bpErrorField) {
          this.bpErrorField.innerHTML = this.bpFieldControl.error.message;
        }
      } else {
        this._elementRef.nativeElement.closest('.bp-form-field').classList.remove('bp-invalid');
        if (this.bpErrorField) {
          this.bpErrorField.innerHTML = '';
        }
      }
    }
  }

  runTest(validator: any) {
    return validator({ value: this.ngModel });
  }
}

