import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IBpeGridColumn, IconRefEnum, DateTimeService } from 'library';
import { kebabCase } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/internal/Subscription';
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  IBondProLicenseSubscriptionRequestDto,
  INGLicenseStatusTypeCode,
  INGStatusTypeInstanceDto,
  INGSubscriptionInstanceDto,
  INGSubscriptionTypeInstanceDto,
  IViewClientLicenseInfo,
  INGClientStatusTypeId,
} from '../../../interfaces/license-interfaces';
import { CommonHelperService } from '../../../services/common-helper.service';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from '../../../services/format-datepicker.service';
import { LicenseService } from '../../../services/license.service';
import { MessageHelperService } from '../../../services/message-helper.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

dayjs.extend(utc)

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss'],
  providers: [
    // { provide: DateAdapter, useClass: AppDateAdapter },
    // { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class SubscriptionModalComponent implements OnInit, OnDestroy,AfterViewInit  {

  public license_Model!: IViewClientLicenseInfo;

  @Input('licenseModel')
  public set licenseModel(value: IViewClientLicenseInfo) {
      if (value) {
          this.license_Model = value;
        this.setModalValues()
      }
  }

  columnDefs: IBpeGridColumn[] = [];
  _subs = [] as Subscription[];

  subscriptionList!: Array<INGSubscriptionInstanceDto>;
  subscriptionTypeList!: Array<INGSubscriptionTypeInstanceDto>;
  statusTypeList!: Array<INGStatusTypeInstanceDto>;
  statusTypeListSubscriptionAddModal!: Array<INGStatusTypeInstanceDto>;
  formSubscription!: FormGroup;

  closeIcon = IconRefEnum.BpClose;
  addIcon = IconRefEnum.Add;
  searchIcon = IconRefEnum.BpSearch;
  isExpanded: boolean = true;
  expDateIsRequired: boolean = true;
  canDelete: boolean = true;
  isSelectable: boolean = true;
  filterCategoryList!: any[];
  pagination!: IBondProLicenseSubscriptionRequestDto;
  filterByModel: any;
  minDate: any;
  minEffectiveDate: any;
  maxEffectiveDate: any;
  maxExpirationDate: any;
  isReadOnly: boolean = false;
  loadingSubcriptions: boolean = true;
@ViewChild('myTemplate') private myTemplate!: TemplateRef<any>;
public myGridTemplate!: TemplateRef<any>;


  constructor(
    private dialogRef: MatDialogRef<SubscriptionModalComponent>,
    private formBuilder: FormBuilder,
    private licenseService: LicenseService,
    private _commonHelperService: CommonHelperService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private messageService: MessageHelperService,
    private dateTimeService: DateTimeService
  ) {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.defColumns();
    this.setPaginationValues();
    this.buildForm();
   // this.getSubcriptionType();
    this.getStatusType();
  }

  ngAfterViewInit()
  {
    setTimeout(() => {
      if(this.canDelete)
      {
        this.myGridTemplate = this.myTemplate;
      }
  }, 1000);
  }

  setModalValues()
  {
      if(this.license_Model.licenseStatusTypeName==="Expired" || this.license_Model.licenseStatusTypeName==="Disabled")
      {
        this.isReadOnly = true;
        this.canDelete = false;
      }

      if(this.license_Model.effectiveDate)
      {
        this.license_Model.effectiveDate = this.dateTimeService.toLocal(this.license_Model.effectiveDate);
      }

      if(this.license_Model.expirationDate)
      {
        this.license_Model.expirationDate = this.dateTimeService.toLocal(this.license_Model.expirationDate);
      }

      this.setDateRange()
      this.getSubcriptionType()
  }

  ngOnInit() {
    this.getSubscription();
    this.getDropdownValue();
  }



  setDateRange() {
    this.minEffectiveDate = this.license_Model.effectiveDate
    if (this.license_Model.expirationDate) {
      this.maxEffectiveDate = new Date(this.license_Model.expirationDate);
      this.maxEffectiveDate.setDate(this.maxEffectiveDate.getDate() - 1);
    }

    this.maxExpirationDate = this.license_Model.expirationDate

    this.setNeverExpiresControl()
  }

  setNeverExpiresControl() {
    if (this.license_Model.expirationDate) {
      this.formSubscription.controls.neverExpires.setValue(false)
      this.formSubscription.controls.neverExpires.disable()
    }
  }

  setPaginationValues(searchTerm?: any) {
    this.pagination = {
      page: 1,
      pageSize: 5,
      sortColumn: 'licenseSubscriptionTypeId',
      direction: 'desc',
      ...searchTerm,
    };
  }

  defColumns() {
    this.columnDefs = [
      { title: 'Subscription Id', field: 'licenseSubscriptionTypeId' },
      { title: 'Subscription Type', field: 'subscriptionName' },
      {
        title: 'Effective Date', field: 'effectiveDate', bodyCellTemplate: (col, row) => {

          return this.dateTimeService.toLocalTime(row.effectiveDate, false)
        }
      },
      {
        title: 'Expiration Date', field: 'expirationDate', bodyCellTemplate: (col, row) => {

          return row.expirationDate ? this.dateTimeService.toLocalTime(row.expirationDate, false) : 'Never Expires'
        }
      },
      {
        title: 'Last Modified Data/Time', field: 'modifyDate', bodyCellTemplate: (col, row) => {

          return this.dateTimeService.toLocalTime(row.modifyDate, true)
        }
      },
      { title: 'Last Modified By', field: 'modifiedBy' },
      {
        title: 'Status', field: 'subscriptionStatus', bodyCellTemplate: (col, row) => {

          return `<span class="bpe-status bpe-status-${this.kebabCase(row.subscriptionStatus)}">
                    <span>${row.subscriptionStatus}</span>
                  </span>`
        }
      },
    ];
  }

  kebabCase(x: string) {
    return kebabCase(x);
  }

  buildForm(): void {
    this.formSubscription = this.formBuilder.group({
      licenseSubscriptionTypeId: [null],
      subscriptionTypeId: [null],
      effectiveDate: [null, [Validators.required]],
      expirationDate: [null, [Validators.required]],
      statusType: [null],
      neverExpires: [false],
    });
  }

  onDateValueChanged() {
    const dateSeleted = new Date(this.formSubscription.controls.effectiveDate.value);
    const currentDate = new Date();
    dateSeleted.setDate(dateSeleted.getDate() + 1);
    this.minDate = dateSeleted.getTime() > currentDate.getTime() ? dateSeleted : this.minDate;
  }

   getSubcriptionType(): void {
    const ACTIVE_STATUS_TYPE_ID = 1
    this._subs.push(
      this.licenseService.getSubscriptionByLicenseTypeId(this.license_Model?.licenseTypeId).subscribe((n) => {
        this.subscriptionTypeList = n.filter((l) => l.statusTypeId === ACTIVE_STATUS_TYPE_ID).sort((a, b) => a.subscriptionName.localeCompare(b.subscriptionName));
      })
    );

/*     this._subs.push(
      this.licenseService.getNGSubscriptionTypes().subscribe((n) => {
        this.subscriptionTypeList = n;
      })
    ); */
  }

  getStatusType(): void {
    this._subs.push(
      this.licenseService.getNGStatusTypes().subscribe((statusList) => {
        this.statusTypeList = statusList;

        this.statusTypeListSubscriptionAddModal = this.statusTypeList.filter((item) => item.statusTypeCode !== INGLicenseStatusTypeCode.EXPIRED
        && item.statusTypeCode !== INGLicenseStatusTypeCode.DISABLED && item.statusTypeCode !== INGLicenseStatusTypeCode.PENDING_ACTIVATION);

        const allItem: any =
        {
          statusDescription: 'All',
          statusName: 'All',
          statusTypeCode: 'All',
          statusTypeId: 0,
        }
        this.statusTypeList.splice(0, 0, allItem)
        const defaultStatus = this.statusTypeList.find((item) => item.statusName === 'Pending');
        this.formSubscription.controls.statusType.setValue(defaultStatus);
      })
    );
  }


  onPageChange(event: any) {
    this.pagination.page = event.page
    this.pagination.pageSize = event.pageSize
    this.getSubscription()
  }

  onSort(event: any) {
    this.pagination.direction = event.direction
    this.pagination.sortColumn = event.sortColumn
    this.getSubscription()
  }

  getSubscription(): void {
    const licenseId = this.license_Model.licenseId;
    const requestDto = {
      licenseId,
      ...this.pagination
    } as IBondProLicenseSubscriptionRequestDto;
    this.loadingSubcriptions = true
    this._subs.push(
      this.licenseService.getNGSubscription(requestDto).subscribe((n) => {
        this.subscriptionList = n.items;
        this.pagination.totalRecords = n.totalRecords;
        this.loadingSubcriptions = false
      })
    );
  }

  removeSubscription(subscriptionId: number, modifiedBy?: string) {
    this._subs.push(
      this.licenseService
        .deleteNGSubscription(subscriptionId, modifiedBy)
        .subscribe((n) => {
          if (n.success) {
            this.toastr.success('Has been deleted successfully', 'Success');
            this.formSubscription.get('expirationDate')?.enable();
            this.formSubscription.reset();
            this.expDateIsRequired = true;
            this.getSubscription();
          } else {
            const messageModel = {
              title: 'Remove Subscription',
              message: 'Unable to remove this subscription due to the following:'
            }
            this.messageService.showErrorModal(messageModel, n.errorMessages);
          }
        })
    );
  }

  onSave(): void {
    const subscriptionType = this.formSubscription.controls.subscriptionTypeId.value;
    const statusType = this.formSubscription.controls.statusType?.value;
    const subscriptionModel: INGSubscriptionInstanceDto = {
      licenseId: this.license_Model.licenseId,
      subscriptionTypeId: subscriptionType.subscriptionTypeId,
      statusTypeId: statusType?.statusTypeId,
      effectiveDate: this.formSubscription.controls.effectiveDate.value,
      subscriptionName: this.getSubscriptionTypeName(subscriptionType.subscriptionTypeId),
    };

    if (!statusType) {
      const statusTypeId = this.statusTypeList.find(item => item.statusTypeCode === 'PENDING')?.statusTypeId;
      if (statusTypeId) {
        subscriptionModel.statusTypeId = statusTypeId;
      }
    }
    if (this.formSubscription.controls.expirationDate.value) {
      subscriptionModel.expirationDate = this.formSubscription.controls.expirationDate.value
    }
    if (subscriptionModel.effectiveDate) {
      subscriptionModel.effectiveDate = this.isMomentObject(subscriptionModel.effectiveDate) ? this.toDate(subscriptionModel.effectiveDate)  : subscriptionModel.effectiveDate;
    }
    if (subscriptionModel.expirationDate) {
      subscriptionModel.expirationDate = this.isMomentObject(subscriptionModel.expirationDate) ? this.toDate(subscriptionModel.expirationDate) : subscriptionModel.expirationDate;
    }
    subscriptionModel.statusTypeName = this.getStatusTypeName(subscriptionModel.statusTypeId);
    if (this.formSubscription.controls.licenseSubscriptionTypeId.value) {
      subscriptionModel.licenseSubscriptionTypeId = this.formSubscription.controls.licenseSubscriptionTypeId.value;
      this.updateSubscription(subscriptionModel);
    } else {
      if (!subscriptionModel.statusTypeId) {
        subscriptionModel.statusTypeId = INGClientStatusTypeId.PENDING;
      }
      this.createSubscription(subscriptionModel, subscriptionType);
    }
  }

  getSubscriptionTypeName(subscriptionTypeId: number | undefined) {
    const foundItem = (this.subscriptionTypeList || []).find((item) => item.subscriptionTypeId == subscriptionTypeId);
    return foundItem ? foundItem.subscriptionName : void 0;
  }

  getStatusTypeName(statusTypeId: number | undefined) {
    const foundItem = (this.statusTypeList || []).find((item) => item.statusTypeId == statusTypeId);
    return foundItem ? foundItem.statusName : void 0;
  }

  isMomentObject(date: any) {
    return date._isAMomentObject
  }

  toDate(momentDate: any) {
    return momentDate.toDate()
  }

  formatDate(dateChange: any) {
    const modelDate = new Date(dateChange).toString();
    return this._commonHelperService.changeDateFormat(modelDate, 'yyyy-MM-dd');
  }

  createSubscription(item: INGSubscriptionInstanceDto, subscriptionTypeIds: number[]) {
    if (Array.isArray(subscriptionTypeIds)) {
      item.subscriptionTypes = subscriptionTypeIds.map((subscriptionTypeId) => ({
        subscriptionTypeId: subscriptionTypeId,
        subscriptionName: this.getSubscriptionTypeName(subscriptionTypeId) as string,
      }));
    }

    this._subs.push(
      this.licenseService.createNGSubscription(item).subscribe((response) => {
        if (response.success) {
          this.toastr.success('Has been saved successfully', 'Success');
          this.formSubscription.get('expirationDate')?.enable();
          this.formSubscription.reset();
          this.expDateIsRequired = true;
          this.getSubscription();
        } else {
          const messageModel = {
            title: 'Save Subscription',
            message: 'Unable to create this subscription due to the following:'
          }
          this.messageService.showErrorModal(messageModel, response.errorMessages);
        }
      })
    );
  }

  updateSubscription(item: any) {
    this._subs.push(
      this.licenseService.updateNGSubscription(item).subscribe((response) => {
        if (response.success) {
          this.toastr.success('Has been updated successfully', 'Success');
          this.formSubscription.get('expirationDate')?.enable();
          this.formSubscription.reset();
          this.expDateIsRequired = true;
          this.getSubscription();
        } else {
          const messageModel = {
            title: 'Update Subscription',
            message: 'Unable to updated this subscription due to the following:'
          }
          this.messageService.showErrorModal(messageModel, response.errorMessages);
        }
      })
    );
  }

  onCloseModal(): void {
    this.dialogRef.close();
  }

  onChangeExpireCheck(data: any) {
    this.expDateIsRequired = !data.checked;
    if (this.expDateIsRequired) {
      this.formSubscription.get('expirationDate')?.enable();
    } else {
      this.formSubscription.get('expirationDate')?.reset();
      this.formSubscription.get('expirationDate')?.disable();
    }
  }

  onDeleteRow(data: any) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent);
    dialogRef.componentInstance.title = 'Remove Customer';
    dialogRef.componentInstance.message =
      'Are you sure you want to remove this subscription?';
    dialogRef.componentInstance.messageIcon = IconRefEnum.BpWarning;
    dialogRef.componentInstance.cancelBtn = 'Cancel';
    dialogRef.componentInstance.acceptBtn = 'Confirm';
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.removeSubscription(data.licenseSubscriptionTypeId, data.modifiedBy);
      }
    });
  }

  onSelectionChange(data: any) {
    if (data.selectedRows.length) {
      const rowData = data.selectedRows[0].dataItem;
      this.setFormValues(rowData);
    } else {
      this.formSubscription.reset();
      this.formSubscription.get('expirationDate')?.enable();
      this.expDateIsRequired = true;
    }
  }

  setFormValues(rowData: INGSubscriptionInstanceDto) {
    this.formSubscription.reset();
    this.formSubscription.enable();

    this.formSubscription.controls.effectiveDate.setValue(dayjs.utc(rowData.effectiveDate).local().toDate());
    if (rowData.expirationDate) {
      this.formSubscription.controls.expirationDate.setValue(dayjs.utc(rowData.expirationDate).local().toDate());
      this.formSubscription.get('expirationDate')?.enable();
      this.expDateIsRequired = true;
    } else {
      this.formSubscription.controls.neverExpires.setValue(true);
      this.formSubscription.get('expirationDate')?.disable();
      this.expDateIsRequired = false;
    }

    const substype = this.subscriptionTypeList.find((item) => item.subscriptionName === rowData.subscriptionName);
    const statustype = this.statusTypeList.find((item) => item.statusName === rowData.subscriptionStatus);

    if(statustype?.statusTypeCode === INGLicenseStatusTypeCode.EXPIRED || statustype?.statusTypeCode === INGLicenseStatusTypeCode.PENDING_ACTIVATION || statustype?.statusTypeCode === INGLicenseStatusTypeCode.DISABLED)
    {
      this.formSubscription.disable();
      this.statusTypeListSubscriptionAddModal  = this.statusTypeList;
    }
    else
    {
      this.statusTypeListSubscriptionAddModal  = this.statusTypeListSubscriptionAddModal .filter(x => x.statusTypeCode !== 'EXPIRED' && x.statusTypeCode !== 'PENDING_ACTIVATION'&& x.statusTypeCode !== 'DISABLED' )
    }

    // Remove All Item
    this.statusTypeListSubscriptionAddModal  = this.statusTypeListSubscriptionAddModal .filter(x => x.statusTypeId !== 0 )

    this.setNeverExpiresControl()

    this.formSubscription.controls.subscriptionTypeId.setValue(substype);
    this.formSubscription.controls.statusType.setValue(statustype);
    this.formSubscription.controls.licenseSubscriptionTypeId.setValue(rowData.licenseSubscriptionTypeId);
    this.onDateValueChanged()
  }

  getDropdownValue() {
    this.filterCategoryList = [
      {
        label: 'Subscription Type',
        key: 'subscriptionName',
      },
      {
        label: 'Status',
        key: 'subscriptionStatus'
      },
    ];
  }

  onChangeFilter(searchModel: any) {
    const searchTerm: any = {};
    if (searchModel.search) {
      searchTerm.licenseSubscriptionTypeId = searchModel.search;
    }
    if (searchModel.filterBy !== 'All') {
      searchTerm[searchModel.category] = searchModel.filterBy;
    }
    this.setPaginationValues(searchTerm);
    this.getSubscription()
  }

  onChangeCategory(field: any) {
    if (field === 'subscriptionName') {
      this.filterByModel = {
        list: this.subscriptionTypeList,
        label: 'subscriptionName',
        key: 'subscriptionName',
      };
    }
    if (field === 'subscriptionStatus') {
      this.filterByModel = {
        list: this.statusTypeList,
        label: 'statusDescription',
        key: 'statusDescription',
      };
    }
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }
}
