<div id="ng_license">
  <app-sidebar></app-sidebar>
  <div class="body-container">
    <div class="user-content user-content__logout" *ngIf="userName">
      <fa-icon [icon]="versionIcon" class="version-icon" kendoTooltip position="bottom" [title]="appVersion"></fa-icon>
      <span class="username" [innerHtml]="userName"></span>
      <span class="logout" (click)="onLogout()">Logout</span>
    </div>
    <div class="user-content" *ngIf="!userName">
      <span class="logout" (click)="onLogin()">Login</span>
    </div>
    <bp-tabs-bar [tabsList]="_routeTabsService.routeTabsList"></bp-tabs-bar>
    <div class="page-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
