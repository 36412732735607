import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBpeGridColumn, IconRefEnum, DateTimeService } from 'library';
import {
  IBondProNGCarrierClientDto,
  INGCarrierClientInstanceDto,
  IServiceResponseDto,
} from '../../interfaces/license-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { AddTenantModalComponent } from '../dialog/add-tenant-modal/add-tenant-modal.component';
import { LicenseService } from '../../services/license.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationModalComponent } from '../dialog/confirmation-modal/confirmation-modal.component';
import { ActivityHistoryDialogComponent } from '../activity-history/activity-history-dialog/activity-history-dialog.component';
import { ActivityHistoryService, ActivityKey, SearchCriteriaOperator, SearchOperator } from '../../services/activity-history.service';
@Component({
  selector: 'app-tenant-details',
  templateUrl: './tenant-details.component.html',
  styleUrls: ['./tenant-details.component.scss'],
})
export class TenantDetailsComponent implements OnInit {
  @Output() reloadTenantList = new EventEmitter();
  @Output() pageChange = new EventEmitter();
  @Output() sortChange = new EventEmitter();
  @Input() disabled!: boolean;
  @Input() pagination!: any;
  @Input() clientModel!: IBondProNGCarrierClientDto;

  public _clientInstances: INGCarrierClientInstanceDto[] = [];
  public columnDefs: IBpeGridColumn[] = [];
  private _subs = [] as Subscription[];
  addIcon = IconRefEnum.Add;
  showViewMore: boolean = true;
  clientId?: number;

  @Input() public set instancesModel(value: any) {
    this._clientInstances = value;
  }

  activityIcon = IconRefEnum.History;

  constructor(
    private licenseService: LicenseService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private dateTimeService: DateTimeService,
    private activityHistoryService: ActivityHistoryService,
  ) { }

  ngOnInit() {
    this._subs.push(
      this.route.params.subscribe((params) => {
        this.clientId = params.clientId;
      })
    );
    this.defColumns();
  }

  onChangeFilter(searchModel: any) {
    const searchTerm: any = {};
    if (searchModel.search) {
      searchTerm.dbCorporationId = searchModel.search;
    }

    this.reloadTenantList.emit(searchTerm);
  }

  defColumns() {
    this.columnDefs = [
      { title: 'Tenant Id', field: 'dbCorporationId' },
      { title: 'Database Server Name', field: 'databaseServerName' },
      { title: 'Database Name', field: 'databaseName' },
      { title: 'Database Server IPAddress', field: 'databaseServerIPAddress' },
      { title: 'Database Version', field: 'dbVersion' },
      { title: 'Sub Source', field: 'subSource' },
      { title: 'AppVersion', field: 'appVersion' },
      {
        title: 'LAST MODIFIED DATETIME', field: 'modifyDate', bodyCellTemplate: (col, row) => {

          return this.dateTimeService.toLocalTime(row.modifyDate, true)
        }
      },
      { title: 'LAST MODIFIED BY', field: 'modifiedBy' },
    ];
  }

  onAddEditInstance(value?: INGCarrierClientInstanceDto) {
    const dialogRef = this.dialog.open(AddTenantModalComponent, {
      width: '800px',
      disableClose: true,
      backdropClass: 'custom-dialog-backdrop-class',
      panelClass: 'custom-dialog-panel-class',
    });

    if (value) {
      const appSettingUrlsDeserealized: any = JSON.parse(value.urlSettings)
      value.appSettingUrls = {
        b2BApplicationURL: appSettingUrlsDeserealized?.B2BApplicationURL,
        b2BAuthenticationTypeCode: appSettingUrlsDeserealized?.B2BAuthenticationTypeCode,
        b2BAuthenticationTypeName: appSettingUrlsDeserealized?.B2BAuthenticationTypeName,
        b2BAuthenticationURL: appSettingUrlsDeserealized?.B2BAuthenticationURL,
        b2BServiceURL: appSettingUrlsDeserealized?.B2BServiceURL,
        portalApplicationURL: appSettingUrlsDeserealized?.PortalApplicationURL,
        portalAuthenticationTypeCode: appSettingUrlsDeserealized?.PortalAuthenticationTypeCode,
        portalAuthenticationTypeName: appSettingUrlsDeserealized?.PortalAuthenticationTypeName,
        portalAuthenticationURL:appSettingUrlsDeserealized?.PortalAuthenticationURL,
        portalServiceURL: appSettingUrlsDeserealized?.PortalServiceURL,
        fDApplicationURL: appSettingUrlsDeserealized?.FDApplicationURL,
        fDAuthenticationTypeCode: appSettingUrlsDeserealized?.FDAuthenticationTypeCode,
        fDAuthenticationTypeName: appSettingUrlsDeserealized?.FDAuthenticationTypeName,
        fDAuthenticationURL: appSettingUrlsDeserealized?.FDAuthenticationURL,
        fDServiceURL: appSettingUrlsDeserealized?.FDServiceURL,
      }
      dialogRef.componentInstance.instanceModal = value;
      dialogRef.componentInstance.clientModel = this.clientModel;
    }
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      const instanceModel: INGCarrierClientInstanceDto = result.data;
      instanceModel.ngCarrierClientId = this.clientId;
      value ? this.updateClient(instanceModel, value.ngCarrierClientInstanceId) : this.createClient(instanceModel);
    });
  }

  createClient(instanceModel: INGCarrierClientInstanceDto) {
    this._subs.push(
      this.licenseService
        .createNGInstance(instanceModel)
        .subscribe((response: IServiceResponseDto) => {
          if (response.success) {
            this.toastr.success('The Instance has been created!', 'Success');
            this.reloadTenantList.emit();
          } else {
            this.toastr.error(
              response?.errorMessages[0] || 'There was an error, please try again',
              'Error'
            );
          }
        })
    );
  }

  updateClient(instanceModel: INGCarrierClientInstanceDto, instanceId: any) {
    instanceModel.ngCarrierClientInstanceId = instanceId;
    this._subs.push(
      this.licenseService
        .updateNGInstance(instanceModel)
        .subscribe((response: IServiceResponseDto) => {
          if (response.success) {
            this.toastr.success('The Instance has been updated!', 'Success');
            this.reloadTenantList.emit();
          } else {
            this.toastr.error(
              response?.errorMessages[0] || 'There was an error, please try again',
              'Error'
            );
          }
        })
    );
  }

  onRemove(value: INGCarrierClientInstanceDto) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent);
    dialogRef.componentInstance.title = 'Remove Tenant';
    dialogRef.componentInstance.message =
      'Are you sure you want to remove this Tenant Instance?';
    dialogRef.componentInstance.messageDetails = [
      'All licenses and subscriptions will also be removed.',
    ];
    dialogRef.componentInstance.messageIcon = IconRefEnum.BpWarning;
    dialogRef.componentInstance.cancelBtn = 'Cancel';
    dialogRef.componentInstance.acceptBtn = 'Confirm';
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && value.ngCarrierClientInstanceId) {
        this.removeTenant(value.ngCarrierClientInstanceId, value.modifiedBy);
      }
    });
  }

  removeTenant(tenantId: number, modifiedBy?: string) {
    this._subs.push(
      this.licenseService.deleteNGInstance(tenantId, modifiedBy).subscribe((n) => {
        if (n.success) {
          this.toastr.success('Has been deleted successfully', 'Success');
          this.reloadTenantList.emit();
        } else {
          this.toastr.error( n.errorMessages[0] || 'Error deleting client Instance');
        }
      })
    );
  }

  onPageChange(event: any) {
    this.pageChange.emit(event)
  }

  onSortChange(event: any) {
    this.sortChange.emit(event)
  }

  showActivity() {
    const dialogRef = this.dialog.open(ActivityHistoryDialogComponent, {
      maxHeight: '100vh'
    });
    dialogRef.componentInstance.classification = 'ClientInstance';
    dialogRef.componentInstance.extraColumns = [
      {
        field: 'TenantId',
        title: 'Tenant ID',
      },
      {
        field: 'DataBaseName',
        title: 'DataBase Name',
      }
    ]
    dialogRef.componentInstance.extraDetailHeaders = [
      {
        key: 'TenantId',
        label: 'Tenant ID'
      },
      {
        key: 'DataBaseName',
        label: 'DataBase Name'
      },
    ];
    dialogRef.componentInstance.extraGridDetailHeaders = [
      {
        value: this.clientModel.ngCarrierClientId,
        label: 'Client ID'
      },
      {
        value: this.clientModel.clientName,
        label: 'Client Name'
      },
    ];
    dialogRef.componentInstance.diffConfig = {
      historicalFields: ['CreatedBy', 'CreateDate', 'ModifyDate', 'ModifiedBy'],
      onlyDateFields: [],
      customPropertyFormatters: {},
      rootPropertySection: 'Instance Section',
    }
    dialogRef.componentInstance.extraSearchCriterias = this.activityHistoryService.buildCriteriaFilter(
      'ClientId',
      this.clientModel.ngCarrierClientId
    )
    dialogRef.componentInstance.filterConfig = {
      enabled: true,
      placeholder: 'Search by Tenant ID or Description',
      buildSearchCriterias: (searchValue) => {
        let criterias: any[] = [];
        if (!searchValue) {
          return criterias;
        }
        const descriptionCriteria = {
          Operator: SearchOperator.OR,
          Criteria: {
            Operator: SearchCriteriaOperator.EQ,
            Key: ActivityKey.Description,
            Value: searchValue,
          },
        };
        const inputSearchCriteria = this.activityHistoryService.buildInputCriteria(
          ['TenantId'],
          searchValue,
          [descriptionCriteria]
        );
        criterias.push(inputSearchCriteria);
        return criterias;
      }
    }
    dialogRef.afterClosed().subscribe((statusTypeId: any) => {
      console.log('status type id', statusTypeId)
      if (statusTypeId) {}
    });
  }
}
