import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { RouteTabsService } from './services/route-tabs.service';
import { LicenseService } from './services/license.service';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { UserService } from './services/user.service';
import { ConfigurationService } from './services/configuration-service.service';
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loginSubscription!: Subscription;
  userName!: string;
  versionIcon = faCodeBranch;
  appVersion!: string;

  constructor(
    public _routeTabsService: RouteTabsService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private router: Router,
    private userService: UserService,
    private configService: ConfigurationService,
  ) { }

  ngOnInit() {
    this.userName = this.userService.currentUserValue
    this.onListenEventMsl()
    this.onListeLoginMsl()
    this.configService.getBuildNumber()
      .subscribe((buildInfo) => {
        this.appVersion = `Version ${buildInfo.appVersion}${buildInfo.appBuild ? '.' + buildInfo.appBuild : ''}`
      })
  }

  onListenEventMsl() {
    this.loginSubscription = this.msalBroadcastService.msalSubject$
      .pipe()
      .subscribe((result: any) => {
        if (result.eventType === 'msal:loginSuccess') {
          this.userName = this.authService.instance.getAllAccounts()[0]?.username
          this.router.navigateByUrl(`license`);
        }
      });
  }

  onListeLoginMsl() {
    this.msalBroadcastService.msalSubject$
    .pipe(
       filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS)
        ).subscribe((result: EventMessage) => {
           const payload = result.payload as AuthenticationResult;
           this.authService.instance.setActiveAccount(payload.account);
           this.userName = this.userService.currentUserValue
     });
  }

  onLogin() {
    this.authService.loginRedirect();
    // this.router.navigateByUrl(`license`);
  }

  onLogout() {
    this.authService.logoutRedirect();
  }

  ngOnDestroy(): void {
    this.loginSubscription.unsubscribe()
  }
}

