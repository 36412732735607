<div class="license-filter">
  <app-filter-grid
    [searchPlaceholder]="'Search by License Type or Tenant ID'"
    [categoryList]="filterCategoryList"
    [filterByModel]="filterByModel"
    (changeCategory)="onChangeCategory($event)"
    (changeFilter)="onChangeFilter($event)">
  </app-filter-grid>
  <div class="options">
    <span (click)="showActivity()" class="activity-icon" [bpMatIcon]="activityIcon"></span>
    <button type="button" class="primary md bp-icon-button align-right"
      [disabled]="disabled" (click)="onAddLicense()">
      <span class="btn-icon" [bpMatIcon]="addIcon"></span>
      <span class="btn-text">Add</span>
    </button>
    <button type="button" class="primary md align-right" [disabled]="disabled || !licenseSelected?.licenseId"
      (click)="onSubscription()">
      <span class="btn-text">Subscription</span>
    </button>
  </div>
</div>
