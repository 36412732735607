import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { IconRefEnum } from 'library';

@Component({
  selector: 'app-activity-history-diff-grid',
  templateUrl: './activity-history-diff-grid.component.html',
  styleUrls: ['./activity-history-diff-grid.component.scss'],
})
export class ActivityHistoryDiffGridComponent implements OnInit, OnDestroy, OnChanges {

    @Input() differenceSections: any;
    @Input() headerLabels: any;

    @Output() onSectionSelected = new EventEmitter<any>();

    collapsedSection: any = {}

    eyeIcon = 'remove_red_eye';

    constructor(
    ) {
    }

    ngOnInit() {
        this.collapsedSection = {}
        this.updateCollapsedSection()
    }

    ngOnDestroy() { }

    ngOnChanges(): void {
        this.updateCollapsedSection()
    }

    updateCollapsedSection() {
        this.collapsedSection = {}
        this.differenceSections.forEach((diffSection: any) => {
          this.collapsedSection[`${diffSection.sectionName}`] = true
        })
    }

    showMoreDetails(diffSection: any) {
        this.onSectionSelected.emit(diffSection)
    }
}
