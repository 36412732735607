import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBpeGridColumn, IconRefEnum, DateTimeService } from 'library';
import {
    BondProUpdateClientRelationStatusRequestDto,
  IBondProNGCarrierClientDto,
  INGCarrierClientRelationshipModel,
  INGStatusTypeInstanceDto,
  RemoveBusinessToBusinessRequestDto,
} from '../../interfaces/license-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { LicenseService } from '../../services/license.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { kebabCase } from 'lodash';
import { ConfirmationModalComponent } from '../dialog/confirmation-modal/confirmation-modal.component';
import { ActivityHistoryDialogComponent } from '../activity-history/activity-history-dialog/activity-history-dialog.component';
import { ActivityHistoryService, ActivityKey, SearchCriteriaOperator, SearchOperator } from '../../services/activity-history.service';
import { AddRelationshipModalComponent } from '../dialog/add-relationship-modal/add-relationship-modal.component';
import { UpdateStatusModalComponent } from '../dialog/update-status-modal/update-status-modal.component';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-relationship-details',
  templateUrl: './relationship-details.component.html',
  styleUrls: ['./relationship-details.component.scss'],
})
export class RelationshipDetailsComponent implements OnInit {
  @Output() reloadRelationshipsList = new EventEmitter();
  @Output() pageChange = new EventEmitter();
  @Output() sortChange = new EventEmitter();
  @Input() disabled!: boolean;
  @Input() pagination!: any;
  @Input() clientModel!: IBondProNGCarrierClientDto;
  @Input() statusList!: INGStatusTypeInstanceDto[];

  public _relationships: INGCarrierClientRelationshipModel[] = [];
  public columnDefs: IBpeGridColumn[] = [];
  private _subs = [] as Subscription[];
  addIcon = IconRefEnum.Add;
  showViewMore: boolean = true;
  clientId?: number;

  @Input() public set relationships(value: any) {
    this._relationships = value;
  }

  activityIcon = IconRefEnum.History;

  constructor(
    private licenseService: LicenseService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private dateTimeService: DateTimeService,
    private activityHistoryService: ActivityHistoryService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this._subs.push(
      this.route.params.subscribe((params) => {
        this.clientId = params.clientId;
      })
    );
    this.defColumns();
  }

  onChangeFilter(searchModel: any) {
    const searchTerm: any = {};
    if (searchModel.search) {
      searchTerm.clientRelationName = searchModel.search;
    }

    this.reloadRelationshipsList.emit(searchTerm);
  }

  defColumns() {
    this.columnDefs = [
      { title: 'Customer Name', field: 'clientRelationName' },
      { title: 'CUSTOMER TYPE', field: 'customerTypeRelationName' },
      {
        title: 'LAST MODIFIED DATE/TIME', field: 'modifyDate', bodyCellTemplate: (col, row) => {

          return this.dateTimeService.toLocalTime(row.modifyDate, true)
        }
      },
      { title: 'LAST MODIFIED BY', field: 'modifiedBy' },
      {
        title: 'STATUS', field: 'statusName', bodyCellTemplate: (col, row) => {

          return `<span class="bpe-status bpe-status-${this.kebabCase(row.statusName)}">
                    <span>${row.statusName}</span>
                  </span>`
        }
      },
    ];
  }

  kebabCase(x: string) {
    return kebabCase(x);
  }

  onEditRelationship() {
    const dialogRef = this.dialog.open(AddRelationshipModalComponent, {
      width: '950px',
    });
    dialogRef.componentInstance.clientModel = this.clientModel;
    dialogRef.componentInstance.relationships = this._relationships;

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.reloadRelationshipsList.emit();
    });
  }

  onAddRelationships() {
    const dialogRef = this.dialog.open(AddRelationshipModalComponent, {
        width: '950px',
      });
    dialogRef.componentInstance.clientModel = this.clientModel;
    dialogRef.componentInstance.relationships = this._relationships;

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.reloadRelationshipsList.emit();
    });
  }

  onModifyStatus(item: INGCarrierClientRelationshipModel) {
    if (!this.statusList) return;
    const dialogRef = this.dialog.open(UpdateStatusModalComponent);
    const ACTIVE_STATUS_ID = 1;
    const INACTIVE_STATUS_ID = 2;
    let arrayAvailableStatuses = [ACTIVE_STATUS_ID, INACTIVE_STATUS_ID];
    arrayAvailableStatuses = arrayAvailableStatuses.filter(x=> x !== item.statusTypeId);
    dialogRef.componentInstance.statusTypeList = this.getAvailableStatus(arrayAvailableStatuses);
    dialogRef.afterClosed().subscribe((statusTypeId: any) => {
      if (statusTypeId) {
        this.updateRelationshipStatus(statusTypeId, item);
      }
    });
  }

  updateRelationshipStatus(statusTypeId: number, item: INGCarrierClientRelationshipModel) {
    const request: BondProUpdateClientRelationStatusRequestDto = {
        modifiedBy: this.userService.currentUserValue || '',
        statusTypeId,
        statusTypeName: this.getStatusTypeName(statusTypeId) || '',
        nGCarrierClientNameRelation: item.clientRelationName,
        nGCarrierClientBusinessToBusinessId: item.ngCarrierClientBusinessToBusinessId,
        nGCarrierClientName: item.clientName,
    };
    this._subs.push(
      this.licenseService.updateBusinessToBusinessStatus(request).subscribe((n) => {
        if (n.success) {
          this.toastr.success(
            'The status has been updated successfully',
            'Success'
          );
          this.reloadRelationshipsList.emit();
        } else {
          this.toastr.error(n.errorMessages[0] || 'Error deleting client Instance');
        }
      })
    );
  }

  
  getStatusTypeName(statusTypeId: number | undefined) {
    const foundItem = (this.statusList || []).find((item) => item.statusTypeId === statusTypeId);
    return foundItem ? foundItem.statusName : void 0;
  }

  getAvailableStatus(status: any) {
    return this.statusList.filter(
        (item) => {
         return status.includes(item.statusTypeId)
       }
    );
  }

  onRemove(value: INGCarrierClientRelationshipModel) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent);
    dialogRef.componentInstance.title = 'Remove Relationship';
    dialogRef.componentInstance.message =
      'Are you sure you want to remove this Relationship?';
    dialogRef.componentInstance.messageIcon = IconRefEnum.BpWarning;
    dialogRef.componentInstance.cancelBtn = 'Cancel';
    dialogRef.componentInstance.acceptBtn = 'Confirm';
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && value.ngCarrierClientBusinessToBusinessId) {
        this.removeTenant(value.ngCarrierClientBusinessToBusinessId, value.modifiedBy);
      }
    });
  }

  removeTenant(ngCarrierClientBusinessToBusinessId: number, modifiedBy: string) {
    const request: RemoveBusinessToBusinessRequestDto = {
        modifiedBy,
        nGCarrierClientBusinessToBusinessID: ngCarrierClientBusinessToBusinessId,
    }
    this._subs.push(
      this.licenseService.removeBusinessToBusiness(request).subscribe((n) => {
        if (n.success) {
          this.toastr.success('Relationship has been deleted successfully', 'Success');
          this.reloadRelationshipsList.emit();
        } else {
          this.toastr.error( n.errorMessages[0] || 'Error deleting client Instance');
        }
      })
    );
  }

  onPageChange(event: any) {
    this.pageChange.emit(event)
  }

  onSortChange(event: any) {
    this.sortChange.emit(event)
  }

  showActivity() {
    const dialogRef = this.dialog.open(ActivityHistoryDialogComponent, {
      maxHeight: '100vh'
    });
    dialogRef.componentInstance.classification = 'ClientBusinessToBusiness';
    dialogRef.componentInstance.extraColumns = [
      {
        field: 'NGCarrierClientNameRelation',
        title: 'Customer Name',
      },
    ]
    dialogRef.componentInstance.extraDetailHeaders = [
      {
        key: 'NGCarrierClientNameRelation',
        label: 'Customer Name'
      },
    ];
    dialogRef.componentInstance.extraGridDetailHeaders = [
      {
        value: this.clientModel.ngCarrierClientId,
        label: 'Client ID'
      },
      {
        value: this.clientModel.clientName,
        label: 'Client Name'
      },
    ];
    dialogRef.componentInstance.diffConfig = {
      historicalFields: ['CreatedBy', 'CreateDate', 'ModifyDate', 'ModifiedBy'],
      onlyDateFields: [],
      customPropertyFormatters: {},
      rootPropertySection: 'Business To Business Section',
    }
    dialogRef.componentInstance.extraSearchCriterias = this.activityHistoryService.buildCriteriaFilter(
      'ClientId',
      this.clientModel.ngCarrierClientId
    )
    dialogRef.componentInstance.filterConfig = {
      enabled: true,
      placeholder: 'Search by Customer Name or Description',
      buildSearchCriterias: (searchValue) => {
        let criterias: any[] = [];
        if (!searchValue) {
          return criterias;
        }
        const descriptionCriteria = {
          Operator: SearchOperator.OR,
          Criteria: {
            Operator: SearchCriteriaOperator.EQ,
            Key: ActivityKey.Description,
            Value: searchValue,
          },
        };
        const inputSearchCriteria = this.activityHistoryService.buildInputCriteria(
          ['NGCarrierClientNameRelation'],
          searchValue,
          [descriptionCriteria]
        );
        criterias.push(inputSearchCriteria);
        return criterias;
      }
    }
    dialogRef.afterClosed().subscribe((statusTypeId: any) => {
      console.log('status type id', statusTypeId)
      if (statusTypeId) {}
    });
  }
}
