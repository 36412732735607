import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IconRefEnum } from 'library';
import { Subscription } from 'rxjs';
import {
  IBondProNGCarrierClientDto,
  INGLicenseTypeInstanceDto,
  INGStatusTypeInstanceDto,
  IViewClientLicenseInfo,
} from '../../../interfaces/license-interfaces';
import { AddLicenseModalComponent } from '../../dialog/add-license-modal/add-license-modal.component';
import { SubscriptionModalComponent } from '../../dialog/subscription-modal/subscription-modal.component';
import { LicenseService } from '../../../services/license.service';
import { ActivityHistoryService, ActivityKey, SearchCriteriaOperator, SearchOperator } from '../../../services/activity-history.service';
import { ActivityHistoryDialogComponent } from '../../activity-history/activity-history-dialog/activity-history-dialog.component';

@Component({
  selector: 'app-license-filter',
  templateUrl: './license-filter.component.html',
  styleUrls: ['./license-filter.component.scss'],
})
export class LicenseFilterComponent implements OnInit, OnDestroy {
  @Input() clientId!: number;
  @Input() disabled!: boolean;
  @Input() licenseSelected!: IViewClientLicenseInfo;
  @Input() statusList!: INGStatusTypeInstanceDto[];
  @Input() clientModel!: IBondProNGCarrierClientDto;
  @Output() reloadLicenseList = new EventEmitter();

  private _subs = [] as Subscription[];
  licenseTypeList!: Array<INGLicenseTypeInstanceDto>;
  filterCategoryList!: any[];
  addIcon = IconRefEnum.Add;
  filterByModel: any;

  activityIcon = IconRefEnum.History;

  constructor(
    public dialog: MatDialog,
    private licenseService: LicenseService,
    private activityHistoryService: ActivityHistoryService,
  ) {
    this.getLicenseType();
  }

  ngOnInit() {}

  getLicenseType() {
    this._subs.push(
      this.licenseService
        .getNGLicenseTypes()
        .subscribe((response: INGLicenseTypeInstanceDto[]) => {
          this.licenseTypeList = response;
          this.getDropdownValue();
        })
    );
  }

  onAddLicense() {
    const dialogRef = this.dialog.open(AddLicenseModalComponent, {
      width: '900px',
    });

    dialogRef.componentInstance.clientId = this.clientId;
    dialogRef.componentInstance.clientModel = this.clientModel;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadLicenseList.emit();
      }
    });
  }

  onSubscription() {
    const dialogRef = this.dialog.open(SubscriptionModalComponent, {
      width: '1250px',
    });

    dialogRef.componentInstance.licenseModel = this.licenseSelected;
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getDropdownValue(statusList?: any) {
    this.filterCategoryList = [
      {
        label: 'License Type',
        key: 'licenseType'
      },
      {
        label: 'Status',
        key: 'licenseStatusTypeName'
      },
    ];
  }

  onChangeFilter(searchModel: any) {
    const searchTerm: any = {};
    if (searchModel.search) {

      if (Number(searchModel.search)) { 
        searchTerm.dbCorporationId = parseInt(searchModel.search);
       } 
      else {
        searchTerm.licenseType = searchModel.search;
      }
    }
    if (searchModel.filterBy && searchModel.filterBy !== 'All') {
      searchTerm[searchModel.category] = searchModel.filterBy;
    }
    this.reloadLicenseList.emit(searchTerm);
  }

  onChangeCategory(field: any) {
    if (field === 'licenseType') {
      this.filterByModel = {
        list: this.licenseTypeList,
        label: 'licenseDescription',
        key: 'licenseDescription',
      };
    }
    if (field === 'licenseStatusTypeName') {

      const findAll = this.statusList.find(item => item.statusTypeId === 0)
      if (!findAll) {
        const allItem: any =
        {
          statusDescription: 'All',
          statusName: 'All',
          statusTypeCode: 'All',
          statusTypeId: 0,
        }
        this.statusList.splice(0, 0, allItem)
      }

      this.filterByModel = {
        list: this.statusList,
        label: 'statusDescription',
        key: 'statusDescription',
      };
    }
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  showActivity() {
    const dialogRef = this.dialog.open(ActivityHistoryDialogComponent, {
      maxHeight: '100vh'
    });
    dialogRef.componentInstance.classification = 'License';
    dialogRef.componentInstance.extraColumns = [
      {
        field: 'LicenseId',
        title: 'License ID',
      },
      {
        field: 'LicenseType',
        title: 'License Type',
      }
    ]
    dialogRef.componentInstance.extraDetailHeaders = [
      {
        key: 'LicenseId',
        label: 'License ID'
      },
      {
        key: 'LicenseType',
        label: 'License Type'
      },
    ];
    dialogRef.componentInstance.extraGridDetailHeaders = [
      {
        value: this.clientModel.ngCarrierClientId,
        label: 'Client ID'
      },
      {
        value: this.clientModel.clientName,
        label: 'Client Name'
      },
    ];
    dialogRef.componentInstance.diffConfig = {
      historicalFields: ['CreatedBy', 'CreateDate', 'ModifyDate', 'ModifiedBy'],
      onlyDateFields: [],
      customPropertyFormatters: {},
      rootPropertySection: 'License Section',
    }
    dialogRef.componentInstance.extraSearchCriterias = this.activityHistoryService.buildCriteriaFilter(
      'ClientId',
      this.clientModel.ngCarrierClientId
    )
    dialogRef.componentInstance.filterConfig = {
      enabled: true,
      placeholder: 'Search by License ID or Description',
      buildSearchCriterias: (searchValue) => {
        let criterias: any[] = [];
        if (!searchValue) {
          return criterias;
        }
        const descriptionCriteria = {
          Operator: SearchOperator.OR,
          Criteria: {
            Operator: SearchCriteriaOperator.EQ,
            Key: ActivityKey.Description,
            Value: searchValue,
          },
        };
        const inputSearchCriteria = this.activityHistoryService.buildInputCriteria(
          ['LicenseId'],
          searchValue,
          [descriptionCriteria]
        );
        criterias.push(inputSearchCriteria);
        return criterias;
      }
    }
    dialogRef.afterClosed().subscribe((statusTypeId: any) => {
      console.log('status type id', statusTypeId)
      if (statusTypeId) {}
    });
  }
}
