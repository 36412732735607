import { Component, OnInit, Input, ViewEncapsulation, TemplateRef, Output, EventEmitter } from '@angular/core';
import { IconRefEnum } from '../../enums/iconRefEnum';

@Component({
  selector: 'bp-tabs-bar',
  templateUrl: './bp-tabs-bar.component.html',
  styleUrls: ['./bp-tabs-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BpTabsBarComponent implements OnInit {
  @Input() tabsList!: Array<BpTabsModel>;
  @Input() tabsBarTemplate!: TemplateRef<any>;
  @Input() selectedTabIndex: number = 0;
  @Input() isTabtertiary: boolean = false;
  @Output() onTabChange = new EventEmitter<any>();
  _iconRefEnum = IconRefEnum;

  constructor() { }

  ngOnInit() {
  }

  stopPropagation(ev: any) {
    ev.stopPropagation();
  }

  onTabClick(event: any, tab: BpTabsModel) {
    if (tab.onClick) {
      tab.onClick(event);
    }
  }

  onSubMenuClick(event: any, tab: BpTabsModel, option: any, tabIndex: any) {
    this.selectedTabIndex = tabIndex;
    if (tab.onClick) {
      tab.onClick(event, option);
    }
  }

  selectedTabChanged(event: any){
    let tab = this.tabsList[event.index];
    if(tab && tab.onClick)
    tab.onClick(event);
    this.onTabChange.emit({
      tabSelected: tab
    })
  }
}

export interface BpTabsModel {
  label: string;
  customClass: string;
  isDisabled: boolean;
  isHidden: boolean;
  hasDropdown?: boolean;
  dropdownList?: Array<any>;
  dropdownOptionDisplayKey?: string;
  onClick($event: any, dropdownOption?: any): void;
}
