export interface INGCarrierClientModel {
  ngCarrierClientId?: number;
  clientName: string;
  akaNames: string;
  customerNumber: string;
  clientDescription: string;
  contractReferenceNumber: string;
  modifyDate: Date;
  modifiedBy: string;
  clientStatus: string;
}

export interface INGCarrierClientRelationshipModel {
  ngCarrierClientBusinessToBusinessId: number;
  ngCarrierClientId: number;
  ngCarrierClientIDRelation: number;
  statusTypeId: number;
  createDate: string;
  createdBy: string;
  modifyDate: string;
  modifiedBy: string;
  customerTypeCode: string;
  name: string;
  statusName: string;
  clientName: string;
  clientRelationName: string;
}

export interface IBondProNGCarrierClientDto {
  ngCarrierClientId?: number;
  clientName: string;
  customerNumber: string;
  customerTypeCode: string;
  customerTypeName?: string;
  contractReferenceNumber: string;
  clientDescription: string;
  akaNames?: any[];
  statusTypeId?: number;
  notes: string;
  createdDate?: Date;
  createdBy: string;
  modifiedDate?: Date;
  createDate?: Date;
  modifyDate?: Date;
  modifiedBy: string;
  isInternalApp?: boolean;
  statusTypeName?: string;
  businessToBusinessRelations?: number[];
}

export interface IBondProNGClientStatusDto {
  ngCarrierClientId?: number;
  statusTypeId: number;
  statusTypeName?: string;
  modifiedBy?: string;
}

export interface ClientInstanceAuthenticationKey {
  nGCarrierClientInstanceAuthenticationKeyId?: number;
  nGCarrierClientInstanceId?: number;
  authenticationINCode: string;
  serviceAuthenticationCode: string;
  key: string;
  value: string;
  connectingUserId?: string;
  createDate?: Date;
  createdBy?: string;
  modifyDate?: Date;
  modifiedBy?: string;
  serviceAuthenticationName?: string;
  authenticationINName?: string;
  applicationTypeCode: string;
  applicationTypeName?: string;
}
export interface ApplicationType {
  applicationTypeCode: string;
  name: string;
  description: string;
  statusTypeId: number;
  label?: string;
  customClass?: string;
  isDisabled?: boolean;
  isHidden?: boolean;
}
export interface AuthenticationType {
  authenticationTypeCode: string;
  name: string;
  description: string;
  statusTypeId: number;
}

export interface AuthenticationIN {
  authenticationINCode: string;
  name: string;
  description: string;
  statusTypeId: number
}

export interface ServiceAuthentication {
  serviceAuthenticationCode: string;
  name: string;
  description: string;
  statusTypeId: number;
}

export interface INGCarrierClientInstanceDto {
  ngCarrierClientInstanceId?: number;
  ngCarrierClientId?: number;
  dbCorporationId?: number;
  databaseServerName: string;
  databaseName: string;
  databaseServerIPAddress: string;
  dbVersion: string;
  appVersion: string;
  subSource: string;
  createDate?: Date;
  createdBy: string;
  modifyDate: Date;
  modifiedBy: string;
  authenticationKeys: ClientInstanceAuthenticationKey[];
  isInternalApp?: boolean;
  appSettingUrls: AppSettingUrls;
  urlSettings: string;
}

export interface AppSettingUrls {

  //FD
  fDServiceURL: string;
  fDApplicationURL: string;
  fDAuthenticationTypeCode: string;
  fDAuthenticationTypeName: string;
  fDAuthenticationURL: string;

  //B2B
  b2BServiceURL: string;
  b2BApplicationURL: string;
  b2BAuthenticationTypeCode: string;
  b2BAuthenticationTypeName: string;
  b2BAuthenticationURL: string;

  //PORTAL
  portalServiceURL: string;
  portalApplicationURL: string;
  portalAuthenticationTypeCode: string;
  portalAuthenticationTypeName: string;
  portalAuthenticationURL: string;
}
export interface DinamicAppSettingUrl {
    isSelected: boolean;
    code: string;
    label: string;
		serviceURL: string;
		applicationURL: string;
		authenticationTypeCode: string;
		authenticationTypeName?: string;
		authenticationURL: string;
}

export interface IViewClientLicenseInfo {
  ngCarrierClientId?: number;
  licenseId?: number;
  licenseTypeId?: number;
  dbCorporationId?: number;
  notes: string;
  customerName: string;
  customerNumber: string;
  contractReferenceNumber: string;
  description: string;
  ngCarrierClientInstanceId?: number;
  clientStatusTypeName: string;
  clientStatusTypeCode: string;
  effectiveDate: Date;
  expirationDate: Date;
  createDate?: Date;
  createdBy: string;
  modifyDate: Date;
  modifiedBy: string;
  licenseType: string;
  licenseTypeCode: string;
  licenseKey?: string;
  LicenseTypeId?: number;
  licenseStatusTypeId?: number;
  licenseStatusTypeName: string;
  licenseStatusTypeCode: string;


}

export interface IBondProClientInfoRequestDto extends IPagingSettings {
  nGCarrierClientId?: number;
  clientName?: string;
  clientStatuses? : string[];
}

export interface IBondProClientInstanceInfoRequestDto extends IPagingSettings {
  NGCarrierClientId?: number;
}

export interface IBondProClientLicenseRequestDto extends IPagingSettings {
  NGCarrierClientId?: number;
}

export interface IBondProLicenseSubscriptionRequestDto extends IPagingSettings {
  licenseId?: number;
}

export interface IServiceResponseDto {
  items: any[];
  success: boolean;
  errorMessages: string[];
}

export interface IPaginationResult {
  items: any[];
  totalRecords: number;
  page: number;
  pageSize: number;
  totalPages: number;
}

export class IPagingSettings {
  page?: number = 1;
  pageSize?: number = 20;
  sortColumn: string = '';
  direction: string = 'ASC';
  totalRecords: number = 0;
}

export interface INGCarrierLicensesInstanceDto {
  licenseId?: number;
  licenseTypeId: number;
  nGCarrierClientInstanceId: number;
  effectiveDate: string;
  expirationDate?: string;
  notes?: string;
  createDate?: Date;
  createdBy: string;
  modifyDate: Date;
  modifiedBy: string;
  licenseName?: string;
  licenseTypeCode?: string;
}

export interface INGSubscriptionInstanceDto {
  licenseSubscriptionTypeId?: number;
  licenseId?: number;
  effectiveDate: string;
  expirationDate?: string;
  subscriptionTypeId: number;
  subscriptionName?: string;
  statusTypeId: number;
  statusTypeName?: string;
  subscriptionStatus?: string;
  createDate?: Date;
  createdBy?: string;
  modifyDate?: Date;
  modifiedBy?: string;
  subscriptionTypes?: SubscriptionTypeItem[];
}

export interface SubscriptionTypeItem {
  subscriptionTypeId: number;
  subscriptionName: string;
}

export interface INGUpdateLicenseStatusDto {
  licenseId?: number;
  statusTypeId: number;
  ngCarrierClientInstanceId?: number;
  tenantId?: number;
  modifiedBy?: string;
  statusTypeName?: string;
  licenseName?: string;
  ngCarrierClientId?: string | number;
  licenseTypeId?: number;
  licenseTypeCode?: string;
}

export interface INGSubscriptionTypeInstanceDto {
  statusTypeId: number;
  subscriptionName: string;
  subscriptionTypeCode: string;
  subscriptionTypeId: number;
}

export interface INGStatusTypeInstanceDto {
  statusName: string;
  statusTypeCode: string;
  statusTypeId: number;
}

export interface INGLicenseTypeInstanceDto {
  licenseName: string;
  licenseTypeCode: string;
  licenseTypeId: number;
  statusTypeId: number;
}
export interface INGLicenseKeyDto {
  licenseId: number;
  licenseType: string;
  tenantId: number;
  expirationDate: string;
  effectiveDate: string;
  neverExpires: boolean;
  licenseStatusTypeId: number;
  modifiedBy?: string;
  licenseName?: string;
  licenseStatusTypeName?: string;
  ngCarrierClientId?: string | number;
  licenseTypeCode: string;
}

export interface INGBondProCustomerDto {
  bondProCustomerId: number;
  customerCode: string;
  customerTypeCode: string;
  contractReferenceNumber: string;
  customerName: string;
  customerDescription: string;
  statusTypeId: number;
  createDate: string;
  createdBy: string;
  modifyDate: string;
  modifiedBy: string;
}

export interface ICustomerTypeDto {
  customerTypeCode: string;
  name: string;
  description?: string;
  connectingUserId?: string;
  createDate?: string;
  createdBy?: string;
  modifyDate?: string;
  modifiedBy?: string;
}

export interface ILicenseKeyRequestDto {
  licenseKey: string;
}

export interface SaveBusinessToBusinessRequestDto {
  nGCarrierClientId: number;
  nGCarrierClientIdRelations: number[];
  username: string;
}

export interface RemoveBusinessToBusinessRequestDto {
  nGCarrierClientBusinessToBusinessID: number;
  modifiedBy: string;
}

export interface BondProUpdateClientRelationStatusRequestDto {
  nGCarrierClientBusinessToBusinessId: number;
  nGCarrierClientNameRelation: string;
  nGCarrierClientName: string;
  statusTypeId: number;
  statusTypeName: string;
  modifiedBy: string;
}

export interface BondProClientBusinessToBusinessRequestDto {
  nGCarrierClientId: number;
  page: number;
  pageSize: number;
  sortColumn: string;
  direction: string;
  clientName?: string;
  totalRecords: number;
}

export enum INGLicenseStatusTypeCode {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  EXPIRED = 'EXPIRED',
  DISABLED = 'DISABLED',
}

export enum INGClientStatusTypeId {
  ACTIVE = 1,
  INACTIVE = 2,
  PENDING = 3,
  PENDING_ACTIVATION = 5,
  EXPIRED = 6,
  DISABLED = 7
}

export enum BpStatefulTextbox {
  ERROR = 'error',
  SUCCESS = 'success'
}

export interface INGLicenseCheckDuplicate {
  nGCarrierClientInstanceID: number;
  databaseName: string;
}
