import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {IconRefEnum} from '../../enums/iconRefEnum';

@Component({
  selector: 'lib-select',
  templateUrl: './select.html',
  styleUrls: ['./select.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LibSelectComponent implements OnInit {
  @Input() options: any[] = [];
  @Input() control: FormControl | any;
  @Input() multiple: boolean = false;
  @Input() closeOnSelect: boolean = true;
  @Input() returnKey?: string;
  @Input() displayKey?: string;
  @Input() tooltipKey?: string;
  @Input() required: boolean = false;
  @Input() requiredErrorMessage?: string;
  @Input() bondIcon?: boolean = false;
  @Input() bondIconTemplate?: any;
  @Input() placeholder?: string;
  @Input() isState?: boolean = false;
  @Input() isAll?: boolean = false;
  @Input() isDuration?: boolean = false;
  @Input() clearable?: boolean = false;
  @Input() hasCurrencyList?: boolean = false;
  @Input() showRequiredOnSelectedOption? = false;
  @Input() disabled = false;
  @Input() appendTo: string = '';
  @Input() searchable: boolean = true;
  @Input() disableInternalFiltering = false;
  @Input() name = '';
  @Output() searchFn: EventEmitter<string> = new EventEmitter();
  @Output() onChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() onClearAllEvent: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Input() enableClearIcon: boolean = false;
  isFocused = false;
  disPlayValue = '';
  returnDisplayValue: any = '';
  selectAllChecked: boolean = false;

  searchIcon = IconRefEnum.BpSearch;

  constructor(private eleRef: ElementRef) {}

  ngOnInit() {
    let test: HTMLElement =
      this.eleRef.nativeElement.querySelector('ng-select input');
    test.setAttribute('maxlength', '50');

    this.disPlayValue = this.control.value;
    this.control.valueChanges.subscribe(() => {
      this.disPlayValue = this.control.value;
    });
    this.setDisabled();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if appendTo changes assign it to itself
    if (changes.appendTo) {
      if (changes.appendTo.currentValue) {
        // Break reference to the current value by using new String
        // Have to do .toString() since new String returns a type String vs string
        // (String with capital S denotates an object vs string with lower case that denotates a primitive type)
        this.appendTo = new String(changes.appendTo.currentValue).toString();
      } else {
        this.appendTo = 'body'; // Default value
      }
    }
    if (changes.disabled && !changes.disabled.firstChange) this.setDisabled();
  }

  setDisabled() {
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  selectUnselectAll(checked: boolean) {
    console.log('checked', checked);
    console.log(this.control);
    console.log(this.options);
    if (checked) {
      this.control.setValue(this.options.map((x) => x[`${this.returnKey}`]));
    } else {
      this.control.setValue(null);
    }
  }

  getDisplayValue(item: any) {
    if (this.displayKey && item && item[this.displayKey]) {
      return item[this.displayKey];
    }

    var isObject = Object.keys(item).length;
    if (isObject > 0) {
      if ('name' in item) {
        return item.name ? item.name : '';
      } else {
        return '';
      }
    }

    return item;
  }

  getTooltipValue(item: any) {
    if (this.tooltipKey && item && item[this.tooltipKey]) {
      return item[this.tooltipKey];
    }

    var isObject = Object.keys(item).length;
    if (isObject > 0) {
      if ('name' in item) {
        return item.name ? item.name : '';
      } else {
        return '';
      }
    }

    return item;
  }

  parseInt(data: any) {
    return parseInt(data, 10);
  }

  onChange(ev: any) {
    this.onChangeEvent.emit(this.control.value);
    this.eleRef.nativeElement.querySelector('input')?.blur();
  }

  /** To override the internal search function */
  searchFunction() {
    return true;
  }

  onSearch(ev: any) {
    if (this.searchFn) this.searchFn.emit(ev.term);
  }

  onSearchMultiple(select: any, $event: any) {
    select.filter($event.target!.value);
  }

  onKeyDown(ev: any) {
    if (ev.keyCode === 8 || ev.keyCode === 46) {
      ev.stopImmediatePropagation();
      ev.preventDefault();
      return false;
    }
    return true;
  }

  clearData() {
    this.disPlayValue = '';
    this.control.setValue(null);
    this.onChangeEvent.emit(this.control.value);
    this.onClearAllEvent.emit(this.control.value);
  }

  getSelectedItems() {
    if (!this.options) {
      return ''
    }
    const selectedIds = this.control.value || [];
    const selectedItems = this.options.filter((item) => selectedIds.includes(item[`${this.returnKey}`]));
    const selectedNames = selectedItems.map((item) => this.getDisplayValue(item));
    return selectedNames.join(', ');
  }
}
