import { Component } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BpSideBarService, BpNavigationLinkModel } from '../../services/bpSidebar.service';
import { IconRefEnum } from '../../enums/iconRefEnum';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ width: '0px', minWidth: '0', display: 'none' })),
      state('expanded', style({ width: '*', display: 'block' })),
      transition('* <=> *', animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class AppSideBarComponent {
  hoverElement: any;
  treeControl = new NestedTreeControl<BpNavigationLinkModel>(node => node.paletteItemList);
  treeDataSource = new MatTreeNestedDataSource<BpNavigationLinkModel>();

  selectedLinkId!: string;
  _iconRefEnum = IconRefEnum;
  navbarWidth = '';

  hasChild = (_: number, node: BpNavigationLinkModel) => !!node.paletteItemList && node.paletteItemList.length > 0;

  constructor(
    public _sideBarService: BpSideBarService,
    private router: Router
  ) {
    this._sideBarService.navListChangeListener.subscribe((list: BpNavigationLinkModel[]) => {
      this.treeDataSource.data = list;
    });

    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.setActiveNavigationItem(ev.urlAfterRedirects);
      }
    });

    const menuList: BpNavigationLinkModel[] = [
      {
        name: 'Client Management',
        id: 1,
        url: '/license',
        icon: 'bpi-User-Tasks',
      }
    ]
    // this.treeDataSource.data = this._sideBarService.getNavList();
    this.treeDataSource.data = menuList;
  }

  setActiveNavigationItem(currentUrl: string) {
    this.treeDataSource.data.forEach((navLink: BpNavigationLinkModel) => {
      navLink._isActive = false;
      if (navLink.paletteItemList) {
        navLink.paletteItemList.forEach((childLink: BpNavigationLinkModel) => {
          if (this.router.isActive(childLink.url ?? '', false)) { navLink._isActive = true; }
        });
      }
    });
  }

}


