import { Compiler, Component, EventEmitter, Injector, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PopupAnimation } from '@progress/kendo-angular-popup';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IconRefEnum } from '../../enums/iconRefEnum';
import { IBpeGridColumn, Pagination } from './grid.interface';

@Component({
  selector: 'bpe-grid',
  templateUrl: './grid.tmpl.html',
  styleUrls: [
    './grid.styles.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class BpeGridComponent implements OnInit {
  @Input() columnDefs: IBpeGridColumn[] = [];
  @Input() data: Object[] = [];
  @Input() noHeader?: boolean = false;
  @Input() isSelectable: boolean = false;
  @Input() isMultipleSelection: boolean = false;
  @Input() showViewMore: boolean = false;
  @Input() disabled: boolean = false;
  @Input() pagination!: Pagination;
  @Input() optionsTmpl!: TemplateRef<any>;
  @Input() selectionKey!: string;
  @Output() onPageChange = new EventEmitter<any>();
  @Output() onPageSizeChange?= new EventEmitter<number>();
  @Output() onRefresh?= new EventEmitter();
  @Output() onSort = new EventEmitter<any>();
  @Output() onFilterChange?= new EventEmitter<number>();
  @Output() onViewDetails = new EventEmitter<any>();
  @Output() onSelectionChange = new EventEmitter<any>();
  @Output() onSelectedKeysChange = new EventEmitter<any>();
  @Output() onDeleteRow = new EventEmitter<any>();
  selectedIds: number[] = [];
  bpMoreVertical = IconRefEnum.BpMoreVertical;
  BpColumns = IconRefEnum.BpColumns;
  bpView = IconRefEnum.BpView;
  bpRemove = IconRefEnum.BpTrash;
  public sortDescriptor: SortDescriptor[] = [];
  public skip = 0;
  public pageSize = 20;
  public pageSizesList = [{ text: '5', value: 5 }, { text: '10', value: 10 }, { text: '20', value: 20 }, { text: '50', value: 50 }];
  public tooltipoOpt = {
    position: 'bottom',
    callout: false,
  }
  public tooltipAnimation: PopupAnimation = {
    type: 'slide',
    direction: 'right',
    duration: 400
  }
  public showPopup = false;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if (this.pagination) {
      this.pagination.page = this.pagination.page ?? 1;
      this.pagination.pageSize = this.pagination.pageSize ?? 20;
    }

    this.columnDefs.forEach(item => {
      item.isVisible = true
      item.title = item.title.toLowerCase()
    })
  }

  pageChange(ev: any) {
    let page = 1
    if (ev.skip > 0) {
      page = (ev.skip / ev.take) + 1
    }
    this.skip = ev.skip
    this.pageSize = ev.take
    this.onPageChange.emit({
      page,
      pageSize: ev.take
    })
  }

  handleSortChange(ev: any) {
    this.sortDescriptor = ev
    this.onSort.emit({
      direction: ev[0].dir,
      sortColumn: ev[0].field
    })
  }

  viewDetails(ev: any) {
    this.onViewDetails.emit(ev);
  }

  selectionChange(ev: any) {
    this.onSelectionChange.emit(ev);
  }

  selectedKeysChange() {
    this.onSelectedKeysChange.emit({ selectedIds: this.selectedIds });
  }

  deleteRow(ev: any) {
    this.onDeleteRow.emit(ev);
  }
  public onToggle(): void {
    this.showPopup = !this.showPopup;
  }
}
