import { Component, OnInit, Input } from '@angular/core';
import { IconTypeEnum } from '../../../enums/iconTypeEnum';

@Component({
  selector: '[bpIcon]',
  templateUrl: './bp-icon.component.html',
  styleUrls: ['./bp-icon.component.scss']
})
export class BpIconComponent implements OnInit {
  @Input() bpIcon!: string;
  @Input() Size?: string;
  @Input() iconType?: IconTypeEnum;

  _iconType = IconTypeEnum;

  constructor() { }

  ngOnInit() {
  }

}
