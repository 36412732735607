import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IconRefEnum } from 'library';
import { InformationModalComponent } from '../components/dialog/information-modal/information-modal.component';

@Injectable({
  providedIn: 'root',
})
export class MessageHelperService {
  constructor(private dialog: MatDialog) {}
  showErrorModal(
    messageModel: any,
    errorMessages: string[]
  ): void {
    const dialogRef = this.dialog.open(InformationModalComponent, {
      width: '600px'
    });
    dialogRef.componentInstance.title = messageModel.title;
    dialogRef.componentInstance.message = messageModel.message;
    dialogRef.componentInstance.messageDetails = errorMessages;
    dialogRef.componentInstance.messageIcon = messageModel.iconMessage ?? IconRefEnum.BpWarning;
    dialogRef.afterClosed().subscribe((result: any) => {});
  }
}
