<div class="tenant-editor">
  <div class="header">
    <div class="title">
      <span *ngIf="!instanceModal" [innerText]="'Add Tenant Instance'"></span>
      <span *ngIf="instanceModal && !editMode" [innerText]="'Tenant Instance'"></span>
      <span *ngIf="editMode" [innerText]="'Modify Tenant Instance'"></span>
      <span *ngIf="instanceModal && !editMode" class="btn-icon" [bpMatIcon]="editIcon" (click)="onEditMode()"></span>
    </div>
    <div>
      <span [bpIcon]="closeIcon" (click)="onCloseModal()"></span>
    </div>
  </div>

  <div class="container">

    <div class="form-header">
      <span *ngIf="instanceModal" (click)="showActivity()" class="activity-icon" [bpMatIcon]="activityIcon"></span>
    </div>

    <!-- <div class="model-details">
      <div class="detail">
        <label [innerText]="'Tenant ID'"></label>
        <label class="value" [innerText]="instanceModal?.dbCorporationId"></label>
      </div>
      <div class="detail">
        <label [innerText]="'Created Date/Time'"></label>
        <label *ngIf="instanceModal?.createDate" class="value" [innerText]="instanceModal?.createDate"></label>
      </div>
    </div> -->

    <form [formGroup]="clientForm" novalidate autocomplete="off">
      <div class="form-container">
        <div class="bp-form-field full-width required"
          [class.bp-invalid]="clientForm.controls.databaseName?.invalid && (clientForm.controls.databaseName?.dirty || clientForm.controls.databaseName?.touched)">
          <label [innerText]="'Name'"></label>
          <bp-stateful-textbox formControlName="databaseName" [parentForm]="clientForm" [filedName]="'databaseName'" [label]="'Name'" [maxInputLengt]="'129'" (onValueChange)="onValueCustomInputChange($event)" [state]="stateCheckDuplicate"></bp-stateful-textbox>
          <div class="ng-error-message">{{nameValidationMessage}}</div>
          <!--<input matInput formControlName="databaseName" maxlength="129" />-->
        </div>
        <div class="bp-form-field full-width"
          [class.bp-invalid]="clientForm.controls.databaseServerIPAddress?.invalid && (clientForm.controls.databaseServerIPAddress?.dirty || clientForm.controls.databaseServerIPAddress?.touched)">
          <label [innerText]="'Server IP Address'"></label>
          <input matInput formControlName="databaseServerIPAddress" maxlength="33" />
          <div class="bp-field-error"
              *ngIf="clientForm.controls.databaseServerIPAddress?.invalid && (clientForm.controls.databaseServerIPAddress?.dirty || clientForm.controls.databaseServerIPAddress?.touched)">
              <div *ngIf="clientForm.controls.databaseServerIPAddress?.errors?.maxlength">
                <span [innerText]="'Maximum 32 characters allowed.'"></span>
              </div>
          </div>
        </div>
      </div>


      <div class="form-container">
        <div class="bp-form-field full-width"
          [class.bp-invalid]="clientForm.controls.databaseServerName?.invalid && (clientForm.controls.databaseServerName?.dirty || clientForm.controls.databaseServerName?.touched)">
          <label [innerText]="'Server Name'"></label>
          <input matInput formControlName="databaseServerName" maxlength="129" />
          <div class="bp-field-error"
              *ngIf="clientForm.controls.databaseServerName?.invalid && (clientForm.controls.databaseServerName?.dirty || clientForm.controls.databaseServerName?.touched)">
              <div *ngIf="clientForm.controls.databaseServerName?.errors?.maxlength">
                <span [innerText]="'Maximum 128 characters allowed.'"></span>
              </div>
          </div>
        </div>

        <div class="bp-form-field full-width"
          [class.bp-invalid]="clientForm.controls.appVersion?.invalid && (clientForm.controls.appVersion?.dirty || clientForm.controls.appVersion?.touched)">
          <label [innerText]="'App Version (Environment)'"></label>
          <input matInput formControlName="appVersion" maxlength="65" />
          <div class="bp-field-error"
              *ngIf="clientForm.controls.appVersion?.invalid && (clientForm.controls.appVersion?.dirty || clientForm.controls.appVersion?.touched)">
              <div *ngIf="clientForm.controls.appVersion?.errors?.maxlength">
                <span [innerText]="'Maximum 64 characters allowed.'"></span>
              </div>
          </div>
        </div>
      </div>

      <div class="form-container">
        <div class="bp-form-field full-width required">
          <label [innerText]="'Sub Source'"></label>
          <input matInput formControlName="subSource" maxlength="65" required/>
          <div class="bp-field-error"
              *ngIf="clientForm.controls.subSource?.invalid && (clientForm.controls.subSource?.dirty || clientForm.controls.subSource?.touched)">
              <div *ngIf="clientForm.controls.subSource?.errors?.required">
                <span [innerText]="'Sub Source is required.'"></span>
              </div>
              <div *ngIf="clientForm.controls.subSource?.errors?.maxlength">
                <span [innerText]="'Maximum 64 characters allowed.'"></span>
              </div>
          </div>
        </div>
        <div class="bp-form-field full-width"
          [class.bp-invalid]="clientForm.controls.dbVersion?.invalid && (clientForm.controls.dbVersion?.dirty || clientForm.controls.dbVersion?.touched)">
          <label [innerText]="'Database Version (Enviroment)'"></label>
          <input matInput formControlName="dbVersion" maxlength="65" />
          <div class="bp-field-error"
              *ngIf="clientForm.controls.dbVersion?.invalid && (clientForm.controls.dbVersion?.dirty || clientForm.controls.dbVersion?.touched)">
              <div *ngIf="clientForm.controls.dbVersion?.errors?.maxlength">
                <span [innerText]="'Maximum 64 characters allowed.'"></span>
              </div>
          </div>
        </div>
      </div>

      <div class="integration-detail-title">
        <span>Integration Detail</span>
      </div>

      <bp-tabs-bar [tabsList]="applicationTypesList" [isTabtertiary]="true" (onTabChange)="onTabChange($event)"></bp-tabs-bar>

      <div *ngFor="let type of appSettingUrlsList">
        <div *ngIf="type.isSelected" class="integartion-detail-content">
          <ng-container formGroupName="appSettingUrls">
              <div class="form-container">
                <div class="bp-form-field full-width dbversion"
                  [class.bp-invalid]="clientForm.controls.appSettingUrls?.get(type.serviceURL)?.invalid && (clientForm.controls.appSettingUrls?.get(type.serviceURL)?.dirty || clientForm.controls.appSettingUrls?.get(type.serviceURL)?.touched)">
                  <label [innerText]="'SOA Service URL'"></label>
                  <input placeholder="Enter SOA Service URL" matInput formControlName="{{type.serviceURL}}" maxlength="513" />
                  <div class="bp-field-error"
                      *ngIf="clientForm.controls.appSettingUrls?.get(type.serviceURL)?.invalid && (clientForm.controls.appSettingUrls?.get(type.serviceURL)?.dirty || clientForm.controls.appSettingUrls?.get(type.serviceURL)?.touched)">
                      <div *ngIf="clientForm.controls.appSettingUrls?.get(type.serviceURL)?.errors?.maxlength">
                        <span [innerText]="'Maximum 512 characters allowed.'"></span>
                      </div>
                  </div>
                </div>
              </div>

              <div class="form-container">
                <div class="bp-form-field full-width dbversion"
                  [class.bp-invalid]="clientForm.controls.appSettingUrls?.get(type.applicationURL)?.invalid && (clientForm.controls.appSettingUrls?.get(type.applicationURL)?.dirty || clientForm.controls.appSettingUrls?.get(type.applicationURL)?.touched)">
                  <label [innerText]="'Application URL'"></label>
                  <input placeholder="Enter Application URL" matInput formControlName="{{type.applicationURL}}" maxlength="513" />
                  <div class="bp-field-error"
                      *ngIf="clientForm.controls.appSettingUrls?.get(type.applicationURL)?.invalid && (clientForm.controls.appSettingUrls?.get(type.applicationURL)?.dirty || clientForm.controls.appSettingUrls?.get(type.applicationURL)?.touched)">
                      <div *ngIf="clientForm.controls.appSettingUrls?.get(type.applicationURL)?.errors?.maxlength">
                        <span [innerText]="'Maximum 512 characters allowed.'"></span>
                      </div>
                  </div>
                </div>
              </div>

              <div class="form-container">
                <div class="bp-form-field full-width row"
                  [class.bp-invalid]="clientForm.controls.appSettingUrls?.get(type.authenticationTypeCode)?.invalid && (clientForm.controls.appSettingUrls?.get(type.authenticationTypeCode)?.dirty || clientForm.controls.appSettingUrls?.get(type.authenticationTypeCode)?.touched)">
                  <label [innerText]="'Authentication Type'"></label>
                  <mat-form-field appearance="outline" class="bp-form-field full-width bp-dropdown filter-input">
                    <mat-select formControlName="{{type.authenticationTypeCode}}" placeholder="Select Authentication Type" disableOptionCentering
                      panelClass="bp-filter-selector">
                      <mat-option *ngFor="let type of authenticationTypes" [value]="type.authenticationTypeCode">
                        {{type.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-container">
                <div class="bp-form-field full-width dbversion"
                  [class.bp-invalid]="clientForm.controls.appSettingUrls?.get(type.authenticationURL)?.invalid && (clientForm.controls.appSettingUrls?.get(type.authenticationURL)?.dirty || clientForm.controls.appSettingUrls?.get(type.authenticationURL)?.touched)">
                  <label [innerText]="'Authentication URL'"></label>
                  <input placeholder="Enter Authentication URL" matInput formControlName="{{type.authenticationURL}}" maxlength="513" />
                  <div class="bp-field-error"
                      *ngIf="clientForm.controls.appSettingUrls?.get(type.authenticationURL)?.invalid && (clientForm.controls.appSettingUrls?.get(type.authenticationURL)?.dirty || clientForm.controls.appSettingUrls?.get(type.authenticationURL)?.touched)">
                      <div *ngIf="clientForm.controls.appSettingUrls?.get(type.authenticationURL)?.errors?.maxlength">
                        <span [innerText]="'Maximum 512 characters allowed.'"></span>
                      </div>
                  </div>
                </div>
              </div>
          </ng-container>

            <div [class.grid-controls--disabled]="clientForm.disabled" class="grid-controls">
              <fa-icon (click)="addNewAuthenticationKey()" [icon]="addIcon" class="add-icon"></fa-icon>
              <label (click)="addNewAuthenticationKey()">
                ADD
              </label>
            </div>
            <div class="grid-form">
              <div class="grid-header">
                <div class="column--key">
                  <label class="grid-header__label">key</label>
                </div>
                <div class="column--value">
                  <label class="grid-header__label">value</label>
                </div>
                <div class="column--in">
                  <label class="grid-header__label">in</label>
                </div>
                <div class="column--service">
                  <label class="grid-header__label">service/authentication</label>
                </div>
                <div class="column--actions">
                  <label class="grid-header__label"></label>
                </div>
              </div>
              <div formArrayName="authenticationKeys" class="grid-body">
                <div *ngFor="let authenticationKey of authenticationKeysFormArray.controls; let i=index">
                  <ng-container [formGroupName]="i" *ngIf="authenticationKey.get('applicationTypeCode')?.value?.includes(type.label.replace(' ','_').toUpperCase())">
                    <div class="grid-body__row">
                      <div class="column--key">
                        <div class="form-container">
                          <div class="bp-form-field full-width"
                            [class.bp-invalid]="authenticationKey.get('key')?.invalid && (authenticationKey.get('key')?.dirty || authenticationKey.get('key')?.touched)">
                            <input matInput formControlName="key" maxlength="65" />
                            <!-- <div class="bp-field-error"
                                *ngIf="clientForm.controls.databaseName?.invalid && (clientForm.controls.databaseName?.dirty || clientForm.controls.databaseName?.touched)">
                                <div *ngIf="clientForm.controls.databaseName?.errors?.maxlength">
                                  <span [innerText]="'Maximum 128 characters allowed.'"></span>
                                </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="column--value">
                        <div class="form-container">
                          <div class="bp-form-field full-width"
                            [class.bp-invalid]="authenticationKey.get('value')?.invalid && (authenticationKey.get('value')?.dirty || authenticationKey.get('value')?.touched)">
                            <input matInput formControlName="value"/>
                            <!-- <div class="bp-field-error"
                                *ngIf="clientForm.controls.databaseName?.invalid && (clientForm.controls.databaseName?.dirty || clientForm.controls.databaseName?.touched)">
                                <div *ngIf="clientForm.controls.databaseName?.errors?.maxlength">
                                  <span [innerText]="'Maximum 128 characters allowed.'"></span>
                                </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="column--in column--in__value">
                        <div class="form-container">
                          <div class="bp-form-field full-width">
                            <mat-form-field appearance="outline" class="bp-form-field full-width bp-dropdown filter-input">
                              <mat-select formControlName="authenticationINCode" placeholder="Select" disableOptionCentering
                                panelClass="bp-filter-selector">
                                <mat-option *ngFor="let type of authenticationINList" [value]="type.authenticationINCode">
                                  {{type.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="column--service">
                        <div class="form-container">
                          <div class="bp-form-field full-width">
                            <mat-form-field appearance="outline" class="bp-form-field full-width bp-dropdown filter-input">
                              <mat-select formControlName="serviceAuthenticationCode" placeholder="Select" disableOptionCentering
                                panelClass="bp-filter-selector">
                                <mat-option *ngFor="let type of serviceAuthenticationList" [value]="type.serviceAuthenticationCode">
                                  {{type.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="column--actions">
                        <!-- <fa-icon [icon]="editFaIcon" class="icon"></fa-icon> -->
                        <fa-icon [class.icon--disabled]="clientForm.disabled" [icon]="removeIcon" class="icon" (click)="removeAuthenticationKey(i)"></fa-icon>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
        </div>
      </div>

    </form>

    <div class="btn-container buttons-right">
      <button type="button" class="secondary md" (click)="onCloseModal()">Cancel</button>
      <button [disabled]="!clientForm.valid" type="submit" class="primary md" (click)="onSave()">Save</button>
    </div>
  </div>
</div>
