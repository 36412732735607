import { Component, OnInit, Input ,} from '@angular/core';

@Component({
  selector: 'bpe-custom-spinner',
  templateUrl: './custom-spinner.component.html',
  styleUrls: ['./custom-spinner.component.scss'],
})

export class CustomSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
