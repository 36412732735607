import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[bpMatIcon]',
  templateUrl: './bp-mat-icon.component.html',
  styleUrls: ['./bp-mat-icon.component.scss']
})
export class BpMatIconComponent implements OnInit {
  @Input() bpMatIcon!: string;
  @Input() iconSize?: string;

  constructor() { }

  ngOnInit() {
  }

}
