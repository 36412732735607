<div class="add-edit-client">
  <div class="container">
    <div class="title">
      <div>
        <span [innerText]="titleForm"></span>
        <span *ngIf="!_disabled && isEditForm && !editMode" class="btn-icon" [bpMatIcon]="editIcon"
          (click)="onEditClient()"></span>
      </div>
      <span *ngIf="isEditForm" (click)="showActivity()" class="activity-icon" [bpMatIcon]="activityIcon"></span>
    </div>
    <form [formGroup]="clientForm" novalidate autocomplete="off">
      <div class="form-container">
        <div class="box">
          <div class="bp-form-field bp-form-field--customer-name required"
            [class.bp-invalid]="clientForm.controls.clientName?.invalid && (clientForm.controls.clientName?.dirty || clientForm.controls.clientName?.touched)">
            <label [innerText]="'Customer Name'"></label>
             <input *ngIf="isEditForm" matInput id="clientName" formControlName="clientName" maxlength="257"/>
            <lib-select *ngIf="!isEditForm"
                [name]="'Customer Name'"
                [options]="bondProCustomers"
                [control]="clientForm.controls.bondProCustomerId"
                [disabled]="isEditForm && !editMode"
                [multiple]="false"
                [closeOnSelect]="true"
                displayKey="customerName"
                returnKey="bondProCustomerId"
                placeholder="Select"
                [required]="false"
            ></lib-select>
            <div class="bp-field-error"
              *ngIf="clientForm.controls.clientName?.invalid && (clientForm.controls.clientName?.dirty || clientForm.controls.clientName?.touched)">
              <!-- <div *ngIf="clientForm.controls.clientName?.errors?.required">
                <span [innerText]="'Customer Name is required.'"></span>
              </div> -->
              <div *ngIf="clientForm.controls.clientName?.errors?.maxlength">
                <span [innerText]="'Maximum 256 characters allowed.'"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="box app-content">
          <div class="bp-form-field" style="width: 50%; margin-right: 5px;"
            [class.bp-invalid]="clientForm.controls.customerNumber?.invalid && (clientForm.controls.customerNumber?.dirty || clientForm.controls.customerNumber?.touched)">
            <label [innerText]="'Customer Number'"></label>
            <input matInput id="customerNumber" formControlName="customerNumber" maxlength="65"/>
            <div class="bp-field-error"
              *ngIf="clientForm.controls.customerNumber?.invalid && (clientForm.controls.customerNumber?.dirty || clientForm.controls.customerNumber?.touched)">
              <div *ngIf="clientForm.controls.customerNumber?.errors?.maxlength">
                <span [innerText]="'Maximum 64 characters allowed.'"></span>
              </div>
            </div>
          </div>
          <div class="bp-form-field" style="width: 50%;">
            <label [innerText]="'Contract Reference Number'"></label>
            <input matInput formControlName="contractReferenceNumber" />
          </div>
        </div>
        <div class="box app-content">
          <div class="bp-form-field required" style="width: 50%; margin-right: 5px;">
            <label [innerText]="'Customer Type'"></label>
            <mat-form-field appearance="outline" class="bp-form-field bp-dropdown filter-input">
              <mat-select formControlName="customerTypeCode" [placeholder]="'Select Customer Type'" disableOptionCentering
              panelClass="bp-filter-selector">
                <mat-option *ngFor="let type of customerTypeList" [value]="type.customerTypeCode">
                  {{type.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="bp-field-error"
              *ngIf="clientForm.controls.customerTypeCode?.invalid && (clientForm.controls.customerTypeCode?.dirty || clientForm.controls.customerTypeCode?.touched)">
              <div *ngIf="clientForm.controls.customerTypeCode?.errors?.required">
                <span [innerText]="'Customer Type is required.'"></span>
              </div>
            </div>
          </div>
          <div class="bp-form-field required" style="width: 50%;">
            <label [innerText]="'Edition'"></label>
            <mat-form-field appearance="outline" class="bp-form-field bp-dropdown filter-input">
              <mat-select formControlName="isInternalApp" [placeholder]="'Select Edition'" disableOptionCentering
              panelClass="bp-filter-selector">
                <mat-option *ngFor="let type of appList" [value]="type.key">
                  {{type.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="bp-field-error"
              *ngIf="clientForm.controls.isInternalApp?.invalid && (clientForm.controls.isInternalApp?.dirty || clientForm.controls.isInternalApp?.touched)">
              <div *ngIf="clientForm.controls.isInternalApp?.errors?.required">
                <span [innerText]="'Edition is required.'"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="bp-form-field">
            <label [innerText]="'AKA Name(s)'"></label>
            <bp-mat-chips [(chipList)]="akaChipList" [chipDisabled]="isEditForm && !editMode">
            </bp-mat-chips>
          </div>
        </div>
        <div class="box">
          <div class="bp-form-field">
            <label [innerText]="'Description'"></label>
            <textarea matInput rows="3" cols="18" formControlName="clientDescription">
          </textarea>
          </div>
        </div>
        <div class="box">
          <div class="bp-form-field">
            <label [innerText]="'Notes'"></label>
            <textarea matInput rows="3" cols="18" formControlName="notes">
          </textarea>
          </div>
        </div>
      </div>
      <div class="btn-container buttons-right" *ngIf="!isEditForm || editMode">
        <button type="button" class="secondary md" (click)="onCancel()" style="margin-right: 15px;">Cancel</button>
        <button [disabled]="!clientForm.valid" type="submit" class="primary md" (click)="onSave()">Save</button>
      </div>
    </form>
  </div>
</div>
