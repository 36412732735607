import { filter } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import {
  IBondProClientInfoRequestDto,
  IBondProNGClientStatusDto,
  ICustomerTypeDto,
  INGCarrierClientModel,
  INGStatusTypeInstanceDto,
} from '../../interfaces/license-interfaces';
import { LicenseService } from '../../services/license.service';
import { BpeGridComponent,IBpeGridColumn, IconRefEnum, DateTimeService } from 'library';
import { Router } from '@angular/router';
import { RouteTabsService } from '../../services/route-tabs.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { UpdateStatusModalComponent } from '../dialog/update-status-modal/update-status-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationModalComponent } from '../dialog/confirmation-modal/confirmation-modal.component';
import { MessageHelperService } from '../../services/message-helper.service';
import { kebabCase } from 'lodash';
import { ActivityKey, SearchCriteriaOperator, SearchOperator } from '../../services/activity-history.service';
import { ActivityHistoryDialogComponent } from '../activity-history/activity-history-dialog/activity-history-dialog.component';
import { UserService } from '../../services/user.service';


const statusList = ['Active','Inactive','Pending']
const customerTypeList = ['AGENCY', 'CARRIER']
@Component({
  selector: 'app-client-license-management',
  templateUrl: './client-license-management.component.html',
  styleUrls: ['./client-license-management.component.scss'],
})
export class ClientLicenseManagementComponent implements OnInit, OnDestroy {
  public ngClients: INGCarrierClientModel[] = [];
  public columnDefs: IBpeGridColumn[] = [];

  _subs = [] as Subscription[];
  addIcon = IconRefEnum.Add;
  activityIcon = IconRefEnum.History;
  advancedFilterIsOpen: boolean = false;
  showViewMore: boolean = true;
  pagination!: IBondProClientInfoRequestDto;
  statusTypeList!: Array<INGStatusTypeInstanceDto>;
  customerTypeList!: Array<ICustomerTypeDto>;
  //@ViewChild("bpeGrid") grid!: BpeGridComponent;

  constructor(
    private licenseService: LicenseService,
    private router: Router,
    public _routeTabsService: RouteTabsService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private messageService: MessageHelperService,
    private dateTimeService: DateTimeService,
    private userService: UserService,
  ) {
    this.defColumns();
    this.setPaginationValues();
  }

  ngOnInit() {
    this.getStatusType();
    this.getCustomerTypeList();
    this.loadClientList();
  }

 /*  ngAfterViewInit()
  {
    console.log(this.grid);
  } */

  defColumns() {
    this.columnDefs = [
      { title: 'Customer ID', field: 'ngCarrierClientId', width: 60 },
      { title: 'Customer Name', field: 'clientName' },
      { title: 'CUSTOMER TYPE', field: 'customerTypeName' },
      { title: 'AKA NAME(s)', field: 'akaName' },
      { title: 'CUSTOMER NUMBER', field: 'customerNumber' },
      { title: 'DESCRIPTION', field: 'clientDescription' },
      { title: 'CONTRACT REFERENCE NUMBER', field: 'contractReferenceNumber' },
      {
        title: 'LAST MODIFIED DATE/TIME', field: 'modifyDate', bodyCellTemplate: (col, row) => {

          return this.dateTimeService.toLocalTime(row.modifyDate, true)
        }
      },
      { title: 'LAST MODIFIED BY', field: 'modifiedBy' },
      {
        title: 'STATUS', field: 'clientStatus', bodyCellTemplate: (col, row) => {

          return `<span class="bpe-status bpe-status-${this.kebabCase(row.clientStatus)}">
                    <span>${row.clientStatus}</span>
                  </span>`
        }
      },
    ];
  }

  kebabCase(x: string) {
    return kebabCase(x);
  }

  escapeHtml(unsafe: string) {
    return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;").replace(/'/g, "&#039;");
  }

  setPaginationValues(searchTerm?: any) {
    this.pagination = {
      page: 1,
      pageSize: 20,
      sortColumn: 'ngCarrierClientId',
      direction: 'desc',
      totalRecords: 0,
      ...searchTerm,
      clientStatuses : !searchTerm?.clientStatuses ? statusList : [searchTerm.clientStatuses],
      customerTypeCodes: !searchTerm?.customerTypeCodes ? customerTypeList : [searchTerm.customerTypeCodes]
    };

  }

  onPageChange(event: any) {
    this.pagination.page = event.page
    this.pagination.pageSize = event.pageSize
    this.loadClientList()
  }

  onSort(event: any) {
    this.pagination.direction = event.direction
    this.pagination.sortColumn = event.sortColumn
    this.loadClientList()
  }

  loadClientList() {
    this._subs.push(
      this.licenseService
        .getNGCarrierClients(this.pagination)
        .subscribe((n) => {
          this.ngClients = n.items;
          this.ngClients = this.ngClients.map(x=> {return {...x,disableRow: x.clientStatus === 'Disabled' ? true : false}})
          this.pagination.totalRecords = n.totalRecords;
        })
    );
  }

  getStatusType(): void {
    this._subs.push(
      this.licenseService.getNGStatusTypes().subscribe((reponse) => {
        this.statusTypeList = reponse.filter(x=> x.statusTypeCode !== 'EXPIRED' && x.statusTypeCode !== 'PENDING_ACTIVATION');

        const allItem: INGStatusTypeInstanceDto =
        {
          statusName: 'All',
          statusTypeCode: 'All',
          statusTypeId: 0,
        }
        this.statusTypeList.splice(0, 0, allItem)
      })
    );
  }

  getCustomerTypeList(): void{
    this._subs.push(
      this.licenseService
        .getCustomerType()
        .subscribe((response: ICustomerTypeDto[]) => {
          const allItem: ICustomerTypeDto =
          {
            name: 'All',
            customerTypeCode: 'All',
          }
          this.customerTypeList = response
          this.customerTypeList.splice(0, 0, allItem)
        })
    );
  }

  showActivity() {
    const dialogRef = this.dialog.open(ActivityHistoryDialogComponent, {
      maxHeight: '100vh'
    });
    dialogRef.componentInstance.diffConfig = {
      historicalFields: ['CreatedBy', 'CreateDate', 'ModifyDate', 'ModifiedBy'],
      onlyDateFields: [],
      customPropertyFormatters: {},
      rootPropertySection: '',
      multipleRootOptions: ['License Section', 'Instance Section', 'Client Section'],
    };
    // dialogRef.componentInstance.statusTypeList = this.getAvailableStatus();
    dialogRef.componentInstance.filterConfig = {
      enabled: true,
      placeholder: 'Search by Description',
      buildSearchCriterias: (searchValue) => {
        let criterias: any[] = [];
        if (!searchValue) {
          return criterias;
        }
        const descriptionCriterias = [
          {
            Operator: SearchOperator.AND,
            Criteria: {
              Operator: SearchCriteriaOperator.EQ,
              Key: ActivityKey.Description,
              Value: searchValue,
            },
          },
        ]
        criterias = criterias.concat(descriptionCriterias)
        return criterias;
      }
    }
  }

  onChangeValueFilter(searchTerm: any) {
    this.setPaginationValues(searchTerm);
    this.loadClientList();
  }

  onGoDetails(value: INGCarrierClientModel) {
    this.router.navigateByUrl(`license/client/${value.ngCarrierClientId}`);
  }

  onGoCreateClient() {
    this.router.navigateByUrl(`license/create-client`);
  }

  onOpenAdvancedFilter(isOpen: boolean) {
    this.advancedFilterIsOpen = isOpen;
  }

  onApplyAdvancedFilter(filterValues: any) {
    this.setPaginationValues(filterValues);
    this.loadClientList();
  }

  onApplyAdvancedCancel(filterValues: any) {
    this.advancedFilterIsOpen = false;
    this.setPaginationValues({});
    this.loadClientList();
  }

  onApplyAdvancedClear(filterValues: any) {
    this.setPaginationValues(filterValues);
    this.loadClientList();
  }

  onChangeStatus(rowData: INGCarrierClientModel) {
    const statusFiltered = this.getAvailableStatus(rowData);
    const dialogRef = this.dialog.open(UpdateStatusModalComponent, {
      width: '300px'
    });
    dialogRef.componentInstance.statusTypeList = statusFiltered;
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.updateClientStatus(result, rowData);
      }
    });
  }

  getAvailableStatus(rowData: INGCarrierClientModel) {
  // exclude status from the existing value
   const availableStatus = this.statusTypeList.filter(x=> x.statusName !== rowData.clientStatus);

    return availableStatus.filter((item) => {
      return (
        item.statusTypeCode === 'ACTIVE' ||
        item.statusTypeCode === 'INACTIVE'
      );
    })
  }

  updateClientStatus(statusTypeId: number, rowData: INGCarrierClientModel) {
    const clientModel: IBondProNGClientStatusDto = {
      ngCarrierClientId: rowData.ngCarrierClientId,
      statusTypeId: statusTypeId,
      statusTypeName: this.getStatusTypeName(statusTypeId),
      modifiedBy: this.userService.currentUserValue,
    };
    this._subs.push(
      this.licenseService.updateClientStatus(clientModel).subscribe((n) => {
        if (n.success) {
          this.toastr.success(
            'The status has been updated successfully',
            'Success'
          );
          this.loadClientList();
        } else {
          this.toastr.error(
            'There was a problem updating the status, please try again.',
            'Error'
          );
        }
      })
    );
  }

  getStatusTypeName(statusTypeId: number | undefined) {
    const foundItem = (this.statusTypeList || []).find((item) => item.statusTypeId === statusTypeId);
    return foundItem ? foundItem.statusName : void 0;
  }

  getCustomerTypeName(customerTypeCode: string | undefined) {
    const foundItem = (this.customerTypeList || []).find((item) => item.customerTypeCode === customerTypeCode);
    return foundItem ? foundItem.name : void 0;
  }

  onDisable(value: INGCarrierClientModel) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '600px'
    });
    dialogRef.componentInstance.title = 'Disable Customer';
    dialogRef.componentInstance.message =
      'Are you sure you want to disable this customer?';
    dialogRef.componentInstance.messageDetails = [
      'All licenses and subscriptions will also be disabled.',
    ];
    dialogRef.componentInstance.messageIcon = IconRefEnum.BpWarning;
    dialogRef.componentInstance.cancelBtn = 'Cancel';
    dialogRef.componentInstance.acceptBtn = 'Confirm';
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && value.ngCarrierClientId) {
        this.disableClient(value.ngCarrierClientId, value.modifiedBy);
      }
    });
  }

  disableClient(clientId: number, modifiedBy?: string) {
    this._subs.push(
      this.licenseService.disableClient(clientId, modifiedBy).subscribe((n) => {
        if (n.success) {
          this.toastr.success('Customer has been disabled successfully', 'Success');
          this.loadClientList();
        } else {
          const messageModel = {
            title: 'Disable Customer',
            message: 'Unable to disable this customer due to the following:'
          }
          this.messageService.showErrorModal(messageModel, n.errorMessages);
        }
      })
    );
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }
}
