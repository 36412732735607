<div class="filter-management-grid">
  <app-filter-grid
    [searchPlaceholder]="'Search by Customer ID, Name, Customer Number'"
    [categoryList]="filterCategoryList"
    [filterByModel]="filterByModel"
    (changeCategory)="onChangeCategory($event)"
    (changeFilter)="onChangeFilter($event)"
    [advancedIsOpen]="advancedIsOpen">
  </app-filter-grid>
<div>
  <div *ngIf="!advancedIsOpen" class="advanced-filter filter-input" (click)="onOpenAdvencedFilter()">
    <span *ngIf="!advancedIsOpen" [bpIcon]="filterIcon"></span>
    <label [innerText]="'Advanced Filter'"></label>
  </div>
  <div *ngIf="advancedIsOpen" class="advanced-filter filter-input" (click)="onOpenAdvencedFilter()">
    <span *ngIf="advancedIsOpen" [bpIcon]="closeIcon"></span>
    <label [innerText]="'Exit Advanced Filter'"></label>
  </div>
</div>
</div>

