import { Injectable } from '@angular/core';
import { formatDate, formatNumber, formatCurrency } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class CommonHelperService {

  changeDateFormat(date: string | number | Date, format: string, locale = 'en-US'): string {
    return formatDate(date, format, locale);
  }

  convertDateTimeToUTC(date: string | number | Date): string {
    if (date) {
      const inputDate = new Date(date);
      let utcDate = inputDate.getUTCDate() as any;
      if (String(utcDate).length !== 2) {
        utcDate = '0' + utcDate;
      }
      const utcYear = inputDate.getUTCFullYear();
      let utcMonth = inputDate.getUTCMonth() + 1 as any;
      if (String(utcMonth).length !== 2) {
        utcMonth = '0' + utcMonth;
      }
      let utcHours = inputDate.getUTCHours() as any;
      if (String(utcHours).length !== 2) {
        utcHours = '0' + utcHours;
      }
      let utcMinutes = inputDate.getUTCMinutes() as any;
      if (String(utcMinutes).length !== 2) {
        utcMinutes = '0' + utcMinutes;
      }
      let utcSeconds = inputDate.getUTCSeconds() as any;
      if (String(utcSeconds).length !== 2) {
        utcSeconds = '0' + utcSeconds;
      }
      return utcMonth + '/' + utcDate + '/' + utcYear + ' ' + utcHours + ':' + utcMinutes + ':' + utcSeconds;
    }
    return '';
  }

  getFormattedFileSize(sizeInBytes: number) {
    const thresh = 1024; // si ? 1000 : 1024;
    if (Math.abs(sizeInBytes) < thresh) {
      return sizeInBytes + ' B';
    }
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    // const units = si
    //     ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
    //     : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
    let u = -1;
    do {
      sizeInBytes /= thresh;
      ++u;
    } while (Math.abs(sizeInBytes) >= thresh && u < units.length - 1);
    return sizeInBytes.toFixed(1) + ' ' + units[u];
  }

  newGuid(): any {
    // tslint:disable-next-line:only-arrow-functions
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      // tslint:disable-next-line:one-variable-per-declaration
      const r = (Math.random() * 16) | 0,
        // tslint:disable-next-line:no-bitwise
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  changeNumberFormat(inputNumber: number, locale: string = 'en-US'): string {
    if (inputNumber) {
      return formatNumber(inputNumber, locale);
    }
    return '';
  }

  formatCurrencyValue(amount: any, noCurrencyFormat?: boolean): string {
    let formattedAmount = '';
    if (amount) {
      const receivedAmount = amount + '';
      if (receivedAmount.includes('.')) {
        const preDotAmount = receivedAmount.substring(0, receivedAmount.indexOf('.'));
        let postDotAmount = receivedAmount.substr(receivedAmount.indexOf('.'));
        if (postDotAmount && postDotAmount.length === 1) {
          postDotAmount += '00';
        } else if (postDotAmount && postDotAmount.length === 2) {
          postDotAmount += '0';
        }
        formattedAmount = preDotAmount + postDotAmount;
      } else {
        formattedAmount = receivedAmount + '.00';
      }
    } else {
      formattedAmount = '0.00';
    }
    if (!noCurrencyFormat) {
      formattedAmount = formatCurrency(Number(formattedAmount), 'en-US', '');
    }
    return formattedAmount;
  }

  isNullOrWhitespace(input: any): boolean {
    if (typeof input === 'undefined' || input == null) return true;
    return input.replace(/\s/g, '').length < 1;
  }

  isNull(input: any): boolean {
    return typeof input === 'undefined' || input == null;
  }

  addTimeToDate(date: Date | string, time: string) {
    const newDate = new Date(date);
    const timing = time.split(':');
    if (timing && timing.length > 0) {
      const hours = +timing[0];
      const minutes = +timing[1]
      const seconds = +timing[2]
      newDate.setHours(hours);
      newDate.setMinutes(minutes);
      if(seconds) newDate.setSeconds(seconds);
    } else {
      return null;
    }

    return newDate;
  }

  extractHoursAndMinutesFromDate(date:Date|string) {
    var d = new Date(date),
    h = (d.getHours()<10?'0':'') + d.getHours(),
    m = (d.getMinutes()<10?'0':'') + d.getMinutes();
    return h + ':' + m;
  }

  getStatusByID(id: number) {
    let statusLabel = ''
    switch (id) {
      case 1:
        statusLabel = 'Active'
        break;
      case 2:
        statusLabel = 'Inactive'
        break;
      case 3:
        statusLabel = 'Pending'
        break;
      case 4:
        statusLabel = 'Suspended'
        break;
      case 5:
        statusLabel = 'Pending Activation'
        break;
      case 6:
        statusLabel = 'Expired'
        break;
      case 7:
          statusLabel = 'Disabled'
          break;
    }
    return statusLabel
  }
}
