import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IconRefEnum, DateTimeService } from 'library';
import { DetailHeader } from '../activity-history-dialog/activity-history-dialog.component';

@Component({
  selector: 'app-app-activity-history-details-dialog',
  templateUrl: './activity-history-details-dialog.component.html',
  styleUrls: ['./activity-history-details-dialog.component.scss'],
})
export class ActivityHistoryDetailsDialogComponent implements OnInit, OnDestroy {

   @Input() differenceSections!: any;
   @Input() selectedActivity!: any;
   @Input() extraDetailHeaders: DetailHeader[] = [];

   @Output() onSectionSelected = new EventEmitter<any>();

    closeIcon = IconRefEnum.BpClose;
    headerLabels!: any;

    constructor(
        private dialogRef: MatDialogRef<ActivityHistoryDetailsDialogComponent>,
        private dateTimeServices: DateTimeService,
    ) {
    }

    ngOnInit() {
        this.headerLabels = {
            propertyName: 'Section',
            oldValue: 'Previous value',
            newValue: 'New Value',
        }
    }

    ngOnDestroy() { }

    onCloseModal(): void {
        this.dialogRef.close();
    }

    sectionSelected(sectionDifferences: any) {
        this.onSectionSelected.emit(sectionDifferences);
    }

    formatDate(date: any) {
        return this.dateTimeServices.toLocalTime(date, true)
    }

}
