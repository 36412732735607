import { Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { Regex } from '../../enums/regex';
import { CountryCodeEnum } from '../../enums/countryCodeEnum';

export class BpFieldGroup {
  hasError = true;
  _error: ValidationErrors = {};
  errorsList: ValidationErrors[] = [];
  _resetForm: EventEmitter<null> = new EventEmitter();
  _setAllFieldUntouched: EventEmitter<null> = new EventEmitter();
  _setAllFieldDirty: EventEmitter<null> = new EventEmitter();
  _validateAll: EventEmitter<null> = new EventEmitter();

  constructor(
    public shouldSubmitWithErrors?: boolean
  ) { }

  setAllFieldsDirty() {
    this._setAllFieldDirty.emit();
  }

  validateAllFields() {
    this._validateAll.emit();
  }

  resetForm() {
    this._resetForm.emit();
  }

  setAllFieldUntouched() {
    this._setAllFieldUntouched.emit();
  }

  __checkForErrors() {
    this.hasError = false;
    this.errorsList = [];
    Object.keys(this._error).forEach(key => {
      if (this._error[key] != null) {
        this.hasError = true;
        this.errorsList.push(this._error[key]);
      }
    });
  }

}

export class BpFieldControl {
  hasError = false;
  error!: ValidationErrors;
  _id!: number;
  _resetField: EventEmitter<null> = new EventEmitter();
  _setUntouched: EventEmitter<null> = new EventEmitter();
  _setDirty: EventEmitter<null> = new EventEmitter();
  _validateField: EventEmitter<null> = new EventEmitter();
  _isInitialized = false;

  constructor(
    public validatorList: BpValidatorItem[],
    public fieldGroup?: BpFieldGroup
  ) { }

  intialize() {
    this._isInitialized = true;
    if (this.fieldGroup) {
      this._id = Math.random();

      this.fieldGroup._resetForm.subscribe(() => {
        this._resetField.emit();
      });

      this.fieldGroup._setAllFieldUntouched.subscribe(() => {
        this._setUntouched.emit();
      });

      this.fieldGroup._setAllFieldDirty.subscribe(() => {
        this._setDirty.emit();
      });

      this.fieldGroup._validateAll.subscribe(() => {
        this.validateField();
      });
    }
  }

  setValidators(validatorList: BpValidatorItem[]) {
    this.validatorList = validatorList;
    this.validateField();
  }

  validateField() {
    this._validateField.emit();
  }

  setDirty() {
    this._setDirty.emit();
  }

  setUntouched() {
    this._setUntouched.emit();
  }

  resetField() {
    this._resetField.emit();
  }

  __setError() {
    if (!this.fieldGroup) { return; }
    if (!this._isInitialized) { this.intialize(); }

    this.fieldGroup._error[this._id] = this.error;
    this.fieldGroup.__checkForErrors();
  }
}

export interface BpValidatorItem {
  validator: ValidatorFn;
  errorMsg?: string;
}

export interface BpValueControl {
  value: any;
}

// @dynamic
export class BpValidators extends Validators {

  static validateStrictEmail(control: BpValueControl): any {
    if (!control.value || control.value.match(Regex.StrictEmail)) {
      return null;
    } else {
      return { invalidEmail: true };
    }
  }

  static validatePhone(countryCode?: string, appliedMasking?: boolean): ValidatorFn {
    return (control: BpValueControl): { [key: string]: boolean } | null => {
      if (control.value) {
        switch (countryCode && countryCode.toLowerCase()) {
          // USA Validator
          case CountryCodeEnum.US.toLowerCase(): {
            return this.fieldValidator(control, Regex.PhoneUS, appliedMasking, [10]);
          }
          // CANADA Validator
          case CountryCodeEnum.CA.toLowerCase(): {
            return this.fieldValidator(control, Regex.PhoneCA, appliedMasking, [10]);
          }
          // Default Validator
          default: {
            return this.fieldValidator(control, Regex.Phone);
          }
        }
      } else {
        return null;
      }
    };
  }

  static validatePostalCode(countryCode?: string, appliedMasking?: boolean): ValidatorFn {
    return (control: BpValueControl): { [key: string]: boolean } | null => {
      if (control.value) {
        switch (countryCode && countryCode.toLowerCase()) {
          // USA Validator
          case CountryCodeEnum.US.toLowerCase(): {
            return this.fieldValidator(control, Regex.PostalCodeUS, appliedMasking, [5, 9]);
          }
          // CANADA Validator
          case CountryCodeEnum.CA.toLowerCase(): {
            return this.fieldValidator(control, Regex.PostalCodeCA, appliedMasking, [6]);
          }
          // Default Validator
          default: {
            return this.fieldValidator(control, Regex.PostalCode);
          }
        }
      } else {
        return null;
      }
    };
  }

  static validateWebsite(control: BpValueControl): any {
    if (!control.value || control.value.match(Regex.Website)) {
      return null;
    } else {
      return { invalidWebSite: true };
    }
  }

  static validateStrictRequired(control: BpValueControl): any {
    if (control.value) {
      const inputValue = control.value.toString().trim();
      if (!inputValue || (inputValue && !(inputValue.match(Regex.StrictRequire)))) {
        return { strictlyRequired: true };
      } else {
        return null;
      }
    } else {
      return { strictlyRequired: true };
    }
  }

  private static fieldValidator(control: BpValueControl, regex?: RegExp, isMasked?: boolean, validlengths?: any, minValue?: number, maxValue?: number) {
    if (isMasked) {
      return this.checkDataWithMask(control, validlengths, minValue, maxValue);
    } else {
      return this.checkDataWithoutMask(control, regex);
    }
  }

  private static checkDataWithoutMask(control: BpValueControl, regex: RegExp|any): { [key: string]: boolean } | null {
    if (control.value.match(regex)) {
      return null;
    }
    return { invalidFormat: true };
  }

  private static checkDataWithMask(control: BpValueControl, validLengths: Array<number>, minValue?: number, maxValue?: number): { [key: string]: boolean } | null {
    let isValid = false;
    if (validLengths && validLengths.length > 0) {
      for (const validLength of validLengths) {
        if (control.value.length === validLength) {
          isValid = true;
          break;
        }
      }
    }
    if (minValue && maxValue) {
      if (control.value.length >= minValue && control.value.length <= maxValue) {
        isValid = true;
      } else {
        isValid = false;
      }
    } else if (minValue) {
      if (control.value.length >= minValue) {
        isValid = true;
      } else {
        isValid = false;
      }
    } else if (maxValue) {
      if (control.value.length <= maxValue) {
        isValid = true;
      } else {
        isValid = false;
      }
    }
    if (isValid) {
      return null;
    }
    return { invalidFormat: true };
  }

}
