import { Component, Input, OnInit } from '@angular/core';
import {  FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IconRefEnum } from 'library';
import { ApplicationType, AuthenticationIN, AuthenticationType, BpStatefulTextbox, ClientInstanceAuthenticationKey, DinamicAppSettingUrl, IBondProNGCarrierClientDto, INGCarrierClientInstanceDto, INGLicenseCheckDuplicate, INGLicenseTypeInstanceDto, ServiceAuthentication } from '../../../interfaces/license-interfaces';
import { ActivityHistoryService, ActivityKey, SearchCriteriaOperator, SearchOperator } from '../../../services/activity-history.service';
import { ActivityHistoryDialogComponent } from '../../activity-history/activity-history-dialog/activity-history-dialog.component';
import { LicenseService } from '../../../services/license.service';
import { Subscription, debounceTime, forkJoin } from 'rxjs';
import { faPlusCircle, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { BpTabsModel } from '../../../services/route-tabs.service';

@Component({
  selector: 'app-add-tenant-modal',
  templateUrl: './add-tenant-modal.component.html',
  styleUrls: ['./add-tenant-modal.component.scss'],
})
export class AddTenantModalComponent implements OnInit {
  @Input() instanceModal!: INGCarrierClientInstanceDto;
  @Input() clientModel!: IBondProNGCarrierClientDto;
  clientForm!: FormGroup;
  closeIcon = IconRefEnum.BpClose;
  editIcon = IconRefEnum.Edit;
  editMode: boolean = false;
  licenseTypeList!: INGLicenseTypeInstanceDto[];
  addIcon = faPlusCircle;
  editFaIcon = faPencil;
  removeIcon = faTrash;
  tabSelected: any;
  applicationTypes: ApplicationType[] = [];
  serviceURL: any;
  applicationTypesList: Array<BpTabsModel> = [];
  appSettingUrlsList: Array<DinamicAppSettingUrl> = [];
  authenticationTypes: AuthenticationType[] = [];
  authenticationINList: AuthenticationIN[] = [];
  serviceAuthenticationList: ServiceAuthentication[] = [];
  stateCheckDuplicate: string = '';
  nameValidationMessage: string = '';
  isSubSourceLinkedWithInternal: boolean = true;

  activityIcon = IconRefEnum.History;

  private _subs = [] as Subscription[];

  constructor(
    public dialogRef: MatDialogRef<AddTenantModalComponent>,
    private formBuilder: FormBuilder,
    private activityHistoryService: ActivityHistoryService,
    public dialog: MatDialog,
    private licenseService: LicenseService,
  ) {}

  ngOnInit() {
    this.getIntegrationDetail();
    this.getAuthenticationKeys();
    this.getDataForDropdowns();
    this.buildForm();
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  buildForm(): void {
    const authenticationKeysFormControls = (this.instanceModal?.authenticationKeys || []).map((a) => this.createAuthenticationKey(a))

    this.clientForm = this.formBuilder.group({
      databaseName: [this.instanceModal?.databaseName, [Validators.required, Validators.maxLength(128)]],
      databaseServerIPAddress: [this.instanceModal?.databaseServerIPAddress, [Validators.maxLength(32)]],
      databaseServerName: [this.instanceModal?.databaseServerName, [Validators.maxLength(128)]],
      appVersion: [this.instanceModal?.appVersion, [Validators.maxLength(64)]],
      subSource: [this.instanceModal?.subSource, [Validators.required, Validators.maxLength(64)]],
      dbVersion: [this.instanceModal?.dbVersion, [Validators.maxLength(64)]],
      authenticationKeys: this.formBuilder.array(authenticationKeysFormControls),
      stateCheckDuplicate : [this.stateCheckDuplicate,this.ValidateDatabaseNameDupe.bind(this)],
      appSettingUrls: this.formBuilder.group({
        b2BServiceURL: [this.instanceModal?.appSettingUrls?.b2BServiceURL],
        b2BApplicationURL: [this.instanceModal?.appSettingUrls?.b2BApplicationURL],
        b2BAuthenticationTypeCode: [this.instanceModal?.appSettingUrls?.b2BAuthenticationTypeCode],
        b2BAuthenticationURL: [this.instanceModal?.appSettingUrls?.b2BAuthenticationURL],
        portalServiceURL: [this.instanceModal?.appSettingUrls?.portalServiceURL],
        portalApplicationURL: [this.instanceModal?.appSettingUrls?.portalApplicationURL],
        portalAuthenticationTypeCode: [this.instanceModal?.appSettingUrls?.portalAuthenticationTypeCode],
        portalAuthenticationURL: [this.instanceModal?.appSettingUrls?.portalAuthenticationURL],
        fDServiceURL: [this.instanceModal?.appSettingUrls?.fDServiceURL],
        fDApplicationURL: [this.instanceModal?.appSettingUrls?.fDApplicationURL],
        fDAuthenticationTypeCode: [this.instanceModal?.appSettingUrls?.fDAuthenticationTypeCode],
        fDAuthenticationURL: [this.instanceModal?.appSettingUrls?.fDAuthenticationURL],
      }),
    });
    if (this.instanceModal) {
      this.clientForm.disable();
    }
    this.clientForm.get('appVersion')?.disable()
    if (!this.instanceModal) {
      this.linkDatabaseNameAndSubSourceChanges()
    }
  }

  linkDatabaseNameAndSubSourceChanges() {
    this._subs.push(
      this.clientForm.controls.databaseName.valueChanges
        .pipe(
          debounceTime(200)
        )
        .subscribe(val => {
          if (this.isSubSourceLinkedWithInternal) {
            this.clientForm.controls['subSource'].setValue(val, { emitEvent: false });
          }
        })
    );
    this._subs.push(
      this.clientForm.controls.subSource.valueChanges
        .pipe(
          debounceTime(200)
        )
        .subscribe(val => {
          this.isSubSourceLinkedWithInternal = false;
        })
    );
  }

  ValidateDatabaseNameDupe(ctrl:any): { [key: string]: boolean } | null{
    if(ctrl.value === BpStatefulTextbox.ERROR){
     return { 'InvalidDatabaseName': true }
    }
    return null;
  }

  createAuthenticationKey(authenticationKey?: ClientInstanceAuthenticationKey) {
    const newAuthenticationKey = this.formBuilder.group({
      authenticationINCode: [authenticationKey?.authenticationINCode, []],
      serviceAuthenticationCode: [authenticationKey?.serviceAuthenticationCode, []],
      key: [authenticationKey?.key, [Validators.maxLength(64)]],
      value: [authenticationKey?.value, []],
      applicationTypeCode: [authenticationKey?.applicationTypeCode, []],
      applicationTypeName: [authenticationKey?.applicationTypeName, []],
    })
    return newAuthenticationKey;
  }

  get authenticationKeysFormArray() : FormArray {
    return this.clientForm.get('authenticationKeys') as FormArray;
  }

  addNewAuthenticationKey() {
    const newItem = this.createAuthenticationKey()
    newItem.controls['applicationTypeCode'].setValue(this.applicationTypes.find(type => type.name === this.tabSelected.label)?.applicationTypeCode)
    newItem.controls['applicationTypeName'].setValue(this.applicationTypes.find(type => type.name === this.tabSelected.label)?.name)
    this.authenticationKeysFormArray.push(newItem);
  }

  removeAuthenticationKey(i: number) {
    this.authenticationKeysFormArray.removeAt(i);
  }

  onEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.clientForm.enable();
      this.clientForm.get('appVersion')?.disable()
    }
  }

  onSave() {
    const instanceModel: INGCarrierClientInstanceDto = this.clientForm.getRawValue();
    instanceModel.authenticationKeys = (instanceModel.authenticationKeys || []).map((item) => {
      return {
        ...item,
        authenticationINName: this.getAuthenticationINName(item.authenticationINCode),
        serviceAuthenticationName: this.getServiceAuthenticationName(item.serviceAuthenticationCode),
      }
    });
    if (this.instanceModal) {
      instanceModel.dbCorporationId = this.instanceModal.dbCorporationId;
    }
    this.dialogRef.close({ event: 'close', data: instanceModel });
  }

  onTabChange(event: any) {
    this.tabSelected = event.tabSelected

    this.appSettingUrlsList.forEach(type => {
      if (this.tabSelected.code === type.code){
        type.isSelected = true
      } else {
        type.isSelected = false
      }
    })
  }

  onValueCustomInputChange(event: any) {
    if (event?.text){
      const requestDto = {
        nGCarrierClientInstanceID: this.instanceModal?.ngCarrierClientInstanceId,
        databaseName: event.text
      } as INGLicenseCheckDuplicate;

      this._subs.push(
        this.licenseService.checkDuplicateClientInstanceName(requestDto).subscribe((dupe) => {
          if (dupe) {
            this.stateCheckDuplicate = BpStatefulTextbox.ERROR
            this.nameValidationMessage = 'This Name already exists, Duplicate not allowed'
          }else
          {
            this.stateCheckDuplicate = BpStatefulTextbox.SUCCESS
            this.nameValidationMessage = ''
          }
          this.clientForm.controls['stateCheckDuplicate'].setValue(this.stateCheckDuplicate);
        })
      );
    } else {
      this.nameValidationMessage = ''
      this.stateCheckDuplicate = ''
      this.clientForm.controls['stateCheckDuplicate'].setValue(this.stateCheckDuplicate);
    }
  }

  getAuthenticationTypeName(code: string) {
    const foundItem = (this.authenticationTypes || []).find(
      (item) => item.authenticationTypeCode === code
    );
    return foundItem ? foundItem.name : void 0;
  }

  getAuthenticationINName(code: string) {
    const foundItem = (this.authenticationINList || []).find(
      (item) => item.authenticationINCode === code
    );
    return foundItem ? foundItem.name : void 0;
  }

  getServiceAuthenticationName(code: string) {
    const foundItem = (this.serviceAuthenticationList || []).find(
      (item) => item.serviceAuthenticationCode === code
    );
    return foundItem ? foundItem.name : void 0;
  }

  getApplicationTypeName(code?: string) {
    const foundItem = (this.applicationTypes || []).find(
      (item) => item.applicationTypeCode === code
    );
    return foundItem ? foundItem.name : void 0;
  }

  getIntegrationDetail() {

    this._subs.push(
      this.licenseService
        .getApplicationTypes()
        .subscribe((response: ApplicationType[]) => {
          this.applicationTypes = response
          this.applicationTypes.forEach(type => {
            this.applicationTypesList.push({code: type.applicationTypeCode, label: type.name, isDisabled: false, isHidden: false, customClass: '', onClick: () => {}})
          })
          this.appSettingUrlsList.push({isSelected: true, code: 'B2B_INTEGRATION', label:'B2B', serviceURL: 'b2BServiceURL', applicationURL: 'b2BApplicationURL', authenticationTypeCode: 'b2BAuthenticationTypeCode', authenticationURL: 'b2BAuthenticationURL'})
          this.appSettingUrlsList.push({isSelected: false, code: 'PORTAL_INTEGRATION', label:'Portal', serviceURL: 'portalServiceURL', applicationURL: 'portalApplicationURL', authenticationTypeCode: 'portalAuthenticationTypeCode', authenticationURL: 'portalAuthenticationURL'})
          this.appSettingUrlsList.push({isSelected: false, code: 'FORMS_DESIGNER_INTEGRATION', label:'Forms Designer', serviceURL: 'fDServiceURL', applicationURL: 'fDApplicationURL', authenticationTypeCode: 'fDAuthenticationTypeCode', authenticationURL: 'fDAuthenticationURL'})
        })
    );
  }

  onCloseModal() {
    this.dialogRef.close();
  }

  getDataForDropdowns() {
    this._subs.push(
      forkJoin([
        this.licenseService.getAuthenticationTypes(),
        this.licenseService.getAuthenticationIN(),
        this.licenseService.getServiceAuthenticationList(),
      ])
      .subscribe(([authenticationTypes, authenticationINList, serviceAuthenticationList]) => {
        this.authenticationTypes = authenticationTypes;
        this.authenticationINList = authenticationINList;
        this.serviceAuthenticationList = serviceAuthenticationList;
      })
    )
  }

  getAuthenticationKeys() {
    if (!this.instanceModal?.ngCarrierClientInstanceId) {
      return
    }
    this._subs.push(
      this.licenseService
        .getClientInstanceAuthenticationKeys(this.instanceModal.ngCarrierClientInstanceId as number)
        .subscribe((response: ClientInstanceAuthenticationKey[]) => {
          this.instanceModal.authenticationKeys = response;
          this.buildForm();
        })
    );
  }

  showActivity() {
    const dialogRef = this.dialog.open(ActivityHistoryDialogComponent, {
      maxHeight: '100vh'
    });
    dialogRef.componentInstance.classification = 'ClientInstance';
    dialogRef.componentInstance.extraColumns = [
      {
        field: 'DataBaseName',
        title: 'DataBase Name',
      }
    ]
    dialogRef.componentInstance.extraDetailHeaders = [
      {
        key: 'TenantId',
        label: 'Tenant ID'
      },
      {
        key: 'DataBaseName',
        label: 'DataBase Name'
      },
    ];
    dialogRef.componentInstance.extraGridDetailHeaders = [
      {
        value: this.clientModel.ngCarrierClientId,
        label: 'Client ID'
      },
      {
        value: this.clientModel.clientName,
        label: 'Client Name'
      },
      {
        value: this.instanceModal.ngCarrierClientInstanceId,
        label: 'Tenant ID',
      }
    ];
    dialogRef.componentInstance.diffConfig = {
      historicalFields: ['CreatedBy', 'CreateDate', 'ModifyDate', 'ModifiedBy'],
      onlyDateFields: [],
      customPropertyFormatters: {},
      rootPropertySection: 'Instance Section',
    }
    const tenantIdCriterias = this.activityHistoryService.buildCriteriaFilter(
      'ClientInstanceId',
      this.instanceModal.ngCarrierClientInstanceId
    )
    dialogRef.componentInstance.extraSearchCriterias = tenantIdCriterias;
    dialogRef.componentInstance.filterConfig = {
      enabled: true,
      placeholder: 'Search by Description',
      buildSearchCriterias: (searchValue) => {
        let criterias: any[] = [];
        if (!searchValue) {
          return criterias;
        }
        const descriptionCriterias = [
          {
            Operator: SearchOperator.AND,
            Criteria: {
              Operator: SearchCriteriaOperator.EQ,
              Key: ActivityKey.Description,
              Value: searchValue,
            },
          },
        ]
        criterias = criterias.concat(descriptionCriterias)
        return criterias;
      }
    }
    dialogRef.afterClosed().subscribe((statusTypeId: any) => {
      console.log('status type id', statusTypeId)
      if (statusTypeId) {}
    });
  }
}
