import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class BpDropDownService {

    private selectedOptions =  new Subject<any>();
    private initialSelectedValues = new BehaviorSubject(null);

    getSelectedOptionsObservable$(): Observable<IBpDropDownEmit> {
        return this.selectedOptions.asObservable();
    }
    setSelectedOptions(emitValue: IBpDropDownEmit): void {
        this.selectedOptions.next(emitValue);
    }

    getInitialSelectedValuesObservable$(): Observable<Array<number>|any> {
        return this.initialSelectedValues.asObservable();
    }
    setInitialSelectedValues(selectedValues: Array<number>|any) {
        this.initialSelectedValues.next(selectedValues);
    }
}

export interface IBpDropDownEmit {
    selectedValues: Array<number>;
    options: any;
}

