<div id="ng_license">
  <app-license-filter
    [disabled]="disabled"
    [clientId]="clientId"
    [licenseSelected]="licenseSelected"
    [statusList]="statusList"
    [clientModel]="clientModel"
    (reloadLicenseList)="onFilterApply($event)">
  </app-license-filter>
  <bpe-grid
    [isSelectable]="enabledOptions"
    [showViewMore]="enabledOptions"
    [columnDefs]="columnDefs"
    [data]="licenseLicense"
    [optionsTmpl]="optionsTmpl"
    [disabled]="disabled"
    (onViewDetails)="onOpenEditModal($event)"
    [pagination]="pagination"
    (onPageChange)="onPageChange($event)"
    (onSort)="onSortChange($event)"
    (onSelectionChange)="onSelectionChange($event)">
  </bpe-grid>
</div>

<ng-template #optionsTmpl let-item="item">
  <button mat-menu-item class="item-btn" (click)="onOpenEditStatus(item)">
    <span>Modify status</span>
  </button>
  <button mat-menu-item class="item-btn" (click)="onOpenRemoveModal(item)">
    <span>Remove</span>
  </button>
</ng-template>
