<div class="activity-history-dialog">
    <div class="header">
        <div class="title">
            <span [innerText]="'Activity'"></span>
        </div>
        <div>
            <span [bpIcon]="closeIcon" (click)="onCloseModal()"></span>
        </div>
    </div>

    <mat-dialog-content class="dialog-content">
        <div class="main-content">
            <form *ngIf="filterConfig && filterConfig.enabled" [formGroup]="formFilter" class="filter-grid">
                <div class="bp-form-field bp-search-input filter-input">
                  <input type="text" [placeholder]="filterConfig.placeholder" formControlName="search" matInput>
                  <span [bpIcon]="searchIcon"></span>
                </div>
            </form>

            <div *ngIf="extraGridDetailHeaders && extraGridDetailHeaders.length > 0" class="model-details">
                <div *ngFor="let header of extraGridDetailHeaders" class="detail">
                  <label>{{header.label}}</label>
                  <label class="value">{{header.value}}</label>
                </div>
            </div>
            <div class="main-content__grid">
                <bpe-grid
                    [showViewMore]="showViewMore"
                    [columnDefs]="columnDefs"
                    [data]="historyData.items"
                    [optionsTmpl]="myTemplate"
                    [pagination]="pagination"
                    (onPageChange)="onPageChange($event)"
                    (onSort)="onSort($event)"
                    (onViewDetails)="onGoDetails($event)">
                </bpe-grid>
            </div>
        </div>

        <div class="btn-container buttons-right">
            <button type="button" class="secondary md" (click)="onCloseModal()">Close</button>
        </div>
    </mat-dialog-content>
</div>


<ng-template #myTemplate let-item="item">
</ng-template>