import { Directive, Output, EventEmitter, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[bpClickOutside]'
})
export class BpClickOutsideDirective {
    @Input() keepElementClassList: Array<string> = [];
    @Output() public clickOutside = new EventEmitter();

    constructor(private _elementRef: ElementRef) { }

    @HostListener('document:mousedown', ['$event.target'])
    public onClick(targetElement: HTMLElement) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        const noOfClasses = this.keepElementClassList.length;
        let clickedKeepElement = false;
        if (this.keepElementClassList && noOfClasses > 0) {
            for (let i = 0; i < noOfClasses; i++) {
                const elements = document.getElementsByClassName(this.keepElementClassList[i]);
                if (elements && elements.length > 0) {
                    if (elements[0].contains(targetElement)) {
                        clickedKeepElement = true;
                    } else {
                        clickedKeepElement = false;
                        break;
                    }
                }
            }
        }
        if (!clickedInside && !clickedKeepElement) {
            this.clickOutside.emit(true);
        }
    }
}
