
  <span style="display: inline-block;line-height: 0">
      <ol Class="breadcrumb">
          <li *ngFor="let breadcrumb of breadcrumbs; let last = last" class="breadcrumb-item"
              [ngClass]="{'active': last || breadcrumb.isReadonly }">
              <a *ngIf="!last && !breadcrumb.isReadonly" [routerLink]="hasParams(breadcrumb)">
                  {{breadcrumb.label}}
              </a>
              <span *ngIf="last || breadcrumb.isReadonly">{{ breadcrumb.label }}</span>
          </li>
      </ol>
    </span>

