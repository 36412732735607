import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import {MatExpansionModule} from '@angular/material/expansion';

const materialModules = [
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatSelectModule,
  TooltipsModule,
  MatExpansionModule,
];

@NgModule({
  declarations: [],
  imports: [...materialModules],
  exports: [...materialModules],
})
export class MaterialModule {}
