export enum IconRefEnum {
    AccessTime = 'access_time',
    Add = 'add',
    AddCircle = 'add_circle_outline',
    Attach = 'attach',
    Attachment = 'attach_file',
    Back = 'back',
    Calendar = 'calendar',
    Clone = 'clone',
    Close = 'close',
    CloudDownload = 'cloud_download',
    CollapseList = 'collapse-list',
    Delete = 'delete',
    Document = 'document',
    Down = 'down',
    Download = 'download',
    Done = 'done',
    Edit = 'edit',
    Exit = 'exit',
    Expand = 'expand',
    ExpandList = 'expand-list',
    Export = 'export',
    Filter = 'filter',
    Folder = 'folder',
    ForkRight = 'fork_right',
    Full = 'full',
    Globe = 'globe',
    Graph = 'graph',
    Grid = 'grid',
    Help = 'help',
    History = 'history',
    Home = 'home',
    Industry = 'industry',
    List = 'list',
    Lock = 'lock',
    Mail = 'mail',
    Menu = 'menu',
    Min = 'min',
    Notification = 'notification',
    Option = 'option',
    Print = 'print',
    Remove = 'remove',
    Reply = 'reply',
    ResizeFull = 'resize-full',
    ResizeSmall = 'resize-small',
    RemoveCircle = 'remove_circle_outline',
    Save = 'save',
    Settings = 'settings',
    Send = 'send',
    Share = 'share',
    SwapVertical = 'swap_vert',
    SwapVerticalCircle = 'swap_vertical_circle',
    User = 'user',
    Users = 'users',
    View = 'visibility',
    Workflow = 'workflow',
    Person = 'person',
    LeftArrow = 'keyboard_arrow_left',
    RightArrow = 'keyboard_arrow_right',
    Search = 'search',
    FileCopy = 'file_copy',
    UpArrow = 'keyboard_arrow_up',
    DownArrow = 'keyboard_arrow_down',
    Redo = 'redo',
    Undo = 'undo',
    Upload = 'cloud_upload',
    Contacts = 'contacts',
    AttachMoney = 'attach_money',
    // --------------- BP Set
    BpDocument = 'bpi-Document',
    BpDocumentView = 'bpi-Document-View',
    BpBondAmount = 'bpi-Bond-Amount',
    BpBondLiability = 'bpi-Bond-Liability',
    BpCancelEndorsement = 'bpi-Cancel',
    BpContractAmount = '.bpi-Contract-Amount',
    BpContractToBid = 'bpi-Convert-To-Bid',
    BpObligeeBondData = 'bpi-Obligee-Bond-Data',
    BpPrincipalBondData = 'bpi-Principal-Bond-Data',
    BpViewBidResult = 'bpi-View-Bid-Result',
    BpActivities = 'bpi-Activities',
    BpTermDate = 'bpi-Term-Date',
    BpCaret = 'bpi-Caret',
    BpDanger = 'bpi-Danger',
    BpHide = 'bpi-Hide',
    BpNotes = 'bpi-Notes',
    BpNotificationActiveFilled = 'bpi-NotificationActiveFilled',
    BpNotificationNoneFilled = 'bpi-NotificationNoneFilled',
    BpNotificationOutline = 'bpi-NotificationOutline',
    BpTasks = 'bpi-Tasks',
    BpUserTasks = 'bpi-User-Tasks',
    BpCheckMark = 'bpi-CheckMark',
    BpMenu = 'bpi-Menu',
    BpAccountAvtar = 'bpi-Account-Avatar',
    BpExpandMore = 'bpi-Expand-More',
    BpStep1 = 'bpi-Step1',
    BpStep1Outline = 'bpi-Step1-Outline',
    BpStep2 = 'bpi-Step2',
    BpStep2Outline = 'bpi-Step2-Outline',
    BpStep3 = 'bpi-Step3',
    BpStep3Outline = 'bpi-Step3-Outline',
    BpStep4 = 'bpi-Step4',
    BpStep4Outline = 'bpi-Step4-Outline',
    BpStep5 = 'bpi-Step5',
    BpStep5Outlone = 'bpi-Step5-Outline',
    BpApproved = 'bpi-Approved',
    BpApproval = 'bpi-Approval',
    BpIssue = 'bpi-Issue',
    BpAccount = 'bpi-Account',
    BpAgent = 'bpi-Agent',
    BpAttorneyInFact = 'bpi-Attorney-In-Fact',
    BpBilling = 'bpi-Billing',
    BpBondNumber = 'bpi-Bond-Number',
    BpConsultants = 'bpi-Consultants',
    BpFinancials = 'bpi-Financials',
    BpHardPowerNumber = 'bpi-Hard-Power-Number',
    BpJob = 'bpi-Job',
    BpCloseEndorsement = 'bpi-CloseEndorsement',
    BpComplete = 'bpi-Complete',
    BpReinstate = 'bpi-Reinstate',
    BpChecked = 'bpi-Checked',
    BpUnchecked = 'bpi-Unchecked',
    BpAccountAvatar = 'bpi-Account-Avatar',
    BpAccountLifecycle = 'bpi-Account-Lifecycle',
    BpAgencyPortal = 'bpi-Agency-Portal',
    BpBondLifecycle = 'bpi-Bond-Lifecycle',
    BpBreadcrumbGlobe = 'bpi-Breadcrumb-Globe',
    BpBusinessAdmin = 'bpi-Business-Admin',
    BpCalendar = 'bpi-Calendar',
    BpChevronLeft = 'bpi-Chevron-Left',
    BpChevronRight = 'bpi-Chevron-Right',
    BpClose = 'bpi-Close',
    BpColumns = 'bpi-Columns',
    BpExpandLess = 'bpi-Expand-Less',
    BpFilter = 'bpi-Filter',
    BpIconNeeded = 'bpi-Icon-Needed',
    BpInfo = 'bpi-Info',
    BpMoreVertical = 'bpi-More-Vertical',
    BpMyWorkbench = 'bpi-My-Workbench',
    BpNo = 'bpi-No',
    BpRadioChecked = 'bpi-Radio-Checked',
    BpRadioUnchecked = 'bpi-Radio-Unchecked',
    BpSearch = 'bpi-Search',
    BpSortAscending = 'bpi-Sort-Ascending',
    BpSortDescending = 'bpi-Sort-Descending',
    BpSystemAdmin = 'bpi-System-Admin',
    BpTrash = 'bpi-Trash',
    BpView = 'bpi-View',
    BpYes = 'bpi-Yes',
    BpWarning = 'bpi-Warning',
    BpIconNewOpenWindow = 'bpi-Icons_Open-New-Window',
    BpIconsTimer = 'bpi-Icons_Timer',
    //#region Pure Text - Look like Icons
    TextPercent = 'text-percent',
    TextDollar = 'text-dollar'
}
