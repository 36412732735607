import { Injectable } from '@angular/core';
import { IconRefEnum } from '../enums/iconRefEnum';

@Injectable({
  providedIn: 'root'
})
export class BpHeaderService {
  breadcrumbs: BpBreadcrumbModel[] = [ { label: 'Breadcrumb', onClick: () => {}}];
  userInfo: BpUserModel = {
    username: 'John Caccavaro',
    id: 1
  };
  actionIconsList: BpHeaderActionIconModel[] = [
    { name: IconRefEnum.BpUserTasks, onClick: (ev) => {}},
    { name: IconRefEnum.BpNotificationNoneFilled, onClick: (ev) => {}}
  ];

  constructor() {}
}

export interface BpBreadcrumbModel {
  label: string;
  onClick($event: any): void;
}

export interface BpUserModel {
  username: string;
  id: number;
}

export interface BpHeaderActionIconModel {
  name: IconRefEnum;
  onClick($event: any): void;
}
