import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormGroup } from '@angular/forms';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'bp-mat-chips',
    templateUrl: './bp-mat-chips.component.html',
    styleUrls: ['./bp-mat-chips.component.scss']
})

export class BpMatChipsComponent {
    @ViewChild('chipFormInput') chipFormInput!: ElementRef;

    @Input() chipList: Array<IBpChip> = [];
    @Output() chipListChange = new EventEmitter();

    @Input() chipSeparatorKeysCodes: number[] = [ENTER, COMMA];
    @Input() chipAddOnBlur = true;
    @Input() chipSelectable = false;
    @Input() chipRemovable = true;
    @Input() chipDisabled = false;
    @Input() multiChipSelectable = false;
    @Input() chipAreaLabel = '';
    @Input() chipPlaceholder = '';

    @Input() bpFormGroup!: FormGroup;
    @Input() bpFormControlName!: string;

    // Emits list of chips while add/remove chips
    @Output() chipsValueChanged = new EventEmitter();
    // Emits chip while select/deselect chip
    @Output() chipsSelectionChanged = new EventEmitter();

    addOnBlur = true;

    addChip(event: MatChipInputEvent | any): void {
        const chipInput = event.input;
        const chipValue = event.value;

        // Add chip to chip list
        if ((chipValue || '').trim()) {
            this.chipList.push(
                {
                    value: chipValue.trim(),
                    isSelectable: this.chipSelectable,
                    isRemovable: this.chipRemovable,
                    isDisabled: this.chipDisabled,
                    isSelected: false
                }
            );
            this.chipListChange.emit(this.chipList);
            this.chipsValueChanged.emit(this.chipList);
            if (this.bpFormGroup) {
                this.bpFormGroup.controls[this.bpFormControlName].setValue(this.chipList);
                this.bpFormGroup.controls[this.bpFormControlName].updateValueAndValidity();
            }
        }

        // Reset the input value
        if (chipInput) {
            chipInput.value = '';
        }
    }

    removeChip(chip: IBpChip): void {
        const removeIndex = this.chipList.indexOf(chip);
        if (removeIndex !== -1) {
            this.chipList.splice(removeIndex, 1);
            this.chipListChange.emit(this.chipList);
            this.chipsValueChanged.emit(this.chipList);
            if (this.bpFormGroup) {
                this.bpFormGroup.controls[this.bpFormControlName].setValue(this.chipList);
                this.bpFormGroup.controls[this.bpFormControlName].updateValueAndValidity();
                this.chipFormInput.nativeElement.value = '';
            }
        }
    }

    onSelectionChange(event: any, chip: IBpChip): void {
        if (chip.isSelectable) {
            chip.isSelected = event.selected;
            this.chipsSelectionChanged.emit(chip);
        }
    }
}

export interface IBpChip {
    value: string;
    isSelectable?: boolean;
    isRemovable?: boolean;
    isDisabled?: boolean;
    isSelected?: boolean;
}
