<div class="activity-history-details-dialog">
    <div class="header">
        <div class="title">
            <span [innerText]="'Activity Detail'"></span>
        </div>
        <div>
            <span [bpIcon]="closeIcon" (click)="onCloseModal()"></span>
        </div>
    </div>

    <mat-dialog-content>
        <div class="main-content">
            <div class="model-details">
                <div class="detail">
                  <label [innerText]="'Activity Id'"></label>
                  <label class="value">{{selectedActivity.id}}</label>
                </div>
                <div *ngFor="let header of extraDetailHeaders" class="detail">
                  <label>{{header.label}}</label>
                  <label class="value">{{selectedActivity[header.key]}}</label>
                </div>
                <div class="detail">
                  <label [innerText]="'Section'"></label>
                  <label class="value">{{selectedActivity.section}}</label>
                </div>
                <div class="detail">
                  <label [innerText]="'Created BY'"></label>
                  <label class="value">{{selectedActivity.createdBy}}</label>
                </div>
                <div class="detail">
                  <label [innerText]="'Created Date/Time'"></label>
                  <label class="value">{{formatDate(selectedActivity.createdDateTime)}}</label>
                </div>
            </div>
            <app-activity-history-diff-grid
                [differenceSections]="differenceSections"
                [headerLabels]="headerLabels"
                (onSectionSelected)="sectionSelected($event)"
            ></app-activity-history-diff-grid>
        </div>

        <div class="btn-container buttons-right">
            <button type="button" class="secondary md" (click)="onCloseModal()">Close</button>
        </div>
    </mat-dialog-content>
</div>
