<div class="relationship-details">
    <div class="header">
        <app-filter-grid
            [searchPlaceholder]="'Search by Customer Name'"
            (changeFilter)="onChangeFilter($event)"
            [type]="'text'">
        </app-filter-grid>
        <div class="header__actions">
        <span (click)="showActivity()" class="activity-icon" [bpMatIcon]="activityIcon"></span>
        <button type="button" class="primary md bp-icon-button" (click)="onAddRelationships()" [disabled]="disabled">
            <span class="btn-icon" [bpMatIcon]="addIcon"></span>
            <span class="btn-text">Add</span>
        </button>
        </div>
    </div>
  
    <bpe-grid
      [showViewMore]="showViewMore"
      [columnDefs]="columnDefs"
      [data]="_relationships"
      [disabled]="disabled"
      [optionsTmpl]="myTemplate"
      [pagination]="pagination"
      (onPageChange)="onPageChange($event)"
      (onSort)="onSortChange($event)"
      (onViewDetails)="onEditRelationship()">
    </bpe-grid>
</div>
  
<ng-template #myTemplate let-item="item">
    <button mat-menu-item class="item-btn" (click)="onModifyStatus(item)">
        <span>Modify status</span>
      </button>
    <button mat-menu-item class="item-btn" (click)="onRemove(item)">
        <span>Remove</span>
    </button>
</ng-template>
  