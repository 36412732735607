import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BpBreadCrumbsService, IBreadcrumb } from '../../services/bpBreadcrumbs.service';


@Component({
  selector: 'bp-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadCrumbsComponent {
  public breadcrumbs: IBreadcrumb[] = [];

  constructor(private breadcrumbService: BpBreadCrumbsService, private activatedRoute: ActivatedRoute, private router: Router) {
    breadcrumbService.get().subscribe((breadcrumbs: IBreadcrumb[]) => {
      this.breadcrumbs = breadcrumbs as IBreadcrumb[];
    });
  }

  public hasParams(breadcrumb: IBreadcrumb) {
    if(!breadcrumb.url) return;
    return Object.keys(breadcrumb.params).length ? [breadcrumb.url, breadcrumb.params] : [breadcrumb.url];
  }

}
