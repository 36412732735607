import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IconRefEnum } from 'library';
import { ICustomerTypeDto, INGStatusTypeInstanceDto } from '../../../interfaces/license-interfaces';
import { parseInt,isNaN, } from "lodash";
@Component({
  selector: 'app-filter-management-grid',
  templateUrl: './filter-management-grid.component.html',
  styleUrls: ['./filter-management-grid.component.scss'],
})
export class FilterManagementGridComponent implements OnInit, OnDestroy {
  @Input() advancedIsOpen: boolean = false;
  @Input() statusList: Array<INGStatusTypeInstanceDto> = [];
  @Input() customerTypeList: Array<ICustomerTypeDto> = [];
  @Output() openAdvancedFiler = new EventEmitter();
  @Output() changeValueFilter = new EventEmitter();

  searchIcon = IconRefEnum.BpSearch;
  filterIcon = IconRefEnum.BpFilter;
  closeIcon = IconRefEnum.BpClose;
  filterCategoryList!: any[];
  filterByModel!: any;

  constructor() {
    this.getDropdownValue();
  }

  ngOnInit() { }

  onOpenAdvencedFilter() {
    this.advancedIsOpen = !this.advancedIsOpen;
    this.openAdvancedFiler.emit(this.advancedIsOpen);
  }

  getDropdownValue() {
    this.filterCategoryList = [
      {
        label: 'Customer Type',
        key: 'customerTypeCodes',
      },
      {
        label: 'Status',
        key: 'clientStatuses',
      },
    ];
  }

  onChangeCategory(filterByModelKey: any) {
    switch (filterByModelKey) {
      case 'clientStatuses': {
        this.filterByModel = {list: this.statusList, key: 'statusName', label: 'statusName'}
        break;
      }
      case 'customerTypeCodes': {
        this.filterByModel = {list: this.customerTypeList, key: 'customerTypeCode', label: 'name'}
        break;
      }
    }
  }

  onChangeFilter(searchModel: any) {
    const searchTerm: any = {};
    if (searchModel.search) {
      if(!isNaN(parseInt(searchModel.search)))
      {
        searchTerm.nGCarrierClientId = searchModel.search;
      }

      searchTerm.clientName = searchModel.search;
      searchTerm.customerNumber = searchModel.search;
    }
    if (searchModel.filterBy) {
      if(searchModel.filterBy !== 'All')
      {
        searchTerm[searchModel.category] = searchModel.filterBy;
      }
    }

    this.changeValueFilter.emit(searchTerm);
  }

  ngOnDestroy() { }
}
