<div class="confirmation-modal">
  <div class="header">
    <div class="title">
      <span [innerText]="title"></span>
    </div>
    <div>
      <span [bpIcon]="closeIcon" (click)="onCloseModal()"></span>
    </div>
  </div>

  <mat-dialog-content>
    <div class="content">
      <span *ngIf="messageIcon" [bpIcon]="messageIcon"></span>
      <span [innerText]="message"></span>
    </div>
    <div class="list-content" *ngIf="messageDetails?.length">
      <ul>
        <li *ngFor="let messageItem of messageDetails">
          <span [innerText]="messageItem"></span>
        </li>
      </ul>
    </div>

    <div class="btn-container buttons-right" style="margin-top: 20px;">
      <button type="button" class="secondary md" (click)="onCloseModal()">
        <span [innerText]="cancelBtn"></span>
      </button>
      <button type="submit" class="primary md" (click)="onAcceptModal()">
        <span [innerText]="acceptBtn"></span>
      </button>
    </div>
  </mat-dialog-content>

</div>