<div class="update-status-modal">
  <div class="header">
    <div class="title">
      <span [innerText]="'Modify Status'"></span>
    </div>
    <div>
      <span [bpIcon]="closeIcon" (click)="onCloseModal()"></span>
    </div>
  </div>

  <mat-dialog-content>
    <form [formGroup]="formStatus" class="form-update-status">
      <div class="bp-form-field status" style="width: 100%">
        <mat-form-field appearance="outline" class="bp-form-field bp-dropdown filter-input" style="width: 100%">
          <mat-select formControlName="statusType" placeholder="Select" disableOptionCentering
            panelClass="bp-filter-selector">
            <mat-option *ngFor="let type of statusTypeList" [value]="type.statusTypeId">
              {{type.statusName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="btn-container buttons-right">
      <button type="button" class="secondary md" (click)="onCloseModal()">Cancel</button>
      <button [disabled]="!formStatus.valid" type="submit" class="primary md" (click)="onSave()">Save</button>
    </div>
  </mat-dialog-content>

</div>