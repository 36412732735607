<div class="header-wrapper">
  <app-horizontal-menu></app-horizontal-menu>
  <div>
    <!-- <span class="sidebar-toggle" (click)="toggleSidebar()">
      <span [bpIcon]="_iconRefEnum.BpMenu" [Size]="'lg'"></span>
    </span> -->
    <!-- <span class="logo-wrapper">
      <img src="./assets/images/logo.png">
    </span> -->

    <div class="breadcrumb-container">
      <span [bpIcon]="_iconRefEnum.BpBreadcrumbGlobe" Size="sm"></span>
      <bp-breadcrumbs></bp-breadcrumbs>
    </div>

    <div class="header-controls">
      <div class="action-icons-holder">
        <span class="action_icon"
              *ngFor="let icon of _headerService.actionIconsList"
              [bpIcon]="icon.name"></span>
      </div>

      <div [matMenuTriggerFor]="menu" class="user-info">
        <span class="profile-icon" [bpIcon]="_iconRefEnum.BpAccountAvtar" Size="sm"></span>
        <span class="user-name" *ngIf="userToken">{{userToken.username}}</span>
        <span class="down-arrow" [bpIcon]="_iconRefEnum.BpExpandMore" Size="md"></span>
      </div>

      <mat-menu #menu="matMenu">
        <button mat-menu-item class="bp-user-info-menu-item">
          <span>Preferences</span>
        </button>
        <button mat-menu-item class="bp-user-info-menu-item">
          <span>Profile</span>
        </button>
        <button mat-menu-item class="bp-user-info-menu-item" (click)="logout()">
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>

  </div>
</div>
